import { Elements } from "@stripe/react-stripe-js";
import {
  ButtonDS,
  CTA,
  SecureEncryptor,
  StepBar,
  Tabs,
  Typography,
} from "get-life-storybook-ts";
import CreaditCard from "get-life-storybook-ts/lib/components/Icons/CreditCard";
import { StripeProvider } from "../../../../context/StripeContext.context";
import PaymentAccordion from "../PaymentGateway/components/PaymentAccordion/PaymentAccordion.component";
import ResumePrice from "../PaymentGateway/components/ResumePrice/ResumePrice.component";
import PaymentPageController from "./PaymentPage.controller";
import "./PaymentPage.scss";
import { DOMAIN } from "../../../../constants/Global";
import {
  GetlifeContext,
  LanguageT,
} from "../../../../context/GetLifeContext.context";
import { GlobalYearlyPayments } from "../../../../utils/InternationlChanges";
import ConditionsModalAB from "../../../../components/ConditionsModal/ConditionsModalAB";
import { Promotion } from "../../../../api/request/Lead/Interfaces/ProductInterface.response";
import { PurchasePeriodT } from "../../../../api/request/Lead/Interfaces/DataInterface.response";
import ResumePriceSQL from "../PaymentGateway/components/ResumePriceSQL/ResumePriceSQL.component";
import { useContext } from "react";

const PaymentPageChallenger = () => {
  const {
    closeNameForm,
    goToUpsell,
    handleCheckbox,
    handleCheckbox2,
    handleIsLoading,
    handleStripeStatus,
    isButtonDisabled,
    isMobile,
    leadId,
    navigateTo,
    openBox,
    payConfirm,
    payConfirm2,
    saveData,
    handleCallMe,
    stripePromise,
    stripeInputStatus,
    token,
    translate,
    checkout,
    nodeConditionsModal,
    handleConditions,
    couponInfo,
    validateCoupon,
    handlePurchasePeriod,
    handleRemoveCoupon,
    setupIntent,
    handleAddCoupon,
    capitals,
    prices,
    promotion,
  } = PaymentPageController();

  const { brokerPhone } = useContext(GetlifeContext);

  const listNavTabs: any = [
    {
      id: "card",
      title: translate("paymentChallenger.tab.card"),
      activeTab: "card",
      icon: <CreaditCard />,
    },
  ];

  const listTabsContent: any = [
    {
      id: "card",
      children: (
        <>
          {" "}
          {isMobile && <div className="line"></div>}
          <PaymentAccordion
            closeNameForm={closeNameForm}
            handleCheckbox={handleCheckbox}
            handleCheckbox2={handleCheckbox2}
            handleIsLoading={handleIsLoading}
            handleStripeStatus={handleStripeStatus}
            isButtonDisabled={isButtonDisabled()}
            leadId={leadId}
            navigateTo={navigateTo}
            openBox={openBox}
            payConfirm={payConfirm}
            payConfirm2={payConfirm2}
            saveData={saveData}
            setupIntent={setupIntent}
            stripeInputStatus={stripeInputStatus}
            token={token}
            translate={translate}
            checkout={checkout}
          />
        </>
      ),
    },
  ];

  if (!checkout) return null;

  return (
    <>
      <div className="paymentChallenger">
        <div className="paymentChallenger__title">
          <Typography
            variant="H1"
            weight="700"
            color="purple"
            text={translate("paymentChallenger.title")}
          />
        </div>
        <div className="paymentChallenger__body">
          <StepBar
            step={[
              {
                active: true,
                text: translate("newUpsell.stepbar.text1"),
                activePass: true,
              },
              {
                active: true,
                text: translate("newUpsell.stepbar.text2"),
                activePass: false,
              },
              {
                active: false,
                text: translate("newUpsell.stepbar.text3"),
                activePass: false,
              },
            ]}
          />
          <div className="paymentChallenger__body__sections">
            <Elements
              stripe={stripePromise}
              options={{
                clientSecret: setupIntent.clientSecret,
              }}
            >
              <div className="paymentChallenger__body__sections--paymentMethod">
                <Typography
                  variant="H3"
                  weight="700"
                  text={translate("paymentChallenger.paymentMethod.title")}
                />
                <SecureEncryptor />
                <Tabs
                  listNavTabs={listNavTabs}
                  listTabsContent={listTabsContent}
                  onChange={() => {}}
                />

                {DOMAIN !== "sql_es" && (
                  <div className="paymentChallenger__body__sections--paymentMethod--backbutton">
                    <ButtonDS
                      label={translate("payment.goback")}
                      onClick={() => goToUpsell()}
                      buttonType="secondary"
                      ghost={true}
                      leftIcon="ArrowBackIcon"
                      className="w-full"
                    />
                  </div>
                )}
                <div className="paymentChallenger__body__sections--paymentMethod--final">
                  <div className="paymentChallenger__body__sections--paymentMethod--final--line"></div>
                  <Typography
                    variant="Body"
                    weight="700"
                    text={translate("paymentChallenger.nextSteps.title")}
                  />
                  <Typography
                    variant="Body"
                    text={translate("paymentChallenger.nextSteps.text")}
                  />
                </div>
              </div>
              <div
                className={
                  validateCoupon
                    ? "paymentChallenger__body__sections--resumePrice couponTopStyle"
                    : "paymentChallenger__body__sections--resumePrice"
                }
              >
                <StripeProvider
                  handleIsLoading={handleIsLoading}
                  setupIntent={setupIntent}
                  leadId={leadId}
                  token={token}
                  navigate={navigateTo}
                  checkout={checkout}
                >
                  {DOMAIN === "sql_es" ? (
                    <ResumePriceSQL
                      checkout={checkout}
                      isAvailableToggle={
                        GlobalYearlyPayments[DOMAIN as LanguageT]
                      }
                      translate={translate}
                      validateCoupon={validateCoupon}
                      handleAddCoupon={handleAddCoupon}
                      handleRemoveCoupon={handleRemoveCoupon}
                      handlePurchasePeriod={handlePurchasePeriod}
                      couponInfo={couponInfo}
                      isButtonDisabled={isButtonDisabled()}
                      onConditionsClick={handleConditions}
                      capitals={capitals}
                      prices={prices}
                      promotion={promotion}
                    />
                  ) : (
                    <ResumePrice
                      checkout={checkout}
                      isAvailableToggle={
                        GlobalYearlyPayments[DOMAIN as LanguageT]
                      }
                      translate={translate}
                      validateCoupon={validateCoupon}
                      handleAddCoupon={handleAddCoupon}
                      handleRemoveCoupon={handleRemoveCoupon}
                      handlePurchasePeriod={handlePurchasePeriod}
                      couponInfo={couponInfo}
                      isButtonDisabled={isButtonDisabled()}
                      onConditionsClick={handleConditions}
                    />
                  )}
                </StripeProvider>
              </div>
            </Elements>
          </div>
        </div>
      </div>
      {DOMAIN === "sql_es" ? (
        <div className="w-full bg-[#F5F7FA] md:bg-white py-[50px] px-[24px] md:px-[48px]">
          <div className="flex flex-col mx-auto items-center w-full max-w-[510px] text-center">
            <h2 className="H2 font-bold text-[var(--primary-color)] text-center mb-[20px]">
              {translate("welcome.sql.contact.title")}
            </h2>
            <p className="BodyL">
              {translate("welcome.call.question")}{" "}
              <a
                className="text-[var(--primary-color)] underline font-bold"
                href={`tel:${brokerPhone}`}
              >
                {brokerPhone}
              </a>
            </p>
            <ButtonDS
              label={translate("welcome.call.checkWithBroker")}
              buttonType="secondary"
              leftIcon="PhoneCallIcon"
              className="mx-auto mt-[20px] !no-underline"
              href={`tel:${brokerPhone}`}
            />
          </div>
        </div>
      ) : (
        <div className="paymentChallenger__final">
          <div className="paymentChallenger__final--CTA">
            <CTA
              title2CTA={translate("newUpsell.ctaSection.title2CTA")}
              titleCTA={translate("newUpsell.ctaSection.titleCTA")}
              paragraph1CTA={translate("newUpsell.ctaSection.paragraph1CTA")}
              paragraph2CTA={translate("newUpsell.ctaSection.paragraph2CTA")}
              textButton={translate("newUpsell.ctaSection.textButton")}
              phone={process.env.REACT_APP_TEL || ""}
              onClick={() => handleCallMe()}
              classNameCTA={"paymentChallenger__final--CTA--Inside"}
            />
          </div>
        </div>
      )}
      {checkout?.promotionalCode && (
        <ConditionsModalAB
          couponInfo={couponInfo}
          nodeFakeDoorModal={nodeConditionsModal}
          promotion={
            {
              conditions: couponInfo?.conditions,
              promotionalCode: couponInfo?.code,
            } as Promotion
          }
          collection={checkout.feeCollection as any}
          period={
            (checkout.period?.toLowerCase() || "monthly") as PurchasePeriodT
          }
          handleSubmit={() => nodeConditionsModal.current?.handleShowPortal()}
        />
      )}
    </>
  );
};

export default PaymentPageChallenger;
