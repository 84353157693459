import { sixHoursDate } from "../utils/Dates.utils";

export function setCookie(
  name: string,
  _value: string | null,
  _expires: Date = sixHoursDate
) {
  const value = _value ? encodeURIComponent(_value) : _value;
  const domain = process.env.REACT_APP_COOKIE_DOMAIN;
  const expires = _expires.toUTCString();

  document.cookie = `${name}=${value};Domain=${domain};expires=${expires};path=/`;
}
