const numberFormat = (value: number, type?: string) => {
  if (value) {
    if (type === "capital") {
      return new Intl.NumberFormat(
        process.env.REACT_APP_DOMAIN_LOCALE === "fr" ? "fr-FR" : "de-DE",
        {
          style: "currency",
          currency: "EUR",
          maximumFractionDigits: 2,
          minimumFractionDigits: 0,
        }
      ).format(Math.round(value));
    }
    if (type === "price") {
      return new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }).format(value);
    }
    return new Intl.NumberFormat("es-ES", {
      style: "currency",
      currency: "EUR",
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }).format(value);

  }

  return "";
};

export default numberFormat;

export const numberFormatPayment = (value: number, type?: string) =>
  new Intl.NumberFormat(
    process.env.REACT_APP_DOMAIN_LOCALE === "fr" ? "fr-FR" : "de-DE",
    {
      maximumFractionDigits: 2,
      minimumFractionDigits: type === "capital" ? 0 : 2,
    }
  ).format(value);

export const capitalize = (word: string) => {
  const lower = word?.toLowerCase();
  return word.charAt(0).toUpperCase() + lower.slice(1);
};

export const renderUnderlined = (
  intention: string,
  translate: (key: string) => string
) => {
  if (intention) {
    const emptyIntention = !!intention && intention.includes(",");
    let sanitize = emptyIntention ? intention.split(",")[0] : intention;
    switch (sanitize) {
      case "loan":
        return translate("payment.policyDetails.loan");
      case "family":
        return translate("payment.policyDetails.family");
      default:
        return translate("payment.policyDetails.finance");
    }
  } else {
    return "";
  }
};

const numbersToWords: any = {
  0: "zero",
  1: "first",
  2: "second",
  3: "three",
  4: "four",
  5: "five",
  6: "six",
  7: "seven",
  8: "eight",
  9: "nine",
  10: "ten",
  11: "eleven",
  12: "twelve",
  13: "thirteen",
  14: "fourteen",
  15: "fifteen",
  16: "sixteen",
  17: "seventeen",
  18: "eighteen",
  19: "nineteen",
  20: "twenty",
  30: "thirty",
  40: "forty",
  50: "fifty",
  60: "sixty",
  70: "seventy",
  80: "eighty",
  90: "ninety",
};

export const convertNumberToWords = (number: number) => {
  if (number in numbersToWords) return numbersToWords[number];

  let words = "";

  if (number >= 100) {
    words += convertNumberToWords(Math.floor(number / 100)) + " hundred";

    number %= 100;

    if (number > 0) {
      if (words !== "") words += " and ";

      if (number < 20) words += numbersToWords[number];
      else {
        words += numbersToWords[Math.floor(number / 10) * 10];

        if (number % 10 > 0) {
          words += "-" + numbersToWords[number % 10];
        }
      }
    }
  }

  return words;
};

export const isInViewport = (element?: Element | null): Boolean => {
  if(!element) return false;
  const { top, bottom } = element.getBoundingClientRect();
  const vHeight = (window.innerHeight || document.documentElement.clientHeight);

  return (
    (top > 0 || bottom > 0) &&
    top < vHeight
  );
}

export const parsePriceDecimal = (price: number, type?: string | undefined) => {
  const formatPrice = new Intl.NumberFormat("de-DE", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(price);

  let formatted = formatPrice.split(",", 2);

  if (type === "decimal") {
    return formatted[1] === "00" ? "" : formatted[1]
  }

  return formatted[0];

}
