
import { CounterPages } from "get-life-storybook-ts";
import { useRef, useState } from "react";
import { usePdf } from "react-pdf-js"
import "./PDFViewer.scss";


const PDFViewer = ({ url, translate }: any) => {
  const canvasRef = useRef(null);
  const [pageNumber, setPageNumber] = useState(1);

  const [loading, numPages] = usePdf({
    file: url,
    withCredentials: true,
    page: pageNumber,
    canvasEl: canvasRef,
  });
 
  return (
    !loading ? (
      <div className="note-pdf">
        <canvas ref={canvasRef}  />
        <CounterPages
          initialPage={pageNumber}
          handlePageChange={(value) => setPageNumber(value)}
          text={translate("informativeNote.pages").replace("#", numPages)}
          maxPages={numPages}
        />
      </div>
    ) :<></>
  );
};

export default PDFViewer;
