import { useState, useContext, useEffect, useRef } from "react";
import "./weCallYou.scss";
import {
  CustomModal,
  Input,
  Typography,
} from "get-life-storybook-ts";
import { I18nContext } from "../../context/i18n.context";
import { GetlifeContext } from "../../context/GetLifeContext.context";
import HttpLeadRepository from "../../api/request/Lead/Lead.service";
import { validatePhoneFR, validationsGlobal } from "../../utils/validations";
import { customWeCallYouStyles } from "../../constants/CustomStyles/WeCallYou";
import { contactTranslations, numberReceivedTranslations } from "../../constants/WeCallYou";
import { ErrorResponse, isITPhone } from "get-life-storybook-ts/lib/components/Validations";
import { DOMAIN, PHONE } from "../../constants/Globals";
import { NotificationMessageI, getNotification } from "../../constants/Notification";
import { ButtonsModalI } from "get-life-storybook-ts/lib/organisms/Modal/CustomModal";

interface WeCallYouBoyI {
  translations: Record<string, string>
  translate: (key: string) => string
  phone: string;
  setPhone: (phone: string) => void;
  error: ErrorResponse
}

interface WeCallYouModalTitleI { translations: Record<string, string> }

const ModalTitle = ({ translations }: WeCallYouModalTitleI) => (
  <div className="flex items-center gap-2">
    <Typography
      variant={customWeCallYouStyles.titleVariant}
      weight={customWeCallYouStyles.titleWeight}
      text={translations.titleText}
    />
  </div>)

const ModalBody = ({
  translations,
  translate,
  phone, error, setPhone,
}: WeCallYouBoyI
) => (
  <>
    <Typography
      variant={customWeCallYouStyles.bodyTextVariant}
      weight={customWeCallYouStyles.bodyTextWeight}
      text={translations.firstParagraphText}
      className="pb-[16px]"
    />
    {translations.secondParagraphText && <Typography
      variant={customWeCallYouStyles.bodyTextVariant}
      weight={customWeCallYouStyles.bodyTextWeight}
      text={translations.secondParagraphText}
    />}
    {!!translations?.inputPlaceholderText && (
      <Input
        label={translations.thirdParagraphText}
        onValueChange={(value) => setPhone(value)}
        initialValue={phone}
        inputMode="number"
        placeholder={translations.inputPlaceholderText}
        initialError={error}
        validationChecks={DOMAIN === "es" || DOMAIN === "pt" || DOMAIN === "it"
          ? [
            (value: string) =>
              validationsGlobal({
                value,
                translate,
                field: "phone",
              }),
          ]
          : []}
        id="WeCallYouModalInput"
      />
    )}

  </>
);


const WeCallYou = () => {
  const { state: { translate } } = useContext(I18nContext)
  const { nodeWeCallYou, nodeElementAlert, leadId, setNotification } = useContext(GetlifeContext)
  const [phone, setPhone] = useState<string>("")
  const [hasReceivedNumber, setHasReceivedNumber] = useState(false)
  const [error, setError] = useState<ErrorResponse>({ error: false, message: "" })
  const [flag, setFlag] = useState<boolean>(false)

  const leadRepository = new HttpLeadRepository()

  const linkRef = useRef<any>()

  const messagesSuccess: NotificationMessageI[] = [{ variant: "BodyMedium", message: translate("upsell.CallMeBack.successTitle"), weight: "700" }, { variant: "BodyMedium", message: translate("upsell.CallMeBack.successText"), weight: "400" }]
  const messagesError: NotificationMessageI[] = [{ variant: "BodyMedium", message: translate("upsell.CallMeBack.warningTitle"), weight: "700" }, { variant: "BodyMedium", message: translate("upsell.CallMeBack.warningText"), weight: "400" }]

  const handleSubmitPhone = async () => {
    try {
      await leadRepository.callMe(phone, leadId);
      setNotification({
        message: getNotification({ messages: messagesSuccess }),
        type: "success",
        iconName: "CheckIcon",
        positionY: "top",
        positionX: "right",
      });
      setHasReceivedNumber(true)
    } catch (error) {
      nodeElementAlert.current.handleShowPortal()
      setNotification({
        message: getNotification({ messages: messagesError }),
        type: "error",
        iconName: "CrossIcon",
        positionY: "top",
        positionX: "right",
      });
    }
  };

  

  useEffect(() => {
    (async () => {
      if (DOMAIN === "fr" && phone.length >= 6) {
        const errorPhone = await validatePhoneFR({
          value: phone,
          errorGlobal: error as ErrorResponse,
          translate,
        });
        setError(errorPhone);
      }
      if (DOMAIN === "it" && phone && phone.length >= 6) {
        const errorPhone = isITPhone({
          value: phone,
          message: translate("error.notPhone"),
        });
        setError(errorPhone);
      }
    })();
  }, [phone])

  useEffect(() => {
    if (flag) {
      linkRef.current?.click()
      setFlag(false)
    }
  }, [flag])

  const contactButton: ButtonsModalI = {
    showBoth: true,
    preventClose: true,
    buttonInfo: {
      primary: {
        label: contactTranslations({ translate }).buttonTextSecond,
        onClick: () => handleSubmitPhone(),
      },
      secondary: {
        buttonType: "text",
        label: contactTranslations({ translate }).buttonTextFirst,
        noHover: true,
        className: "no-underline",
        href: `tel:${PHONE}`,
        target: "_self",
        ref: linkRef,
        onClick: () => {
          setFlag(true)
        }
      }
    }
  }

  const numberReceivedButton: ButtonsModalI = {
    buttonInfo: {
      primary: {
        label: numberReceivedTranslations({ translate }).buttonText,
        onClick: () => nodeWeCallYou.current.handleClosePortal(),
      },
    }
  }
  return !hasReceivedNumber ?
    <CustomModal ref={nodeWeCallYou} ModalTitle={<ModalTitle translations={contactTranslations({ translate })} />} ModalBody={<ModalBody translations={contactTranslations({ translate })} translate={translate} phone={phone} setPhone={setPhone} error={error} />} ModalActions={contactButton} />
    : <CustomModal
      ref={nodeWeCallYou}
      ModalTitle={<ModalTitle translations={numberReceivedTranslations({translate})} />}
      ModalBody={<ModalBody translations={numberReceivedTranslations({translate})} translate={translate} phone={phone} setPhone={setPhone} error={error} />}
      ModalActions={numberReceivedButton}
    />
};

export default WeCallYou;
