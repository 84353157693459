import HttpGlobal from "../Global/HttpGlobal.class";

export default class HttpSignatureRepository extends HttpGlobal {
  private readonly API_URL_SIGNATURE:
    | string
    | undefined = `${process.env.REACT_APP_CUSTOMER_APP_URL}api/v2/signature`;

  getSignStatus = async () => {
    const response = await this.generalGet(
      `${this.API_URL_SIGNATURE}/contract/status/${this.leadId}`
    );
    return response;
  };

  postCreateContract = async () => {
    const response = await this.generalPost({
      url: `${this.API_URL_SIGNATURE}/contract/create-contract/${this.leadId}`,
      dataRequest: {},
    });

    return response;
  };

  getSignatureEmbedded = async () => {
    const response = await this.generalGet(
      `${this.API_URL_SIGNATURE}/contract/ready-to-embedded/${this.leadId}`
    );
    return response;
  };
}
