const apiRegisterHubspot = (data) => {
   
    return fetch(
        process.env.REACT_APP_CUSTOMER_APP_URL +
            `api/hubspot/register`,
        {
            method: 'POST',
            body: JSON.stringify(data),
        }
    ).then((response) => response.json());
};

export default apiRegisterHubspot;
