/* eslint-disable jsx-a11y/alt-text */
import {
  AnimatedAlert,
  ButtonDS,
  DataBlock,
  GlobalIcon,
  HelpOverlay,
  TransitionPage
} from "get-life-storybook-ts";
import "./ConfirmationPage.styles.scss";
import UserIcon from "get-life-storybook-ts/lib/components/Icons/userIcon";
import CheckIcon from "get-life-storybook-ts/lib/components/Icons/checkIcon";
import UmbrellaIcon from "get-life-storybook-ts/lib/components/Icons/umbrellaIcon";
import CalendarIcon from "get-life-storybook-ts/lib/components/Icons/calendarIcon";
import CardIcon from "get-life-storybook-ts/lib/components/Icons/cardIcon";
import TagIcon from "get-life-storybook-ts/lib/components/Icons/tagIcon";
import { capitalize, numberFormatPayment } from "../../utils/Utils";
import Progression from "../DecisionPage/components/Progression.component";
import InfoIcon from "get-life-storybook-ts/lib/components/Icons/InfoIcon";
import CouponInfo from "../StepsCalculatorPage/Components/Coupon/CouponInfo.component";
import ConfirmationPageController from "./ConfirmationPage.controller";
import Spinner from "get-life-storybook-ts/lib/components/Icons/Spinner";

const ICONS = {
  user: <UserIcon />,
  check: <CheckIcon />,
  umbrella: <UmbrellaIcon />,
  calendar: <CalendarIcon />,
  card: <CardIcon />,
};

const ConfirmationPage = () => {
  const {
    continueProcess,
    couponInfo,
    data,
    dataBlocksInfo,
    open,
    product,
    progression,
    setOpen,
    translate,
    isABtesting
  } = ConfirmationPageController();

  const isDataLoaded =
    data !== undefined &&
    dataBlocksInfo !== undefined &&
    progression !== undefined;

  if(!isDataLoaded) {
    if(isABtesting) {
      return <TransitionPage
        illustration="Worker"
        title={translate("transitionPages.paymentPage.title")}
        description={translate("transitionPages.paymentPage.description")}
      />
    }
    return <span className="spinnerLoading"><Spinner height={64} width={64} /></span>;
  }

  return (
    <div className="confirmationPage">
      {couponInfo && (
        <HelpOverlay
          setIsOpen={setOpen}
          isOpen={open}
          buttonLabel={translate("coupons.overlay.button")}
        >
          <CouponInfo
            translate={translate}
            couponInfo={couponInfo}
            couponName={product?.promotion?.name}
          />
        </HelpOverlay>
      )}
      <div className="confirmationPage-content">
        <GlobalIcon iconName="FilledCheckIcon" className="icon-check" color="#018565" size="L" />
        <h1 className="confirmationTitle">
          {translate("confirmationPage.confirmation.title1")}{" "}
          {data && capitalize(data?.lead.name)}{" "}
          {translate("confirmationPage.confirmation.title2")}
        </h1>
        <p className="confirmationSubtitle">
          {translate("confirmationPage.confirmation.text1")}
          {data?.lead.email}
          {translate("confirmationPage.confirmation.text2")}
        </p>
        <AnimatedAlert
          icon={"SignatureIcon"}
          title={translate("confirmationPage.resume.oneSign").replace(
            "{capital}",
            `${numberFormatPayment(product.capital, "capital")} €`
          )}
          defaultState="open"
          type="success"
          canBeClosed={false}
          float={false}
          className="confirmationPage-oneSign"
        />
        <div className="dataBlockProgressInfo">
          <div className="dataBlock">
            {dataBlocksInfo && (
              <>
                {dataBlocksInfo.map((dataBlock, index) => (
                  <>
                    {dataBlock.space && <hr />}
                    <DataBlock
                      key={`dataBlock-${index}`}
                      title={dataBlock.title}
                      subtitle={dataBlock.subtitle}
                      text={dataBlock.text}
                      icon={ICONS[dataBlock.icon]}
                    />
                  </>
                ))}
                {!!product.promotion && (
                  <>
                    <hr />{" "}
                    <DataBlock
                      title={translate("confirmationPage.resume.couponSavings")}
                      subtitle={product.promotion.description}
                      text={
                        <>
                          <span>
                            {translate("confirmationPage.coupon")}
                            <span
                              className="dataBlockProgressInfo__link"
                              onClick={() => setOpen(true)}
                            >
                              {translate("confirmationPage.coupon.link")}
                            </span>
                          </span>
                        </>
                      }
                      icon={<TagIcon />}
                    />
                  </>
                )}
              </>
            )}
          </div>
          <div className="verticalLine"></div>
          <div className="progressNextStep">
            <div className="progressComponent">
              {progression && (
                <Progression
                  title={translate("decisionPage.Progression.title")}
                  list={progression}
                  type=""
                />
              )}
            </div>
            <div className="infoNextStep">
              <div className="infoNextStep--text">
                <div><GlobalIcon iconName="InfoIcon" /></div>
                <p>{translate("confirmationPage.dni")}</p>
              </div>
              <div className="infoNextStep--button">
                <ButtonDS
                  label={translate("decision.continue.button")}
                  onClick={() => continueProcess()}
                  className="w-full"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationPage;
