import { CustomModal, GlobalIcon, Typography } from "get-life-storybook-ts";
import { useContext, useEffect, useRef, useState } from "react";
import { I18nContext } from "../../context/i18n.context";
import { Promotion } from "../../api/request/Lead/Interfaces/ProductInterface.response";
import {
  FeeCollection,
  PurchasePeriodT,
} from "../../api/request/Lead/Interfaces/DataInterface.response";
import { GetlifeContext } from "../../context/GetLifeContext.context";
import "./ConditionsModal.scss";
import numberFormat from "../../utils/Utils";
import HttpCouponsRepository from "../../api/request/Coupons/Coupons.service";
import { CouponInfoI } from "../../views/StepsCalculatorPage/Components/PaymentPage/PaymentPage.controller";
import { useLocation } from "react-router";
import { formatGetlifeDate } from "../../utils/PrepareDataCalendar";
import { GlobalDomainQuestions } from "../../utils/InternationlChanges";

export interface ConditionsModalI {
  collection: FeeCollection[];
  couponInfo?: CouponInfoI;
  finalPrice?: number | undefined;
  handleSubmit: () => void;
  nodeFakeDoorModal: any;
  period: PurchasePeriodT;
  promotion: Promotion;
}

const ConditionsModalAB = ({
  collection,
  couponInfo,
  handleSubmit,
  nodeFakeDoorModal,
  period,
  promotion,
}: ConditionsModalI) => {
  const {
    state: { translate },
  } = useContext(I18nContext);

  const { domain, leadId, token } = useContext(GetlifeContext);
  const pdfElement: any = useRef(null);
  const couponsRepository = new HttpCouponsRepository(leadId, token) as any;
  const [linkPDF, setLinkPDF] = useState<string>("");
  const location = useLocation();

  const link = {
    linkRef: pdfElement,
    linkUrl: "",
  };

  useEffect(() => {
    (async () => {
      const response = await couponsRepository.downloadConditionPDF();
      setLinkPDF(response);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, period]);

  function saveBlobAs(blob: Blob, fileName: string) {
    const anchorElement = document.createElement("a");
    const url = URL.createObjectURL(blob);

    anchorElement.href = url;
    anchorElement.download = fileName;
    document.body.appendChild(anchorElement);
    anchorElement.click();

    setTimeout(() => {
      document.body.removeChild(anchorElement);
      URL.revokeObjectURL(url);
    }, 100);
  }

  const handleDocPDF = async () => {
    try {
      const response = await fetch(`data:application/pdf;base64,${linkPDF}`);
      const blob = await response.blob();
      saveBlobAs(blob, "promo_conditions.pdf");
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  const BodyContent = () => (
    <div className="conditions_container">
      <GlobalIcon iconName="DiscountIcon" size="L" />
      <Typography
        variant="Body"
        weight="700"
        text={promotion.description}
        color="life-blue-100"
      />

      <Typography variant="Body" text={couponInfo?.conditions!} />

      <Typography
        variant="Body"
        text={translate("payment.conditionsModal.text1")}
      />

      <Typography
        variant="Body"
        text={translate("payment.conditionsModal.text3")}
      />

      <div className="conditions_container--table">
        {collection?.map((fee, index: number) => {
          // Display 12 fees for monthly period and 1 fee for yearly period
          if (period === "monthly" && index >= 12) return null;
          if (period === "yearly" && index >= 1) return null;
          return (
            <div className={`conditions_container--row `} key={index}>
              <Typography
                variant="Body"
                className={`${fee.total === 0 && "extrapadding--right"}`}
                text={formatGetlifeDate(
                  fee.expectedPaymentDate,
                  GlobalDomainQuestions[domain]
                )}
                color={fee.discountAmount ? "life-blue-100" : "dark-gray-100"}
              />

              <Typography
                variant="Body"
                weight="700"
                className={`${fee.total === 0 && "extrapadding--left"}`}
                text={fee.total ? numberFormat(fee.total) : "0 €"}
                color={fee.discountAmount ? "life-blue-100" : "dark-gray-100"}
              />
            </div>
          );
        })}
      </div>
    </div>
  );

  return (
    <>
      <CustomModal
        ref={nodeFakeDoorModal}
        ModalTitle={
          <Typography
            variant="H2"
            weight="700"
            text={translate("payment.conditionsModal.title")}
          />
        }
        ModalBody={<BodyContent />}
        ModalActions={{
          showBoth: true,
          buttonInfo: {
            primary: {
              label: translate("coupons.overlay.button"),
              onClick: handleSubmit,
            },
            secondary: {
              label: translate("coupons.overlay.button.secondary"),
              noHover: true,
              icon: <GlobalIcon iconName="DownloadIcon" />,
              onClick: () => {
                handleDocPDF();
              },
              link,
            },
          },
        }}
      />
    </>
  );
};

export default ConditionsModalAB;
