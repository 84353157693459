import { useNavigate } from "react-router";
import { loadStripe } from "@stripe/stripe-js";
import { useContext } from "react";
import { GetlifeContext } from "../../context/GetLifeContext.context";
import HttpCheckoutRepository from "../../api/request/Checkout/Checkout.service";

//import SplitForm from './demos/SplitForm';

export default function CardSetupForm() {
  const { token, leadId, setLoading } =
    useContext(GetlifeContext);

  const checkoutRepository = new HttpCheckoutRepository(leadId, token);

  const navigate = useNavigate();

  const getStripe = async () => {
    setLoading(true);
    const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY, {
      locale: "es",
      stripeAccount: process.env.REACT_APP_STRIPE_ACCOUNT,
    });
    let params = new URLSearchParams(window.location.search);
    let paymentIntentSecret = params.get("payment_intent_client_secret");
    try {
      const result = await stripe.retrievePaymentIntent(paymentIntentSecret);

      await checkoutRepository.saveStatus(
        result.paymentIntent.status
      );
      setLoading(false);
      navigate("/payment/5");
    } catch (error) {
      setLoading(false);
      navigate("/waitingvalidation");
    }
    return stripe;
  };

  getStripe();
  return <></>;
}
