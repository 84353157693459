import { Typography } from "get-life-storybook-ts";
interface OverlayInfoIpaI {
  translate: (key: string) => string;
}

const OverlayInfoIpaAB: React.FC<OverlayInfoIpaI> = ({ translate }) => (
  <div className="payment-newUpsellContainer__section__card__overlay">
    <Typography
      variant="BodyMedium"
      text={translate("newUpsell.OverlayIpa.text1")}
    >
      <Typography
        variant="SpanMedium"
        weight="700"
        text={translate("newUpsell.OverlayIpa.text2")}
      />
      <Typography
        variant="SpanMedium"
        text={translate("newUpsell.OverlayIpa.text3")}
      />
      <Typography
        variant="SpanMedium"
        weight="700"
        text={translate("newUpsell.OverlayIpa.text4")}
      />
      <Typography
        variant="SpanMedium"
        text={translate("newUpsell.OverlayIpa.text5")}
      />
    </Typography>
  </div>
);

export default OverlayInfoIpaAB;
