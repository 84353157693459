import React, { ReactNode } from "react";
import { useContext } from "react";
import { GetlifeContext } from "./GetLifeContext.context";
import { useNavigate } from "react-router";
import HttpLeadRepository from "../api/request/Lead/Lead.service";
import { redirect } from "../Routes/redirect";
import { GetLifeTrackerContext } from "./GetLifeTracker.context";

interface ContextProps {
  children: React.ReactNode;
}

interface statusContextI {
  getStatus: (value?: boolean) => void;
}

export const StatusContext = React.createContext({} as statusContextI);

export const StatusProvider = ({ children }: ContextProps) => {
  const { setUserStatus, setThemeColor, setLogo, init, leadId, token } =
    useContext(GetlifeContext);
  const { handleTrackerQuestion } = useContext(GetLifeTrackerContext);
  const navigate = useNavigate();

  const getStatus = async (conditional?: boolean) => {
    const leadRepository = new HttpLeadRepository(leadId, token);
    try {
      const response = await leadRepository.getClientStatus();
      handleTrackerQuestion({
        type: "trackerCompare",
        data: {
          leadUlid: leadId,
        },
      });
      const { status, themeColor, logo } = response;
      setUserStatus(status);
      setThemeColor(themeColor as any);
      setLogo(logo);
      if (!conditional) {
        navigate(redirect(status));
      } else {
        if (status === "legalnote_pending") navigate(redirect(status));
      }
    } catch (error) {
      init("catch_get_status");
    }
  };

  const values = {
    getStatus,
  };
  return (
    <StatusContext.Provider value={values}>
      {children as unknown as ReactNode}
    </StatusContext.Provider>
  );
};
