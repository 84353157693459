import App from "./App";
import { createRoot } from "react-dom/client";
import WebFont from "webfontloader";
import { GetlifeProvider } from "./context/GetLifeContext.context";
import { WithI18n } from "./context/i18n.context";
import { BrowserRouter as Router } from "react-router-dom";
import { GetLifeTrackerProvider } from "./context/GetLifeTracker.context";
import { StatusProvider } from "./context/StatusContext";

WebFont.load({
  google: {
    families: ["Montserrat:500,600,700", "sans-serif"],
  },
});

const root = createRoot(document.querySelector("#root"));

root.render(
  <WithI18n>
    <GetlifeProvider>
      <Router>
        <GetLifeTrackerProvider>
          <StatusProvider>
            <App />
          </StatusProvider>
        </GetLifeTrackerProvider>
      </Router>
    </GetlifeProvider>
  </WithI18n>
);
