import CheckoutPay from "./components/CheckoutPay/CheckoutPay.component";
import PaymentPageChallenger from "../PaymentPage/PaymentPage.component.";
import CheckoutSign from "./components/CheckoutSign/CheckoutSign.component";
import Upsell from "../../../NewUpsell/Upsell.component";
import { useParams } from "react-router";

export default function PaymentGateway() {
  const { stepPayment } = useParams();

  return (
    <div id="payment-gateway" className="h-full w-full">
      {stepPayment === "1" && <Upsell />}
      {stepPayment === "3" && <PaymentPageChallenger />}
      {stepPayment === "4" && <CheckoutPay />}
      {stepPayment === "5" && <CheckoutSign />}
    </div>
  );
}
