const AvatarIcon = ({className = ""}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
    className={className}
  >
    <circle cx={20} cy={20} r={20} fill="#ECECFE" />
    <path
      fill="#7B78F7"
      fillRule="evenodd"
      d="M6.4 34.664C8.79 29.547 13.98 26 20 26s11.212 3.547 13.6 8.664A19.93 19.93 0 0 1 20 40a19.93 19.93 0 0 1-13.6-5.336Z"
      clipRule="evenodd"
    />
    <circle cx={20} cy={16} r={8} fill="#7B78F7" />
  </svg>
);

export default AvatarIcon;
