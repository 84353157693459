import { useNavigate } from "react-router";
import logo from "../../../assets/img/getlife-logo/getlife-azul.svg";
import happyFace from "../../../assets/img/getlife-elements/happyFace.png";

import "../css/passwordChange.scss";
import { ButtonDS } from "get-life-storybook-ts";

export default function PasswordChange() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/login");
  };

  return (
    <div className="changePassword">
      <main className="changePassword--main">
        <img
          src={logo}
          alt="Logo de Getlife"
          title="Logo de Getlife"
          className="changePassword--main__img"
        />
        <img
          src={happyFace}
          alt="Carita guiñando un ojo"
          title="Cariña guiñando un ojo"
          className="changePassword--main__happyFace"
        />
        <h6 className="changePassword--main__title">
          Te hemos enviado la contraseña a tu mail.{" "}
          <strong>Entra en Getlife</strong> pulsando en el siguiente enlace:
        </h6>
        <ButtonDS
          label={"Entrar en getlife"}
          onClick={() => handleClick()}
        />
      </main>
    </div>
  );
}
