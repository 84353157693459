import { Navigate, Outlet, useLocation } from "react-router-dom";
import { UserStatusT } from "../context/GetLifeContext.context";
import { redirect } from "./redirect";

export interface RoutesI {
  token: string;
  userStatus: UserStatusT;
}
const PublicRoute = ({ token, userStatus }: RoutesI) => {
  const location = useLocation();

  return !token || !userStatus ? (
    <Outlet />
  ) : (
    <Navigate to={redirect(userStatus) + location.search} />
  );
};

export default PublicRoute;
