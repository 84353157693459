import HttpGlobal from "../Global/HttpGlobal.class";

export default class HttpCheckoutRepository extends HttpGlobal {
  private readonly API_URL_CHECKOUT:
    | string
    | undefined = `${process.env.REACT_APP_CUSTOMER_APP_URL}api/checkout`;

  private readonly API_URL:
    | string
    | undefined = `${process.env.REACT_APP_CUSTOMER_APP_URL}api`;

  confirm = async ({ price, paymentMethod }: any) => {
    const response = await this.generalPost({
      url: `${this.API_URL_CHECKOUT}/signature/${this.leadId}`,
      dataRequest: { amount: price, method: paymentMethod },
    });

    return response;
  };

  finishRequest = async ({ price, paymentMethod }: any) => {
    const response = await this.generalPost({
      url: `${this.API_URL_CHECKOUT}/finish/${this.leadId}`,
      dataRequest: { amount: price, method: paymentMethod },
    });

    return response;
  };

  finishSigned = async () => {
    const response = await this.generalPost({
      url: `${this.API_URL_CHECKOUT}/finish/${this.leadId}/signed`,
      dataRequest: {},
    });

    return response;
  };

  finishSucceed = async () => {
    const response = await this.generalPost({
      url: `${this.API_URL_CHECKOUT}/finish/${this.leadId}/succeeded`,
      dataRequest: {},
    });

    return response;
  };

  finishWaitingSignature = async () => {
    const response = await this.generalPost({
      url: `${this.API_URL_CHECKOUT}/finish/${this.leadId}/waiting-signature`,
      dataRequest: {},
    });

    return response;
  };

  savePaymentMethod = async (data: any) => {
    const response = await this.generalPost({
      url: `${this.API_URL_CHECKOUT}/paymentmethod/save`,
      dataRequest: data,
    });

    return response;
  };

  saveStatus = async (status: string) => {
    const response = await this.generalPatch({
      url: `${this.API_URL}/lead/ensure-card-validation/${this.leadId}/${status}`,
      dataRequest: {},
    });

    return response;
  };

  acceptLegalNote = async () => {
    const response = await this.generalPost({
      url: `${this.API_URL_CHECKOUT}/accept-legal-note/${this.leadId}`,
      dataRequest: {}
    });

    return response;
  }

  isReadyToPaymentValidation = async () => {
    const response = await this.generalGet(`${this.API_URL}/is-ready-to-payment-validation-pending/${this.leadId}`);
  
    return response;
  }

  ensureFundsFromCustomer = async () => {
    const response = await this.generalGet(`${this.API_URL}/lead/ensure-funds-from-customer/${this.leadId}`);

    return response;
  }
}
