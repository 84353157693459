import HttpGlobal from "../Global/HttpGlobal.class";

export default class HttpDocumentsRepository extends HttpGlobal {
  private readonly API_URL_DOCUMENTS:
    | string
    | undefined = `${process.env.REACT_APP_CUSTOMER_APP_URL}api/documents`;

  getAvailablesDocuments = async (lead: string) => {
    const response = await this.generalGet(
      `${this.API_URL_DOCUMENTS}/availables/${lead}`
    );
    return response;
  };

  downloadDocument = async (template: string) => {
    const response = await this.generalGet(
      `${this.API_URL_DOCUMENTS}/generate/${template}/${this.leadId}`
    );
    return response;
  }

}
