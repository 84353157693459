import {
  CapitalStep,
  ContactStep,
  PersonalDataStep,
} from "get-life-storybook-ts/lib/components/CalculatorSteps";
import CalculatorPageWL_ESController from "./CalculatorPageWL_ES.controller";
import QuotePage from "../QuotePage/QuotePage.component";
import { reorderDate } from "get-life-storybook-ts";

const CalculatorPageWL_ES = () => {
  const { contextCalculator, handleClickOrganic } =
    CalculatorPageWL_ESController();

  return (
    <>
      {contextCalculator.stepCalculator === "1" && (
        <PersonalDataStep
          dateOfBirth={reorderDate(contextCalculator.dateOfBirth)}
          handleClick={contextCalculator.handleClickPersonalData}
          disabledButton={
            contextCalculator.disabledButtons.dateOfBirth ||
            !contextCalculator.sex
          }
          invalidDateMessage={contextCalculator.translate("error.isNull")}
          setDateOfBirth={contextCalculator.setDateOfBirth}
          setSex={contextCalculator.setSex}
          sex={contextCalculator.sex}
          textBtnContinue={contextCalculator.translate("continuar")}
          textDateInput={contextCalculator.translate(
            "calculatorPage.dateLabel"
          )}
          textDay={contextCalculator.translate("calculatorPage.textDay")}
          textMonth={contextCalculator.translate("calculatorPage.textMonth")}
          textSex={contextCalculator.translate("calculatorPage.sexLabel")}
          textSexF={contextCalculator.translate("calculatorPage.sexF")}
          textSexM={contextCalculator.translate("calculatorPage.sexM")}
          textYear={contextCalculator.translate("calculatorPage.textYear")}
          validations={contextCalculator.validations}
          initalErrorBirthDate={contextCalculator.errorBirthDate}
        />
      )}
      {contextCalculator.stepCalculator === "2" && (
        <CapitalStep
          TextOtherCapital={contextCalculator.translate(
            "calculatorPage.otherCapital"
          )}
          back={contextCalculator.translate("back")}
          backCapital={contextCalculator.translate(
            "calculatorPage.backCapital"
          )}
          disabledButton={contextCalculator.disabledButtons.capital}
          handleClick={contextCalculator.navigateTo}
          handleClickBack={contextCalculator.navigateBack}
          insuranceCover={contextCalculator.insuranceCover}
          maxCapital={contextCalculator.maxCapital}
          minCapital={contextCalculator.minCapital}
          otherCapitalBetween={contextCalculator.translate(
            "calculatorPage.otherCapitalBetween"
          )}
          setInsuranceCover={contextCalculator.setInsuranceCover}
          subtextCapitalQuestion={contextCalculator.translate(
            "calculatorPage.subtextCapitalQuestion"
          )}
          textAnd={contextCalculator.translate(
            "aggregator.personalInformation.title3"
          )}
          textCapitalQuestion={contextCalculator.translate(
            "calculatorPage.capitalQuestion"
          )}
          textContinue={contextCalculator.translate("continuar")}
          validations={contextCalculator.validations}
          country="es"
        />
      )}

      {contextCalculator.stepCalculator === "3" && (
        <ContactStep
          back={contextCalculator.translate("back")}
          btnGetPrice={contextCalculator.translate(
            "calculatorPage.btnGetPrice"
          )}
          disabledButton={
            contextCalculator.disabledButtons.phone ||
            contextCalculator.disabledButtons.email ||
            !contextCalculator.checkedConditions ||
            !contextCalculator.checkedConditions2
          }
          checkedConditions={contextCalculator.checkedConditions}
          email={contextCalculator.email}
          handleClick={handleClickOrganic}
          handleClickBack={contextCalculator.navigateBack}
          phoneNumber={contextCalculator.phoneNumber}
          setCheckedConditions={contextCalculator.handleCheckConditions}
          setCheckedConditions2={contextCalculator.handleCheckConditions2}
          setEmail={contextCalculator.setEmail}
          setPhoneNumber={contextCalculator.setPhoneNumber}
          setUrl={contextCalculator.setUrl}
          secondCheck={true}
          textAcceptDataProcessing={contextCalculator.translate(
            "calculatorPage.acceptDataProcessing"
          )}
          textAcceptDataProcessing2={contextCalculator.translate(
            "calculatorPage.acceptDataProcessing2"
          )}
          textEmailQuestion={contextCalculator.translate(
            "calculatorPage.emailQuestion"
          )}
          textPhoneQuestion={contextCalculator.translate(
            "calculatorPage.phoneQuestion"
          )}
          validations={contextCalculator.validations}
          initialErrorPhoneNumber={contextCalculator.error}
          initialErrorEmail={contextCalculator.errorEmail}
        />
      )}
      {contextCalculator.stepCalculator === "4" && <QuotePage />}
    </>
  );
};

export default CalculatorPageWL_ES;
