import React from 'react';
import './inputGetLife.scss';
import InfoIcon from '@material-ui/icons/Info';
import { Tooltip } from '@material-ui/core';

export default function InputGetLife(props) {
    const handleChange = (e) => {
        if (props.sendInputText !== undefined) {
            props.sendInputText(e.key);
        }
    };

    const inputFilterStyle = () => {
        if (
            props.actualUrl === '/info/family' ||
            props.actualUrl === '/info/loan' ||
            props.actualUrl === '/info' ||
            props.actualUrl === '/step/4' ||
            props.actualUrl === '/productQuestions' ||
            props.actualUrl === '/waitingsignature'
        ) {
            return { width: '340px' };
        } else if (
            (props.actualUrl === '/coverage/1' && props.label === 'Ciudad') ||
            (props.actualUrl === '/beneficiaries/3' &&
                props.label === 'Nombre completo de la entidad') ||
            (props.actualUrl === '/beneficiaries/4' &&
                props.label === 'Razón Social')
        ) {
            return { width: '' };
        }
    };

    return (
        <div className="inputGetLife-container">
            {props.toolTip ? (
                <Tooltip title={props.toolTip} placement="bottom" arrow>
                    <div className={'inputGetlife-tooltip'}>
                        {props.label && <label>{props.label}</label>}
                        <InfoIcon className={'offers_box_info'} />
                    </div>
                </Tooltip>
            ) : (
                props.label && <label>{props.label}</label>
            )}

            <input
                name={props.name}
                className={
                    props.error === true
                        ? 'inputGetLife-input-error'
                        : 'inputGetLife-input'
                }
                style={inputFilterStyle()}
                disabled={props.disabled}
                value={props.answer}
                onChange={
                    props.handleTarget
                        ? (e) => props.handleValues(e.target)
                        : (e) => props.handleValues(e.target.value)
                }
                type={props.contentType}
                id={props.id}
                placeholder={props.placeholder}
                onFocus={() => (props.onFocus ? props.onFocus() : null)}
                // onKeyDown={onKeyDown}
                onBlur={(e) => (props.onBlur ? props.onBlur(e) : null)}
                onKeyUp={handleChange}
                inputMode={props.inputMode ? props.inputMode : ''}
            />

            {props.error === true && (
                <div className="inputGetLife-errorMessageContainer">
                    <p className="inputGetLife-errorMessage">
                        {props.errorMessage}
                    </p>
                </div>
            )}
        </div>
    );
}
