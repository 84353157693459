import { useContext } from "react";
import { I18nContext } from "../../../../../context/i18n.context";
import { DataI } from "../../../../../api/request/Lead/Interfaces/DataInterface.response";
import { ButtonDS, GlobalIcon } from "get-life-storybook-ts";
import { GlobalTypeFormPolicy } from "../../../../../utils/InternationlChanges";
import { DOMAIN } from "../../../../../constants/Global";
import { LanguageT } from "../../../../../context/GetLifeContext.context";

interface NewProfileI {
  userData?: DataI;
}

const NewProfile = ({ userData }: NewProfileI) => {
  const {
    state: { translate },
  } = useContext(I18nContext);
  const lead = userData?.lead;

  const modifyData = () =>
    window.open(GlobalTypeFormPolicy[DOMAIN as LanguageT], "_blank");

  return (
    <div className="grid md:grid-cols-2 gap-[32px]">
      <div className="dashboard-card flex flex-col">
        <div className="text-[var(--dark-gray)] flex flex-row items-center gap-[8px] flex-wrap pb-[16px] border-b-[var(--light-gray)] border-b">
          <GlobalIcon
            iconName="UserCircleIcon"
            size="XS"
            color="currentColor"
          />
          <span className="flex-1 BodyL font-bold">
            {translate("newDashboard.menu.profile.insured")}
          </span>
        </div>
        <div className="py-[16px] border-b-[var(--light-gray)] border-b flex flex-col">
          <span className="text-[#7B7B7B] BodyM font-medium">
            {translate("newDashboard.menu.profile.name")}
          </span>
          <span className="text-[var(--dark-gray)] BodyL font-medium">{`${lead?.name} ${
            lead?.lastName ?? ""
          } ${lead?.lastName2 ?? ""}`}</span>
        </div>
        {lead?.idNumber && lead?.idNumber !== "" ? (
          <div className="py-[16px] border-b-[var(--light-gray)] border-b flex flex-col">
            <span className="text-[#7B7B7B] BodyM font-medium">
              {translate("newDashboard.menu.profile.id")}
            </span>
            <span className="text-[var(--dark-gray)] BodyL font-medium">
              {lead?.idNumber}
            </span>
          </div>
        ) : null}
        {lead?.email && lead?.email !== "" ? (
          <div className="py-[16px] border-b-[var(--light-gray)] border-b flex flex-col">
            <span className="text-[#7B7B7B] BodyM font-medium">
              {translate("newDashboard.menu.profile.email")}
            </span>
            <span className="text-[var(--dark-gray)] BodyL font-medium break-words">
              {lead?.email}
            </span>
          </div>
        ) : null}
        {lead?.addressLine && lead?.addressLine !== "" ? (
          <div className="py-[16px] border-b-[var(--light-gray)] border-b flex flex-col">
            <span className="text-[#7B7B7B] BodyM font-medium">
              {translate("newDashboard.menu.profile.address")}
            </span>
            <span className="text-[var(--dark-gray)] BodyL font-medium break-words">
              {lead?.addressLine}
            </span>
          </div>
        ) : null}
        {lead?.phone && lead?.phone !== "" ? (
          <div className="py-[16px] border-b-[var(--light-gray)] border-b flex flex-col">
            <span className="text-[#7B7B7B] BodyM font-medium">
              {translate("newDashboard.menu.profile.phone")}
            </span>
            <span className="text-[var(--dark-gray)] BodyL font-medium">
              {lead?.phone}
            </span>
          </div>
        ) : null}
        <ButtonDS
          label={translate("newDashboard.menu.profile.modifyData")}
          buttonType="tertiary"
          ghost={true}
          className="w-fit ml-auto mt-[8px]"
          size="32"
          onClick={modifyData}
        />
      </div>
      <div className="dashboard-card flex flex-col">
        <div className="text-[var(--dark-gray)] flex flex-row items-center gap-[8px] flex-wrap pb-[16px] border-b-[var(--light-gray)] border-b">
          <GlobalIcon
            iconName="UserCircleIcon"
            size="XS"
            color="currentColor"
          />
          <span className="flex-1 BodyL font-bold">
            {translate("newDashboard.menu.profile.taker")}
          </span>
        </div>
        <div className="py-[16px] border-b-[var(--light-gray)] border-b flex flex-col">
          <span className="text-[#7B7B7B] BodyM font-medium">
            {translate("newDashboard.menu.profile.name")}
          </span>
          <span className="text-[var(--dark-gray)] BodyL font-medium">{`${lead?.name} ${
            lead?.lastName ?? ""
          } ${lead?.lastName2 ?? ""}`}</span>
        </div>
        {lead?.idNumber && lead?.idNumber !== "" ? (
          <div className="py-[16px] border-b-[var(--light-gray)] border-b flex flex-col">
            <span className="text-[#7B7B7B] BodyM font-medium">
              {translate("newDashboard.menu.profile.id")}
            </span>
            <span className="text-[var(--dark-gray)] BodyL font-medium">
              {lead?.idNumber}
            </span>
          </div>
        ) : null}
        {lead?.email && lead?.email !== "" ? (
          <div className="py-[16px] border-b-[var(--light-gray)] border-b flex flex-col">
            <span className="text-[#7B7B7B] BodyM font-medium">
              {translate("newDashboard.menu.profile.email")}
            </span>
            <span className="text-[var(--dark-gray)] BodyL font-medium break-words">
              {lead?.email}
            </span>
          </div>
        ) : null}
        {lead?.addressLine && lead?.addressLine !== "" ? (
          <div className="py-[16px] border-b-[var(--light-gray)] border-b flex flex-col">
            <span className="text-[#7B7B7B] BodyM font-medium">
              {translate("newDashboard.menu.profile.address")}
            </span>
            <span className="text-[var(--dark-gray)] BodyL font-medium break-words">
              {lead?.addressLine}
            </span>
          </div>
        ) : null}
        {lead?.phone && lead?.phone !== "" ? (
          <div className="py-[16px] border-b-[var(--light-gray)] border-b flex flex-col">
            <span className="text-[#7B7B7B] BodyM font-medium">
              {translate("newDashboard.menu.profile.phone")}
            </span>
            <span className="text-[var(--dark-gray)] BodyL font-medium">
              {lead?.phone}
            </span>
          </div>
        ) : null}
        <ButtonDS
          label={translate("newDashboard.menu.profile.modifyData")}
          buttonType="tertiary"
          ghost={true}
          className="w-fit ml-auto mt-[8px]"
          size="32"
          onClick={modifyData}
        />
      </div>
    </div>
  );
};

export default NewProfile;
