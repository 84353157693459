import CircleNumber from "../../../../../../components/Components/circleNumber/CircleNumber";
import PaymentCardContent from "../PaymentCardContent/PaymentCardContent.component";

import { Accordion, Typography } from "get-life-storybook-ts";
import { StripeProvider } from "../../../../../../context/StripeContext.context";
import PersonalData from "../PersonalData/PersonalData.component";

const PaymentAccordion = ({
  cardError,
  handleCheckbox,
  handleCheckbox2,
  handleIsLoading,
  handleStripeStatus,
  isButtonDisabled,
  leadId,
  navigateTo,
  openBox,
  payConfirm,
  payConfirm2,
  saveData,
  setupIntent,
  stripeInputStatus,
  token,
  translate,
  checkout
}: any) => {
  return (
    <>
      <Accordion
        expanded={openBox.userData}
        headerContent={
          <div className="flex items-center gap-2">
            <CircleNumber content="1" active={true} />
            <Typography
              variant="H3"
              color="purple"
              text={translate("payment.paymentAccordion.header.personalData")}
            />
          </div>
        }
        bodyContent={
          checkout && <PersonalData userData={checkout} saveData={saveData} />
        }
      />
      <Accordion
        expanded={openBox.creditCardData}
        headerContent={
          <div className="flex items-center gap-2">
            <CircleNumber content="2" active={openBox.creditCardData} />
            <Typography
              variant="H3"
              color={openBox.creditCardData && "purple"}
              text={translate("payment.paymentAccordion.header.creditCard")}
            />
          </div>
        }
        bodyContent={
          <StripeProvider
            handleIsLoading={handleIsLoading}
            setupIntent={setupIntent}
            leadId={leadId}
            token={token}
            checkout={checkout}
            navigate={navigateTo}
          >
            <PaymentCardContent
              buttonLabel={translate("payment.paymentCard.button.submitAB")}
              cardError={cardError}
              creditCardData={openBox.creditCardData}
              handleCheckbox={handleCheckbox}
              handleCheckbox2={handleCheckbox2}
              handleStripeStatus={handleStripeStatus}
              isButtonDisabled={isButtonDisabled}
              payConfirm={payConfirm}
              payConfirm2={payConfirm2}
              setupIntent={setupIntent}
              stripeInputStatus={stripeInputStatus}
              translate={translate}
            />
          </StripeProvider>
        }
      />
    </>
  );
};

export default PaymentAccordion;
