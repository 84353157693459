import HttpGlobal from "../Global/HttpGlobal.class";

export default class HttpIdCardRepository extends HttpGlobal {
  private readonly API_URL_IDCARD:
    | string
    | undefined = `${process.env.REACT_APP_CUSTOMER_APP_URL}api/idcard`;

  private readonly API_URL_LEAD:
    | string
    | undefined = `${process.env.REACT_APP_CUSTOMER_APP_URL}api/lead`;

  getIdCardToken = async () => {
    const response = await this.generalGet(
      `${this.API_URL_IDCARD}/user/token/${this.leadId}`
    );
    return response;
  };
  getReport = async () => {
    const response = await this.generalGet(
      `${this.API_URL_IDCARD}/report/${this.leadId}`
    );
    return response;
  };

  getDocumentsBiometry = async () => {
    const response = await this.generalGet(
      `${this.API_URL_LEAD}/biometry/available-documents/${this.leadId}`
    );
    return response;
  };

  getUrlBiometry = async (DOC: string) => {
    const response = await this.generalGet(
      `${this.API_URL_LEAD}/biometry/${DOC}/${this.leadId}`
    );
    return response;
  }

  getStatusBiometry = async () => {
    const response = await this.generalGet(
      `${this.API_URL_LEAD}/biometry/status/${this.leadId}`
    );
    return response;
  }

  getValidateBiometry = async () => {
    const response = await this.generalGet(
      `${this.API_URL_LEAD}/biometry/validate/${this.leadId}`
    );
    return response;
  }

  skipBiometry = async () => {
    const response = await this.generalPut({
      url: `${this.API_URL_LEAD}/update-to-legal/${this.leadId}`,
      dataRequest: {},
    });

    return response;
  };
   
}
