import React from "react";
import "./Box.styles.scss";

interface BoxI {
  children: React.ReactNode;
  classNameChildren?: string;
  classNameContainer?: string;
  classNameTitle?: string;
  title: string;
}

const Box: React.FC<BoxI> = ({
  children,
  classNameChildren = "",
  classNameContainer = "",
  classNameTitle = "",
  title,
}): React.JSX.Element => {
  return (
    <div className={`box ${classNameContainer}`}>
      <p className={`box__title ${classNameTitle}`}>{title}</p>
      <div className={`box__children ${classNameChildren}`}>{children}</div>
    </div>
  );
};

export default Box;
