import { PausesForBreath } from "get-life-storybook-ts";
import * as React from "react";
import { useNavigate, useLocation } from "react-router";
import HttpLeadRepository from "../../api/request/Lead/Lead.service";
import { GetlifeContext } from "../../context/GetLifeContext.context";
import { I18nContext } from "../../context/i18n.context";
import { useContext } from "react";
import { GetLifeTrackerContext } from "../../context/GetLifeTracker.context";

const IncomingUsersPage = () => {
  const {
    state: { translate },
  } = React.useContext(I18nContext);
  const { token, leadId, setToken, setBrokerId, setShowFooter, setShowHeader, setIsABtesting } =
    React.useContext(GetlifeContext);
  const { identifyFS } = useContext(GetLifeTrackerContext);
  const leadRepository = new HttpLeadRepository(leadId, token);
  const navigate = useNavigate();
  const Location = useLocation();

  const route = Location.pathname;

  React.useEffect(() => {
    if (route === "/pauseAB") {
      leadRepository.assingABTest();
    }
    setShowFooter(false);
    setShowHeader(false);
  }, []);

  const navigateToQuestions = () => {
    leadRepository.getToken(leadId).then((res) => {
      const { token, brokerId, email, isAbTest } = res;
      setToken(token);
      setBrokerId(brokerId);
      identifyFS({
        lead: leadId,
        data: {
          emailDomain: email && email.split("@")[1],
          email: email,
          brokerId: brokerId,
        },
      });
      
      setIsABtesting(isAbTest)

      navigate(`/productQuestions`);
    });
  };

  const navigateTo = () => {
    if (token) {
      navigate(`/productQuestions`);
    } else {
      navigateToQuestions();
    }
  };

  return (
    <PausesForBreath
      title={translate("incomingUsersPage.title")}
      subtitle={translate("incomingUsersPage.subtitle")}
      text={translate("incomingUsersPage.text")}
      buttonLabel={translate("incomingUsersPage.button")}
      onClick={() => navigateTo()}
    />
  );
};

export default IncomingUsersPage;
