import CloseIconModal from 'get-life-storybook-ts/lib/components/Icons/CloseIconModal';
import './customModal.scss';

const Modal = ({ handleClose, show, children }: any) => {
    const showHideClassName = show ? "modal display-block" : "modal display-none";

    return (
        <div className={showHideClassName}>
            
            <section className="modal-main">
                <section className='modal-header'>
                    <div className="customModal-close">
                        <CloseIconModal onClick={() => handleClose()}/>
                    </div>
                    
                </section>
                {children}
                
            </section>
        </div>
    );
};


export default Modal;