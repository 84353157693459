import {
  CustomModal,
  GlobalIcon,
  Price,
  Typography,
} from "get-life-storybook-ts";
import "./ConditionsSummary.scss";
import numberFormat, { parsePriceDecimal } from "../../../utils/Utils";
import { useContext, useRef, useState } from "react";
import { I18nContext } from "../../../context/i18n.context";

interface ConditionsSummaryI {
  prices?: {
    monthly: {
      price: number;
      promotedPrice: number;
    };
    yearly: {
      price: number;
      promotedPrice: number;
    };
  };
  capital?: number;
}

const ConditionsSummary = ({ prices, capital }: ConditionsSummaryI) => {
  const {
    state: { translate },
  } = useContext(I18nContext);
  const [extended, setExtended] = useState<boolean>(
    window.innerWidth > 764 ? true : false
  );
  const [extendedCoverage, setExtendedCoverage] = useState<boolean>(true);
  const [extendedNotCoverage, setExtendedNotCoverage] = useState<boolean>(true);

  const getOriginalPrice = () => {
    if (prices && prices.monthly.price !== prices.monthly.promotedPrice) {
      return numberFormat(prices.monthly.price);
    }
    return undefined;
  };

  const toggleAccordion = () => {
    if (window.innerWidth < 764) setExtended(!extended);
  };

  const finalPrice = numberFormat(prices?.monthly.promotedPrice ?? 0);
  const originalPrice = getOriginalPrice();
  const formatedCapital = numberFormat(capital ?? 0, "capital");

  const capitalModal = useRef<any>();
  const coverageModal = useRef<any>();

  const handleShowCapitalModal = () => capitalModal.current.handleShowPortal();
  const handleShowCoverageModal = () =>
    coverageModal.current.handleShowPortal();

  return (
    <>
      <div className="ConditionsSummary">
        <div className="ConditionsSummary__heading" onClick={toggleAccordion}>
          <Typography
            text={translate("partners.summary.title")}
            variant="BodyLarge"
            weight="700"
            className="ConditionsSummary__title"
          />
          <span className="ConditionsSummary__logo">
            <GlobalIcon iconName="LogoLife5Blue" />
          </span>
          {originalPrice ? (
            <div className="ConditionsSummary__discount">
              <Typography
                text={originalPrice}
                variant="SpanMedium"
                weight="400"
              />
            </div>
          ) : null}
          <div className="ConditionsSummary__anualPrice">
            <Price
              decimals=""
              price={parsePriceDecimal(1)}
              priceLabel={translate("calculatorPage.textMonthly")}
              loading={!finalPrice}
              size="small"
              frequency="Per i primi 3 mesi"
              after={`Successivamente: <b>${finalPrice}/mese</b>`}
            />
            <Typography
              text=""
              variant="SpanMedium"
              weight="400"
              className="ConditionsSummary__extender"
            >
              {translate(
                extended
                  ? "partners.summary.lessDetails"
                  : "partners.summary.seeDetails"
              )}
            </Typography>
          </div>
        </div>
        <div
          className={`ConditionsSummary__accordion ${
            extended ? "ConditionsSummary__accordionExtended" : ""
          }`}
        >
          <div
            className="ConditionsSummary__notExtendable"
            onClick={handleShowCapitalModal}
          >
            <GlobalIcon iconName="SaveMoneyIcon" size="M" />
            <div>
              <Typography text="" variant="BodyMedium" weight="500">
                {translate("partners.summary.capital")}{" "}
                <GlobalIcon iconName="InfoIcon" color="#7B7B7B" size="XXXS" />
              </Typography>
              <Typography
                text={formatedCapital}
                variant="H3"
                weight="700"
                className="ConditionsSummary__highlightedField"
              />
            </div>
          </div>
          <div
            className="ConditionsSummary__notExtendable"
            onClick={handleShowCoverageModal}
          >
            <GlobalIcon iconName="UmbrellaIcon" size="M" />
            <div>
              <Typography text="" variant="BodyMedium" weight="500">
                {translate("partners.summary.coverage")}{" "}
                <GlobalIcon iconName="InfoIcon" color="#7B7B7B" size="XXXS" />
              </Typography>
              <Typography
                text={""}
                variant="H3"
                weight="700"
                className="ConditionsSummary__highlightedField"
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: translate("partners.summary.appliedCoverage"),
                  }}
                />
              </Typography>
            </div>
          </div>
          <div className="ConditionsSummary__extendable">
            <GlobalIcon iconName="SimpleShieldIcon" size="M" />
            <Typography
              text={translate("partners.summary.whatIsCovered")}
              variant="BodyMedium"
              weight="500"
            />
            <button
              aria-label="toggle information"
              onClick={() => setExtendedCoverage(!extendedCoverage)}
              className={`ConditionsSummary__chevron ${
                extendedCoverage ? "ConditionsSummary__chevron--rotate" : ""
              }`}
            >
              <GlobalIcon iconName="ArrowDownIcon" size="XXS" />
            </button>
            {extendedCoverage ? (
              <div className="ConditionsSummary__extendableList">
                <Typography text="" variant="Body" weight="400">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: translate("partners.summary.coveredList"),
                    }}
                  />
                </Typography>
              </div>
            ) : null}
          </div>
          <div className="ConditionsSummary__extendable">
            <GlobalIcon iconName="NotCoverIcon" size="M" />
            <Typography
              text={translate("partners.summary.whatIsNotCovered")}
              variant="BodyMedium"
              weight="500"
            />
            <button
              aria-label="toggle information"
              onClick={() => setExtendedNotCoverage(!extendedNotCoverage)}
              className={`ConditionsSummary__chevron ${
                extendedNotCoverage ? "ConditionsSummary__chevron--rotate" : ""
              }`}
            >
              <GlobalIcon iconName="ArrowDownIcon" size="XXS" />
            </button>
            {extendedNotCoverage ? (
              <div className="ConditionsSummary__extendableList">
                <Typography text="" variant="Body" weight="400">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: translate("partners.summary.notCoveredList"),
                    }}
                  />
                </Typography>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <CustomModal
        ref={capitalModal}
        ModalTitle={
          <Typography
            text={translate("partners.capital.modal.title")}
            variant="H3"
            weight="700"
            className="text-dark-gray-100"
          />
        }
        ModalBody={
          <div className="text-dark-gray-100">
            <GlobalIcon
              iconName="CalculatorIcon"
              size="L"
              color="var(--theme-primary)"
              className="mb-[16px]"
            />
            <Typography
              text=""
              variant="Body"
              weight="400"
              className="mb-[24px]"
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: translate("partners.capital.modal.text"),
                }}
              />
            </Typography>
          </div>
        }
        fitContent
      />
      <CustomModal
        ref={coverageModal}
        ModalTitle={
          <Typography
            text={translate("partners.coverage.modal.title")}
            variant="H3"
            weight="700"
            className="text-dark-gray-100"
          />
        }
        ModalBody={
          <div className="text-dark-gray-100">
            <Typography
              text={translate("partners.coverage.modal.text")}
              variant="Body"
              weight="400"
              className="mb-[24px]"
            />
            <div
              className="flex flex-row gap-[16px]"
              style={{ paddingBottom: "12px" }}
            >
              <GlobalIcon
                iconName="CandleIcon"
                size="L"
                color="var(--theme-primary)"
                className="mb-[16px]"
              />
              <div className={"flex-1"}>
                <Typography
                  text={translate("partners.coverage.modal.section1.title")}
                  variant="H3"
                  weight="700"
                  className="mb-[4px]"
                  color="[var(--theme-primary)]"
                />
                <Typography
                  text={translate("partners.coverage.modal.section1.text")}
                  variant="Body"
                  weight="400"
                />
              </div>
            </div>
            <div className="flex flex-row gap-[16px] pt-[12px] border-t border-[#ECECFE]">
              <GlobalIcon
                iconName="SaveMoneyIcon"
                size="L"
                color="var(--theme-primary)"
                className="mb-[16px]"
              />
              <div className={"flex-1"}>
                <Typography
                  text={translate("partners.coverage.modal.section2.title")}
                  variant="H3"
                  weight="700"
                  className="mb-[4px]"
                  color="[var(--theme-primary)]"
                />
                <Typography
                  text={translate("partners.coverage.modal.section2.text")}
                  variant="Body"
                  weight="400"
                />
              </div>
            </div>
          </div>
        }
        fitContent
      />
    </>
  );
};

export default ConditionsSummary;
