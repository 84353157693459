import { useContext, useEffect, useState } from "react";
import { I18nContext } from "../../context/i18n.context";
import { GetlifeContext, useQuery } from "../../context/GetLifeContext.context";
import HttpLeadRepository from "../../api/request/Lead/Lead.service";
import {
  FacileBasePriceI,
  FacileLeadI,
  PostFacileLeadI,
  PostFacileOrganicI,
} from "../../api/request/Lead/Interfaces/DataInterface.response";
import { GetLifeTrackerContext } from "../../context/GetLifeTracker.context";
import { useNavigate } from "react-router";

const FacilePartnerController = () => {
  const {
    state: { translate },
  } = useContext(I18nContext);
  const navigate = useNavigate();
  let query = useQuery();
  const {
    setLeadId,
    setToken,
    utm_source,
    utm_medium,
    utm_campaign,
    utm_content,
    utm_term,
    utm_test,
    gclid,
    msclkid,
    fbclid,
    getEntryPage,
    getReferrerUrl,
    getABTestVersion,
    handleCallMe
  } = useContext(GetlifeContext);

  const leadRepository = new HttpLeadRepository();

  const { handleTrackerQuestion, identifyFS } = useContext(
    GetLifeTrackerContext
  );

  const token = query.get("TOKEN") || query.get("token");

  const [leadData, setLeadData] = useState<FacileLeadI>();
  const [form, setForm] = useState<{
    email: string;
    phone: string;
    conditions: boolean;
    conditions2: boolean;
    conditions3: boolean;
  }>({
    email: "",
    phone: "",
    conditions: false,
    conditions2: false,
    conditions3: false,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [prices, setPrices] = useState<FacileBasePriceI>();

  const sendData = async (form: any) => {
    if (loading) return;

    setLoading(true);

    const { email, phone, conditions3 } = form;
    const data: PostFacileLeadI = {
      name: leadData!.name,
      lastName: leadData!.lastName,
      birthDate: leadData!.dateOfBirth,
      capital: leadData!.capital,
      nicotine: leadData!.nicotine ? "yes" : "no",
      gender: leadData!.gender,
      email,
      phone,
      userAcceptsCommunications: conditions3
    };

    leadRepository
      .postFacileOrganic(data)
      .then((response: PostFacileOrganicI) => {
        const { leadId, coverage, capital } = response;

        const dataInfo = {
          calculatedCapital: capital,
          calculatedPremium: prices!.monthly.promotedPrice,
          coverage: coverage === "premium" ? "FIPA" : "F",
          email: data.email,
          intent: undefined,
          leadId: leadId,
          page: window.location.href,
          phone: data.phone,
          postalCode: undefined,
          utm_source: leadData!.externalReference ? "facile" : utm_source,
          utm_campaign: utm_campaign,
          utm_medium: leadData!.externalReference ? "affiliate" : utm_medium,
          utm_content: utm_content,
          utm_term: utm_term,
          utm_test: utm_test,
          gclid: gclid,
          msclkid: msclkid,
          fbclid: fbclid,
          platform: "App",
          entryPage: getEntryPage(),
          referrerUrl: getReferrerUrl(),
          ABTestVersion: getABTestVersion(),
        };

        setLeadId(leadId);
        setToken("");

        identifyFS({
          lead: leadId,
          data: {
            emailDomain: email && email.split("@")[1],
            email: email,
            brokerId: "null",
          },
        });

        handleTrackerQuestion({
          type: "FS",
          event: "leadCreated",
          data: {
            birthDate: new Date(data.birthDate),
            ...dataInfo,
          },
        });
        handleTrackerQuestion({
          type: "GA",
          data: {
            event: "leadCreated",
            birthDate: data.birthDate,
            ...dataInfo,
          },
        });

        handleTrackerQuestion({
          type: "FS",
          event: "calculatorQuoteGenerated",
          data: {
            birthDate: new Date(data.birthDate),
            ...dataInfo,
          },
        });
        handleTrackerQuestion({
          type: "GA",
          data: {
            event: "calculatorQuoteGenerated",
            birthDate: data.birthDate,
            ...dataInfo,
          },
        });

        handleTrackerQuestion({
          type: "tracker",
          data: {
            leadUlid: leadId,
            campaign: leadData!.externalReference ? leadData!.externalReference : "landing-facile",
            utmSource: leadData!.externalReference && "facile",
            utmMedium: leadData!.externalReference && "affiliate",
          },
        });

        navigate(`/calculator/4?lead=${leadId}&gtf_campaign=facile`);
      })
      .catch((e) => console.log(e))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    (async () => {
      if (token) {
        await leadRepository
          .getPartnerFacile(token)
          .then(async (response) => {
            const {
              dateOfBirth,
              gender,
              capital,
              nicotine,
              email,
              phoneNumber,
            } = response;
            setForm({ ...form, email, phone: phoneNumber });
            setLeadData(response);
            const basePrices = await leadRepository.getPartnerFacilePrice({
              dateOfBirth,
              gender,
              capital,
              nicotine,
            });
            setPrices(basePrices);
          })
          .catch((e) => navigate("/calculator/1"));
      }
      if (!token) navigate("/calculator/1");
    })();
  }, [token]);

  return { translate, leadData, form, sendData, prices, handleCallMe };
};

export default FacilePartnerController;
