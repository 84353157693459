import { reorderDate } from "get-life-storybook-ts";
import { useContext } from "react";
import { GetLifeCalculatorContext } from "../../../context/GetLifeCalculator.context";

const CalculatorPageFRController = () => {
  const contextCalculator = useContext(GetLifeCalculatorContext);

  const handleClickOrganic = () => {
    contextCalculator.handleClickOrganic({
      birthDate: reorderDate(contextCalculator.dateOfBirth),
      campaign: contextCalculator.gtf_campaign || "",
      capital: parseFloat(contextCalculator.insuranceCover),
      email: contextCalculator.email,
      gender: contextCalculator.sex,
      initialCapital: contextCalculator.minCapital,
      phone: contextCalculator.phoneNumber,
      zipcode: contextCalculator.postcode,
      nicotine: contextCalculator.smoke,
      scorProfessionId: contextCalculator.job,
      distanceUpper25000KmPerYear: contextCalculator.workDistance,
      heightMoreThan3m: contextCalculator.workHeight,
      loadMoreThan5Kg: contextCalculator.workWeight,
    });
  };

  const options = {
    smoke: [
      {
        value: "yes",
        text: contextCalculator.translate("yes"),
        label: contextCalculator.translate("yes"),
        illustration: "SmokerIcon" as any,
        variant: "Illustration" as any,
      },
      {
        value: "no",
        text: contextCalculator.translate("no"),
        label: contextCalculator.translate("no"),
        illustration: "NonSmokerIcon" as any,
        variant: "Illustration" as any,
      },
    ],
    workDistance: [
      {
        value: false,
        text: contextCalculator.translate(
          "calculatorPage.options.workDistance.minus"
        ),
        label: contextCalculator.translate(
          "calculatorPage.options.workDistance.minus"
        ),
      },
      {
        value: true,
        text: contextCalculator.translate(
          "calculatorPage.options.workDistance.plus"
        ),
        label: contextCalculator.translate(
          "calculatorPage.options.workDistance.plus"
        ),
      },
    ],
    workHeight: [
      {
        value: true,
        text: contextCalculator.translate("yes"),
        label: contextCalculator.translate("yes"),
      },
      {
        value: false,
        text: contextCalculator.translate("no"),
        label: contextCalculator.translate("no"),
      },
    ],
    workWeight: [
      {
        value: true,
        text: contextCalculator.translate("yes"),
        label: contextCalculator.translate("yes"),
      },
      {
        value: false,
        text: contextCalculator.translate("no"),
        label: contextCalculator.translate("no"),
      },
    ],
  };

  return {
    contextCalculator,
    handleClickOrganic,
    options,
    reorderDate,
  };
};

export default CalculatorPageFRController;
