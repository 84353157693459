import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router";

import Add from "./../../../../components/Components/Add/Add";
import Remove from "../../../../components/Components/Remove/Remove";
import "./formBankingEntity.scss";
import HttpLeadRepository from "../../../../api/request/Lead/Lead.service";
import { I18nContext } from "../../../../context/i18n.context";
import { submitValidationError } from "../../../../utils/validations";
import { ButtonDS, NumberInput, TextInput } from "get-life-storybook-ts";

const validations = [
  {
    name: "bankName",
    type: "string",
    errorText: "Caracteres no permitidos",
    required: true,
    requiredText: "Este campo es obligatorio",
    regExp:
      /^[a-zA-ZÀ-ÿ\u00f1\u00d1\.]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1\.]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1\.]+$/,
  },
  {
    name: "loanMortgageNumber",
    type: "string",
    errorText: "El formato debe de ser del tipo xxxx-xxxx-xx-xxxxxxxxxx",
    required: true,
    requiredText: "Este campo es obligatorio",
    regExp: /([0-9]){4}(-)([0-9]){4}(-)([0-9]){2}(-)([0-9]){10}$/y,
  },
  {
    name: "capital",
    errorText: "Introduzca una cifra válida",
    required: true,
    requiredText: "Este campo es obligatorio",
    regExp: /^([0-9])*$/,
  },
];

export function Body(props) {
  const { index, bankingEntitys, bank, error, errorMessage } = props;
  const [bankName, setBankName] = useState(bank.bankName);
  const [loanMortgageNumber, setLoanMortgageNumber] = useState(
    bank.loanMortgageNumber
  );
  const [capital, setCapital] = useState(bank.capital);

  useEffect(() => {
    setBankName(bank.bankName);
    setLoanMortgageNumber(bank.loanMortgageNumber);
    setCapital(bank.capital);
  }, [bank]);

  useEffect(() => {
    if (typeof bankingEntitys[index] !== "undefined") {
      bankingEntitys[index].bankName = bankName;
      bankingEntitys[index].loanMortgageNumber = loanMortgageNumber;
      bankingEntitys[index].capital = capital;
      props.handleBankingEntitys(bankingEntitys);
    }
  }, [bankName, loanMortgageNumber, capital]);

  return (
    <>
      <div className="grid-beneficiary">
        <div className="row">
          <div>
            <TextInput
              initialValue={bankName}
              label={"Nombre completo de la entidad"}
              placeholder="Bankinter S.A. "
              onValueChange={setBankName}
              //validationChecks={[(value) => validations(value, "name")]}
            />
            {error && error[`bankName${index}`] && (
              <p>{errorMessage[`bankName${index}`]}</p>
            )}
          </div>
        </div>

        <div className="row">
          <div>
            <TextInput
              initialValue={loanMortgageNumber}
              label={"Nº Prestamo o Hipoteca"}
              placeholder="0123-0123-45-0123456789"
              onValueChange={setLoanMortgageNumber}
              //validationChecks={[(value) => validations(value, "name")]}
            />
            {error && error[`loanMortgageNumber${index}`] && (
              <p>{errorMessage[`loanMortgageNumber${index}`]}</p>
            )}
          </div>
          <div className="capital">
            <NumberInput
              initialValue={capital}
              label={"Importe de Capital"}
              placeholder="50000"
              onValueChange={setCapital}
              //validationChecks={[(value) => validations(value, "name")]}
            />
            {error && error[`capital${index}`] && (
              <p>{errorMessage[`capital${index}`]}</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default function FormBankingEntity(props) {
  const navigate = useNavigate();
  const {
    state: { translate },
  } = useContext(I18nContext);
  const { token, leadId } = props;
  const [availableCapital, setAvailableCapital] = useState(null);
  const [bankingEntitys, setBankingEntitys] = useState("");
  const bank = { bankName: "", loanMortgageNumber: "", capital: "" };

  const leadRepository = new HttpLeadRepository(leadId, token);

  const [error, setError] = useState({});
  const [errorMessage, setErrorMessage] = useState({});

  const handleBankingEntitys = (e) => {
    setBankingEntitys(e);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const fetchData = async () => {
      
      await leadRepository.getBeneficiaries().then((res) => {
        if (res.code === 401) {
          navigate("/login");
        } else {
          const filtered = res.beneficiaries.filter(function (obj) {
            if (obj.type === "bankingEntity") {
              delete obj.id;
              delete obj.businessName;
              delete obj.name;
              delete obj.lastName;
              delete obj.lastName2;
              delete obj.idNumber;
              delete obj.fiscalNumber;
            }
            return obj.type === "bankingEntity";
          });
          filtered.length === 0 && filtered.push(bank);
          handleBankingEntitys(filtered);
          let jetUsedCapital = 0;
          filtered.map((bank) => (jetUsedCapital += bank.capital));
          setAvailableCapital(
            parseInt(res.availableCapital) + parseInt(jetUsedCapital)
          );
        }
      });
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    props.handleValues(bankingEntitys);
  }, [bankingEntitys]);

  const addBank = () => {
    const newBankingEntitys = [...bankingEntitys];
    newBankingEntitys.push(bank);
    handleBankingEntitys(newBankingEntitys);
  };

  const removeBank = (index) => {
    const newBankingEntitys = [...bankingEntitys];
    newBankingEntitys.splice(index, 1);
    handleBankingEntitys(newBankingEntitys);
  };

  const isButtonDisabled = () => {
    const inputFeilds = document.querySelectorAll("input");
    const validInputs = Array.from(inputFeilds).filter(
      (input) =>
        input.value === "" &&
        (input.name === "bankName" ||
          input.name === "loanMortgageNumber" ||
          input.name === "capital")
    );
    if (validInputs.length === 0) {
      return false;
    } else {
      return true;
    }
  };

  const next = () => {
    if (
      !submitValidationError({
        beneficiaries: bankingEntitys,
        availableCapital,
        validations,
        translate,
        setError,
        setErrorMessage,
      })
    ) {
      props.nextQuestion();
    }
  };

  return !bankingEntitys ? (
    "cargando"
  ) : (
    <div className="formBankingEntity-container">
      <div className="formBankingEntity-GridContainer">
        {bankingEntitys.map((bank, index) => (
          <>
            <Body
              key={index}
              index={index}
              bank={bank}
              bankingEntitys={bankingEntitys}
              handleBankingEntitys={handleBankingEntitys}
              error={error}
              setError={setError}
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
              actualUrl={props.actualUrl}
              availableCapital={availableCapital}
              setAvailableCapital={setAvailableCapital}
            ></Body>
            {bankingEntitys.length !== 1 && (
              <div item className="formPhysicalPerson-addContainer" xl={12}>
                <div className="formPhysicalPerson-line"></div>
                <Remove
                  content="Eliminar Entidad Bancaria"
                  onClick={() => removeBank(index)}
                />
              </div>
            )}
          </>
        ))}

        <div className="formBankingEntity-addContainer">
          <div className="formBankingEntity-line"></div>
          <Add content="Añadir Entidad Bancaria" onClick={addBank} />
        </div>

        <div className="formPhysicalPerson-buttonContainer">
          <ButtonDS
            label={translate("continuar")}
            onClick={next}
            disabled={isButtonDisabled()}
          />
        </div>
      </div>
    </div>
  );
}
