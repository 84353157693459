/* eslint-disable no-template-curly-in-string */
import "./css/newUpsell.scss";
import "./css/animations.scss";
import UpgradeCapitalModal from "./Components/UpgradeCapitalModal/UpgradeCapitalModal";

import {
  ListSection,
  NavbarSticky,
  BannerPrice,
  UpsellIconSection,
  ResumeCart,
  RenderTrustpilot,
  UpsellCTASection,
  StaticImgText,
  Typography,
  Chip,
  ProgressiveButton,
  GlobalIcon,
  ButtonDS,
} from "get-life-storybook-ts";

import OverlayInfoIpaAB from "./Components/Overlays/OverlayInfoIpaAB.component";
import OverlayChangeCapital from "./Components/Overlays/OverlayChangeCapital.component";
import OverlayInfoCapital from "./Components/Overlays/OverlayInfoCapital.component";
import { numberFormatPayment, parsePriceDecimal } from "../../utils/Utils";

import { IconSection, stepList, Truspilot } from "../../utils/dataForNewUpsell";
import UpsellController from "./Upsell.controller";
import { SwitcherProps } from "get-life-storybook-ts/lib/molecules/Switcher/Switcher";
import { ChipI } from "get-life-storybook-ts/lib/atoms/Chip/Chip";
import MaxCapitalModal from "../../components/MaxCapitalModal/MaxCapitalModal";
import { TagFormat } from "get-life-storybook-ts/lib/molecules/RenderTags/RenderTags";
import ConditionsModalAB from "../../components/ConditionsModal/ConditionsModalAB";
import { PurchasePeriodT } from "../../api/request/Lead/Interfaces/DataInterface.response";

const Upsell = () => {
  const {
    addCapital,
    capital,
    capitalAdded,
    capitalAddedAmount,
    changeCapitalTooltip,
    changeDone,
    correctPrice,
    downloadDocument,
    datePickerProps,
    domain,
    handleCallMe,
    handleIpaChange,
    handleUpgradedClose,
    ipa,
    isMobile,
    listSectionData,
    priceChange,
    refIPA,
    renderPriceValidation,
    selectPlan,
    setChangeDone,
    translate,
    upGradedOpen,
    downloadableFiles,
    isABtesting,
    afterPromoText,
    beforePromoText,
    isStickyVisible,
    product,
    nodeConditionsModal,
    handleConditions,
    couponInfo,
  } = UpsellController();

  const prepareSwitchers = (): SwitcherProps[] => {
    let switchData = [];

    if (
      process.env.REACT_APP_DOMAIN_LOCALE !== "fr" &&
      product?.availableTraits.DISABILITY &&
      product?.ipaAvailable &&
      product.coverage !== "eg"
    ) {
      let switchIPA = {
        buttonsInfo: {
          showBoth: false,
          buttonInfo: {
            primary: { label: translate("coupons.overlay.button") },
            secondary: {},
          },
        },
        extraInfo1: `+${numberFormatPayment(product?.extraDisabilityCost)}`,
        extraInfo2: translate("newUpsell.resumeCart.priceMonth"),
        initialValue: ipa === "basic" ? false : true,
        onClick: () => handleIpaChange(ipa === "basic" ? "premium" : "basic"),
        overlay: <OverlayInfoIpaAB translate={translate} />,
        overlayTitle: (
          <Typography
            variant="H3"
            weight="700"
            text={translate("newUpsell.OverlayIpa.title")}
          />
        ),
        subtext: translate("newUpsell.resumeCart.IPAQuestion"),
        text: (
          <div className="flex gap-1">
            <Typography
              text={translate("newUpsell.resumeCart.addIPA1")}
              variant={"BodySmall"}
              weight="400"
              className="flex"
              color="dark-gray-100"
            />
            <Typography
              text={translate("newUpsell.resumeCart.addIPA2")}
              variant={"BodySmall"}
              weight="700"
              color="dark-gray-100"
            />
          </div>
        ),
        isAlwaysVisible: domain === "fr",
        textValidate: (
          <div className="flex gap-1">
            <Typography
              text={translate("newUpsell.resumeCart.IPAAdded1")}
              variant={
                domain === "es" || domain === "pt" || domain === "it"
                  ? "LinkSmall"
                  : "BodySmall"
              }
              weight={
                domain === "es" || domain === "pt" || domain === "it"
                  ? "700"
                  : "400"
              }
              className={`${
                domain === "es" ||
                domain === "pt" ||
                (domain === "it" && "cursor-pointer")
              }`}
              color="dark-gray-100"
              onClick={() =>
                domain === "es" ||
                domain === "pt" ||
                (domain === "it" && refIPA.current.handleShowModal())
              }
            ></Typography>
            <Typography
              text={translate("newUpsell.resumeCart.IPAAdded2")}
              variant={"BodySmall"}
              weight={
                domain === "es" || domain === "pt" || domain === "it"
                  ? "400"
                  : "700"
              }
              color="dark-gray-100"
            />
          </div>
        ),

        disabled: priceChange,
        ref: refIPA,
        extraInfoCharge: priceChange,
      };

      switchData.push(switchIPA);
    }

    if (product?.coverage === "eg") {
      switchData.push({
        extraInfo1: "",
        extraInfo2: "",
        overlayTitle: (
          <Typography
            variant="H3"
            weight="700"
            text={translate("upsell.policy.eg.modal.title")}
            color="dark-gray-100"
          />
        ),
        overlay: (
          <div>
            <Typography
              text={translate("upsell.policy.eg.modal.description")}
              variant="Body"
              weight="400"
              color="dark-gray-100"
            />
          </div>
        ),
        subtext: "",
        text: "",
        textValidate: (
          <span
            className="text-[#424242]"
            dangerouslySetInnerHTML={{
              __html: translate("upsell.policy.eg.switch.title"),
            }}
          />
        ),
        initialValue: true,
        isAlwaysVisible: true,
      });
    }

    if (product) {
      const capital = product.capital;
      const maxCapital = product.maxCapital;
      const extraCapitalPrice = product.extraCapitalPrice;
      if (
        extraCapitalPrice &&
        extraCapitalPrice >= 0 &&
        capital + 50000 <= maxCapital
      ) {
        let switchCapital = {
          buttonsInfo: {
            showBoth: false,
            buttonInfo: {
              primary: { label: translate("coupons.overlay.button") },
              secondary: {},
            },
          },
          extraInfo1: `+${numberFormatPayment(product?.extraCapitalPrice)}`,
          extraInfo2: translate("newUpsell.resumeCart.priceMonth"),
          initialValue: capitalAdded,
          onClick: () => addCapital(),
          overlay: <OverlayInfoCapital translate={translate} />,
          overlayTitle: (
            <Typography
              variant="H3"
              weight="700"
              text={translate("newUpsell.OverlayInfoCapital.title")}
            />
          ),
          subtext: translate("newUpsell.resumeCart.CapitalQuestion"),
          text: (
            <div className="flex gap-1">
              <Typography
                text={`+${numberFormatPayment(
                  product?.extraCapitalAmount,
                  "capital"
                )} €`}
                weight="700"
                className="flex"
                color="dark-gray-100"
                variant="BodySmall"
              />
              <Typography
                text={`${translate("newUpsell.resumeCart.addCapital")}`}
                weight="400"
                className="flex"
                color="dark-gray-100"
                variant="BodySmall"
              />
            </div>
          ),
          textValidate: (
            <div className="flex gap-1">
              <Typography
                text={`+${numberFormatPayment(
                  capitalAddedAmount,
                  "capital"
                )} €`}
                weight="700"
                color="dark-gray-100"
                variant="BodySmall"
              />
              <Typography
                text={`${translate("newUpsell.resumeCart.CapitalAdded")}`}
                weight="400"
                color="dark-gray-100"
                variant="BodySmall"
              />
            </div>
          ),
          disabled: priceChange,
          extraInfoCharge: priceChange,
        };

        switchData.push(switchCapital);
      }
    }

    return switchData;
  };

  const prepareExtraSwitchers = (): SwitcherProps[] => {
    let switchData = [];

    if (process.env.REACT_APP_DOMAIN_LOCALE === "it") {
      switchData.push(
        {
          extraInfo1: "",
          extraInfo2: "",
          overlayTitle: (
            <Typography
              variant="H3"
              weight="700"
              text={translate("newUpsell.resumeCart.decease.modalTitle")}
              color="dark-gray-100"
            />
          ),
          overlay: (
            <div>
              <GlobalIcon iconName="CandleIcon" size="L" />
              <Typography
                text={translate("newUpsell.resumeCart.decease.modalContent")}
                variant="Body"
                weight="400"
                className="mt-[16px]"
                color="dark-gray-100"
              />
            </div>
          ),
          subtext: "",
          text: "",
          textValidate: (
            <span
              dangerouslySetInnerHTML={{
                __html: translate("newUpsell.resumeCart.decease.title").replace(
                  "${price}",
                  numberFormatPayment(capital, "capital")
                ),
              }}
            />
          ),
          initialValue: true,
          isAlwaysVisible: true,
        },
        {
          extraInfo1: "",
          extraInfo2: "",
          overlayTitle: (
            <Typography
              variant="H3"
              weight="700"
              text={translate(
                "newUpsell.resumeCart.terminalIllness.modalTitle"
              )}
              color="dark-gray-100"
            />
          ),
          overlay: (
            <div>
              <GlobalIcon iconName="SaveMoneyIcon" size="L" />
              <Typography
                text={translate(
                  "newUpsell.resumeCart.terminalIllness.modalContent"
                )}
                variant="Body"
                weight="400"
                className="mt-[16px]"
                color="dark-gray-100"
              />
            </div>
          ),
          subtext: "",
          text: "",
          textValidate: (
            <span
              dangerouslySetInnerHTML={{
                __html: translate(
                  "newUpsell.resumeCart.terminalIllness.title"
                ).replace(
                  "${price}",
                  numberFormatPayment(capital / 2, "capital")
                ),
              }}
            />
          ),
          initialValue: true,
          isAlwaysVisible: true,
        },
        {
          extraInfo1: "",
          extraInfo2: "",
          overlayTitle: (
            <Typography
              variant="H3"
              weight="700"
              text={translate("newUpsell.resumeCart.protect.modalTitle")}
              color="dark-gray-100"
            />
          ),
          overlay: (
            <div className="payment-newUpsellContainer__benefits-modal">
              <div>
                <GlobalIcon
                  iconName="CalendarBrandIcon"
                  size="M"
                  color="var(--theme-primary)"
                  filled="var(--theme-fill)"
                />
                <Typography
                  text={translate("newUpsell.resumeCart.protect.modal.title1")}
                  variant="H3"
                  weight="700"
                  color="life-blue-100"
                />
                <Typography
                  text={translate(
                    "newUpsell.resumeCart.protect.modal.content1"
                  )}
                  variant="Body"
                  weight="400"
                  color="dark-gray-100"
                />
              </div>
              <div>
                <GlobalIcon
                  iconName="TransparencyIcon"
                  size="M"
                  color="var(--theme-primary)"
                  filled="var(--theme-fill)"
                />
                <Typography
                  text={translate("newUpsell.resumeCart.protect.modal.title2")}
                  variant="H3"
                  weight="700"
                  color="life-blue-100"
                />
                <Typography
                  text={translate(
                    "newUpsell.resumeCart.protect.modal.content2"
                  )}
                  variant="Body"
                  weight="400"
                  color="dark-gray-100"
                />
              </div>
              <div>
                <GlobalIcon
                  iconName="SaveMoneyIcon"
                  size="M"
                  color="var(--theme-primary)"
                  filled="var(--theme-fill)"
                />
                <Typography
                  text={translate("newUpsell.resumeCart.protect.modal.title3")}
                  variant="H3"
                  weight="700"
                  color="life-blue-100"
                />
                <Typography
                  text={translate(
                    "newUpsell.resumeCart.protect.modal.content3"
                  )}
                  variant="Body"
                  weight="400"
                  color="dark-gray-100"
                />
              </div>
            </div>
          ),
          subtext: "",
          text: "",
          textValidate: (
            <span
              dangerouslySetInnerHTML={{
                __html: translate("newUpsell.resumeCart.protect.title"),
              }}
            />
          ),
          subtextSwitched: translate("newUpsell.resumeCart.protect.subtitle"),
          initialValue: true,
          isAlwaysVisible: true,
        }
      );
    }

    return switchData;
  };

  const ChipsData: ChipI[] = [
    {
      text: `${translate(
        "newUpsell.resumeCart.tagCapital"
      )} ${numberFormatPayment(capital, "capital")} €`,
      type: "success" as "success",
      icon: "CheckedIcon",
    },
  ];

  if (domain === "it") {
    ChipsData.push(
      {
        text: translate("newUpsell.resumeCart.death"),
        type: "success" as "success",
        icon: "CheckedIcon",
      },
      {
        text: translate("newUpsell.resumeCart.terminalIllness"),
        type: "success" as "success",
        icon: "CheckedIcon",
      }
    );
  }

  if (ipa === "premium") {
    ChipsData.push({
      text: `${translate("newUpsell.resumeCart.tagIPAtext")} ${translate(
        "newUpsell.resumeCart.tagIPAsubtext"
      )}`,
      type: "success" as "success",
      icon: "CheckedIcon",
    });
  }

  if (ipa === "eg") {
    ChipsData.push(
      {
        text: translate("newUpsell.resumeCart.death"),
        type: "success" as "success",
        icon: "CheckedIcon",
      },
      {
        text: translate("newUpsell.resumeCart.chipEg"),
        type: "success" as "success",
        icon: "CheckedIcon",
      }
    );
  }

  const getDiscountedMonthlyPrice = (): any => {
    return product?.promotion
      ? product!.priceToPay
        ? numberFormatPayment(product!.priceToPay)
        : 0
      : numberFormatPayment(product!.regularPrice);
  };

  const getDiscountedMonthlyPriceWithCurrency = (): string => {
    return `${getDiscountedMonthlyPrice()} €`;
  };

  if (!product) return null;

  return (
    <>
      <div className="payment-newUpsellContainer">
        <div className="newUpsell--section1">
          <div className="newUpsell-banner--section">
            <BannerPrice
              title={translate("newUpsell.banner.title")}
              subtitle={translate("newUpsell.banner.subtitle")}
              homeImage="https://storage.googleapis.com/getlife-bucket-1/public/image/img-new-web/Illustration_woman.svg"
              background="white"
            />
          </div>
        </div>

        <div
          className="payment-newUpsellContainer__newsection"
          id="upsell_page_resume_cart"
        >
          <ResumeCart
            buttonTextCustomPolicy={translate(
              "aggregator.personalInformation.btnContract"
            )}
            onClickCP={() => selectPlan()}
            overlayButtonCP={
              <OverlayChangeCapital
                product={product!}
                translate={translate}
                initialValue={capital.toString()}
                textButtonLabel={translate(
                  "newUpsell.tooltipCapital.buttonLabel"
                )}
                onClick={changeCapitalTooltip}
                changeDone={changeDone}
              />
            }
            extraFunction={() => setChangeDone(false)}
            subtitlePB={translate("newUpsell.resumeCart.addCapitalTitle")}
            switchData={prepareSwitchers()}
            extraSwitchData={prepareExtraSwitchers()}
            extraSwitchTitle={translate("newUpsell.resumeCart.switchers.title")}
            textButton={translate("newUpsell.resumeCart.addCapitalButton")}
            titleCP={translate("newUpsell.resumeCart.titleCP")}
            titlePrice={
              isMobile ? "" : translate("newUpsell.resumeCart.titlePrice")
            }
            tags={ChipsData}
            tooltipIcon="BulbIcon"
            subtitle={isABtesting ? renderPriceValidation() : undefined}
            tooltipText={
              isABtesting ? (
                <span
                  dangerouslySetInnerHTML={{
                    __html: translate("newUpsell.resumeCart.tooltipValidDate"),
                  }}
                />
              ) : undefined
            }
            step={stepList(translate)}
            priceChange={priceChange}
            datePickerProps={datePickerProps()}
            overlayButtonLabelCP={""}
            overlayTitleCP={
              <Typography
                variant="H3"
                weight="700"
                className="text-[#424242]"
                text={translate("newUpsell.tooltipCapital.title")}
              />
            }
            downloadableFilesTitle={translate("downloadableFiles.title")}
            downloadableFiles={downloadableFiles}
            downloadableLoading={false}
            downloadDocument={downloadDocument}
            price={{
              price: parsePriceDecimal(
                parseFloat(getDiscountedMonthlyPrice().replace(",", ".")),
                undefined
              ),
              priceLabel: translate("calculatorPage.textMonthly"),
              decimals: parsePriceDecimal(
                parseFloat(getDiscountedMonthlyPrice().replace(",", ".")),
                "decimal"
              ),
              frequency: beforePromoText,
              after: afterPromoText,
              PromoOnClick: couponInfo ? handleConditions : undefined,
            }}
            trustpilot={translate("newUpsell.resumeCart.trustpilot")}
          />
        </div>

        <div className="payment-newUpsellContainer__newsection">
          <div className="containerSection">
            <ListSection
              title={translate("newUpsell.listSection.title")}
              list={listSectionData}
            />
          </div>
        </div>

        <div className="payment-newUpsellContainer__newsection">
          <UpsellIconSection
            title={translate("newUpsell.iconSection.title")}
            data={IconSection(translate) as any}
          />
        </div>

        <div className="payment-newUpsellContainer__newsection">
          <StaticImgText
            image={translate("newUpsell.staticImgText.img")}
            title={translate("newUpsell.staticImgText.title")}
            titleMobile={translate("newUpsell.staticImgText.titleMobile")}
            paragraphFirst1={translate(
              "newUpsell.staticImgText.paragraphFirst1"
            )}
            paragraphFirst2={translate(
              "newUpsell.staticImgText.paragraphFirst2"
            )}
            paragraphFirst3={translate(
              "newUpsell.staticImgText.paragraphFirst3"
            )}
            paragraphFirst4={translate(
              "newUpsell.staticImgText.paragraphFirst4"
            )}
            paragraphSecond1={translate(
              "newUpsell.staticImgText.paragraphSecond1"
            )}
            paragraphSecond2={translate(
              "newUpsell.staticImgText.paragraphSecond2"
            )}
            paragraphSecond3={translate(
              "newUpsell.staticImgText.paragraphSecond3"
            )}
            paragraphSecond4={translate(
              "newUpsell.staticImgText.paragraphSecond4"
            )}
            paragraphSecond5={translate(
              "newUpsell.staticImgText.paragraphSecond5"
            )}
          />
        </div>

        <div className="payment-newUpsellContainer__newsection">
          <RenderTrustpilot
            comments={Truspilot(translate)}
            excelentText={translate("newUpsell.truspilotSection.excelentText")}
            rating={4.8}
            title={translate("newUpsell.truspilotSection.title")}
            trustpilotText={translate(
              "newUpsell.truspilotSection.trustpilotText"
            )}
          />
        </div>
      </div>
      <div
        className="payment-newUpsellContainer__newsection-upsellCTA"
        id="upsell_page_upsell_cta"
      >
        <UpsellCTASection
          buttonTextCustomPolicy={translate(
            "aggregator.personalInformation.btnContract"
          )}
          onClick={() => handleCallMe()}
          onClickCP={() => selectPlan()}
          subtitlePB={isABtesting ? renderPriceValidation() : undefined}
          paragraph1CTA={translate("newUpsell.ctaSection.paragraph1CTA")}
          paragraph2CTA={translate("newUpsell.ctaSection.paragraph2CTA")}
          phone={process.env.REACT_APP_TEL!}
          tags={ChipsData as unknown as TagFormat[]}
          textButton={translate("newUpsell.ctaSection.textButton")}
          title2CTA={translate("newUpsell.ctaSection.title2CTA")}
          titleCTA={translate("newUpsell.ctaSection.titleCTA")}
          titlePrice={
            isMobile
              ? translate("newUpsell.ctaSection.titlePrice")
              : translate("newUpsell.resumeCart.titlePrice")
          }
          showCustomText={true}
          customText={translate("newUpsell.resumeCart.titleCP")}
          onClickCustomText={() =>
            window.scrollTo({ top: 500, left: 0, behavior: "smooth" })
          }
          tooltipIcon="BulbIcon"
          tooltipText={
            isABtesting ? (
              <span
                className={"payment-newUpsellContainer__newsection--tooltip"}
                dangerouslySetInnerHTML={{
                  __html: translate("newUpsell.resumeCart.tooltipValidDate"),
                }}
              />
            ) : undefined
          }
          price={{
            price: parsePriceDecimal(
              parseFloat(getDiscountedMonthlyPrice().replace(",", ".")),
              undefined
            ),
            priceLabel: translate("calculatorPage.textMonthly"),
            decimals: parsePriceDecimal(
              parseFloat(getDiscountedMonthlyPrice().replace(",", ".")),
              "decimal"
            ),
            frequency: beforePromoText,
            after: afterPromoText,
            PromoOnClick: couponInfo ? handleConditions : undefined,
          }}
          trustpilot={translate("newUpsell.CTASection.trustpilot")}
        />
      </div>
      {isMobile && isStickyVisible && (
        <NavbarSticky
          position="bottom"
          content={
            <div className="payment-newUpsellContainer__buyCard--content">
              <div className="flex flex-col">
                {!!product?.promotion &&
                  product?.regularPrice !== product?.priceToPay && (
                    <Typography
                      variant="SpanMedium"
                      weight="400"
                      text={`${numberFormatPayment(
                        correctPrice("priceToPay") ?? 0
                      )}€`}
                      className="payment-newUpsellContainer__buyCard--discounted"
                    />
                  )}
                <div className="payment-newUpsellContainer__buyCard--price">
                  <Typography
                    variant="H2"
                    text={getDiscountedMonthlyPriceWithCurrency()}
                  />
                  <Typography
                    variant="Span"
                    weight="400"
                    text={`/${translate("upsell.BuyCard.month")}`}
                  />
                </div>
              </div>
              <div className="payment-newUpsellContainer__chips">
                {ChipsData.map((d, idx) => {
                  const { text, type } = d;
                  return (
                    <Chip
                      text={text}
                      type={type}
                      icon={"CheckedIcon"}
                      key={`${text}_${idx}`}
                    />
                  );
                })}
              </div>
            </div>
          }
          actions={
            isABtesting ? (
              <div className="payment-newUpsellContainer__buyCard--actions">
                <ProgressiveButton
                  onClick={() => selectPlan()}
                  text={"Protégete"}
                />
              </div>
            ) : (
              <ButtonDS
                label={translate("aggregator.personalInformation.btnContract")}
                onClick={() => selectPlan()}
                className="w-full"
              />
            )
          }
        />
      )}

      <UpgradeCapitalModal
        upGradedOpen={upGradedOpen}
        handleUpgradedClose={handleUpgradedClose}
        capitalUpgrade={product?.capital}
      />

      <MaxCapitalModal capital={product!.capital} />

      {product?.promotion && (
        <ConditionsModalAB
          couponInfo={couponInfo}
          nodeFakeDoorModal={nodeConditionsModal}
          promotion={product.promotion as any}
          collection={product.feeCollection as any}
          period={
            (product.period?.toLowerCase() || "monthly") as PurchasePeriodT
          }
          handleSubmit={() => nodeConditionsModal.current?.handleShowPortal()}
        />
      )}
    </>
  );
};

export default Upsell;
