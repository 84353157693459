import { ButtonDS, GlobalIcon, Input, Typography } from "get-life-storybook-ts";
import React, { useState } from "react";
import { validationsGlobal } from "../../../../utils/validations";
import { NewProductI } from "../../../../api/request/Lead/Interfaces/ProductInterface.response";

interface ErrorI {
  error: boolean;
  message: string;
}

interface OverlayCapitalI {
  changeDone: boolean;
  initialError?: ErrorI;
  initialValue?: string;
  onClick: (price: string) => void;
  product: NewProductI;
  textButtonLabel: string;
  translate: (key: string) => string;
}

const OverlayCapital: React.FC<OverlayCapitalI> = ({
  changeDone,
  initialError,
  initialValue,
  onClick,
  product,
  textButtonLabel,
  translate,
}) => {
  const [value, setValue] = useState<string>(initialValue || "");
  const [errorActive, setErrorActive] = useState(
    initialError || {
      error: false,
      message: "",
    }
  );

  const validatePrice = (price: string) => {
    const error = validationsGlobal({
      value: price,
      translate,
      min: product.minCapital,
      max: product.maxCapital,
      field: "capital",
    });
    setErrorActive(error);
  };

  const handleClick = () => {
    onClick(value);
  };

  return !changeDone ? (
    <div className="payment-newUpsellContainer__section__card__overlay">
      <Typography
        variant="BodyMedium"
        text={translate("newUpsell.tooltipCapital.text1")}
      >
        <Typography
          variant="SpanMedium"
          weight="700"
          text={translate("newUpsell.tooltipCapital.text2")}
        />
        <Typography
          variant="SpanMedium"
          text={translate("newUpsell.tooltipCapital.text3")}
        />
      </Typography>
      <div className="inputCapital">
        <Input
          onValueChange={setValue}
          onBlur={() => validatePrice(value)}
          initialValue={value}
          placeholder={translate("newUpsell.tooltipCapital.placeHolder")}
          initialError={errorActive}
          iconName="CurrencyEuroIcon"
        />
        <ButtonDS
          onClick={handleClick}
          label={textButtonLabel}
          disabled={errorActive.error || value.length < 4}
        />
      </div>
    </div>
  ) : (
    <div className="modalChangeDone">
      <span className="checkedIcon">
        <GlobalIcon
          iconName="CheckedIcon"
          size="XS"
          color="var(--theme-primary)"
        />
      </span>
      <Typography
        variant="Body"
        weight="400"
        text={translate("newUpsell.tooltipCapital.changeDone")}
      />
    </div>
  );
};

export default OverlayCapital;
