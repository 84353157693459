export interface ApiLeadTrackingGetResponse {
    leadId: string;
    leadUlid: string;
    awarenessLanding: string;
    awarenessSource: string;
    awarenessMedium: string;
    awarenessChannel: string;
    awarenessKeyword: string;
    awarenessCampaign: string;
    awarenessContent: string;
    goalLanding: string;
    goalSource: string;
    goalMedium: string;
    goalChannel: string;
    goalKeyword: string;
    goalCampaign: string;
    goalContent: string;
    oldCampaign: string;
    googleAnalyticsId: string;
    fullstoryId: string;
    vwoId: string;
    utmSource: string;
    utmMedium: string;
    utmCampaign: string;
    newCampaign: string;
    atGd: string;
    tid: string;
    utmContent: string;
    utmTerm: string;
    utmTest: string;
    fbclid: string;
    gclid: string;
    msclkid: string;
    utmEntryPage: string;
    utmReferrerUrl: string;
    aBTestVersion: string;
}

const apiLeadTrackingGet = async (
  leadId: string
): Promise<ApiLeadTrackingGetResponse[]> => {
  const response = fetch(
    `${process.env.REACT_APP_CUSTOMER_APP_URL}api/tracking/get/${leadId}`
  ).then((response) => response.json());

  return response;
};

export const getTrackingUtms = async (leadId: string) => {
  const tracking: ApiLeadTrackingGetResponse[] = await apiLeadTrackingGet(
    leadId
  );

  const index = tracking ? tracking.length - 1 : 0;

  const utms = {
    utm_source: tracking ? tracking[index].utmSource : null,
    utm_medium: tracking ?  tracking[index].utmMedium : null,
    utm_campaign: tracking ?  tracking[index].utmCampaign : null,
    utm_content: tracking ?  tracking[index].utmContent : null,
    utm_term: tracking ?  tracking[index].utmTerm : null,
    utm_test: tracking ?  tracking[index].utmTest : null,
    fbclid: tracking ?  tracking[index].fbclid : null,
    gclid: tracking ?  tracking[index].gclid : null,
    msclkid: tracking ?  tracking[index].msclkid : null,
    entryPage: tracking ?  tracking[index].utmEntryPage : null,
    referrerUrl: tracking ?  tracking[index].utmReferrerUrl : null,
    ABTestVersion: tracking ?  tracking[index].aBTestVersion : null
  };
  return utms;
};

export default apiLeadTrackingGet;
