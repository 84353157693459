import * as React from "react";
import CacheFactory from "../api/core/cache/cache.factory";

const useCache = (cacheName: string) => {
  const cacheStorage = CacheFactory.fromLocalStorage();
  const [storedValue, setStoreValue] = React.useState(() => {
    if (cacheStorage.has(cacheName)) {
      return cacheStorage.get(cacheName);
    }
  });

  const setValue = (value: unknown) => {
    cacheStorage.put(cacheName, value);
    setStoreValue(value);
  };

  return [storedValue, setValue];
};

export default useCache;
