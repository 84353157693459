import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router";
import Add from "../../../../components/Components/Add/Add";
import Remove from "../../../../components/Components/Remove/Remove";
import "./../beneficiaries/formPhysicalPerson.scss";
import HttpLeadRepository from "../../../../api/request/Lead/Lead.service";
import {
  ButtonDS,
  DateInput,
  NumberInput,
  TextInput,
} from "get-life-storybook-ts";
import { I18nContext } from "../../../../context/i18n.context";
import {
  submitValidationError,
  validationsGlobal,
} from "../../../../utils/validations";
import { DOMAIN } from "../../../../constants/Global";

const LOCALE = process.env.REACT_APP_DOMAIN_LOCALE;

const REGEX_INTERNATIONAL =
  /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;

const validationsDefault = [
  {
    name: "name",
    type: "string",
    errorText: "Caracteres no permitidos",
    required: true,
    requiredText: "Este campo es obligatorio",
    regExp: REGEX_INTERNATIONAL,
  },
  {
    name: "lastName",
    type: "string",
    errorText: "Caracteres no permitidos",
    required: true,
    requiredText: "Este campo es obligatorio",
    regExp: REGEX_INTERNATIONAL,
  },
  {
    name: "lastName2",
    type: "string",
    errorText: "Caracteres no permitidos",
    required: false,
    regExp: REGEX_INTERNATIONAL,
  },
  {
    name: "idNumber",
    type: "string",
    errorText: "Introduce un NIF/NIE válido",
    required: true,
    requiredText: "Este campo es obligatorio",
    regExp: "idNumberFunction",
  },
  {
    name: "capital",
    errorText: "Introduzca una cifra válida",
    required: true,
    requiredText: "Este campo es obligatorio",
    regExp: /^([0-9])*$/,
  },
];

const validationsFR = [
  {
    name: "name",
    type: "string",
    errorText: "beneficiaries.error.wrongChars",
    required: true,
    requiredText: "beneficiaries.error.required",
    regExp: REGEX_INTERNATIONAL,
  },
  {
    name: "lastName",
    type: "string",
    errorText: "beneficiaries.error.wrongChars",
    required: true,
    requiredText: "beneficiaries.error.required",
    regExp: REGEX_INTERNATIONAL,
  },
  {
    name: "lastName2",
    type: "string",
    errorText: "beneficiaries.error.wrongChars",
    required: false,
    requiredText: "beneficiaries.error.required",
    regExp: REGEX_INTERNATIONAL,
  },
  {
    name: "maidenName",
    type: "string",
    errorText: "beneficiaries.error.wrongChars",
    required: false,
    requiredText: "beneficiaries.error.required",
    regExp: REGEX_INTERNATIONAL,
  },
  {
    name: "birthdate",
    type: "string",
    required: true,
    requiredText: "beneficiaries.error.required",
  },
  {
    name: "birthCity",
    type: "string",
    errorText: "beneficiaries.error.wrongChars",
    required: true,
    requiredText: "beneficiaries.error.required",
    regExp: REGEX_INTERNATIONAL,
  },
  {
    name: "idNumber",
    errorText: "",
    required: false,
    requiredText: ""
  },
  {
    name: "capital",
    errorText: "beneficiaries.error.wrongNumber",
    required: true,
    requiredText: "beneficiaries.error.required",
    regExp: /^([0-9])*$/,
  },
];

const validations =
  {
    fr: validationsFR,
  }[DOMAIN] || validationsDefault;

export function Body(props) {
  const {
    index,
    physicalPersons,
    person,
    error,
    errorMessage,
    translate,
    handlePhysicalPersons,
    availableCapital,
  } = props;

  const [name, setName] = useState(person.name);
  const [lastName, setLastName] = useState(person.lastName);
  const [lastName2, setLastName2] = useState(person.lastName2);
  const [maidenName, setMaidenName] = useState(person.maidenName);
  const [birthdate, setBirthdate] = useState(person.birthddate);
  const [birthCity, setBirthCity] = useState(person.birthCity);
  const [idNumber, setIdNumber] = useState(person.idNumber);
  const [capital, setCapital] = useState(person.capital);

  useEffect(() => {
    setName(person.name);
    setLastName(person.lastName);
    setLastName2(person.lastName2);
    setMaidenName(person.maidenName);
    setBirthdate(person.birthdate);
    setBirthCity(person.birthCity);
    setIdNumber(person.idNumber);
    setCapital(person.capital);
  }, [person]);

  useEffect(() => {
    if (typeof physicalPersons[index] !== "undefined") {
      if (LOCALE === "fr") {
        physicalPersons[index].maidenName = maidenName;
        physicalPersons[index].birthdate = birthdate;
        physicalPersons[index].birthCity = birthCity;
      }
      physicalPersons[index].name = name;
      physicalPersons[index].lastName = lastName;
      physicalPersons[index].lastName2 = lastName2;
      physicalPersons[index].idNumber = idNumber;
      physicalPersons[index].capital = capital;
      handlePhysicalPersons([...physicalPersons]);
    }
  }, [
    name,
    lastName,
    lastName2,
    idNumber,
    capital,
    maidenName,
    birthdate,
    birthCity,
  ]);

  const PhysicalPersonFR = () => {
    return (
      <>
        <div className="grid-beneficiary">
          <div className="row">
            <div>
              <TextInput
                initialValue={name}
                label={translate("physicalperson.personalData.name")}
                onValueChange={setName}
                placeholder={translate("payment.personalData.namePlaceHolder")}
                validationChecks={[
                  (value) =>
                    validationsGlobal({
                      value,
                      translate,
                      min: 1,
                      disabledData: {},
                      setDisabled: () => {},
                    }),
                ]}
              />
              {error && error[`name${index}`] && (
                <p>{translate(errorMessage[`name${index}`])}</p>
              )}
            </div>
            <div>
              <TextInput
                initialValue={lastName2}
                label={translate("physicalperson.personalData.lastName2")}
                onValueChange={setLastName2}
                placeholder={translate(
                  "payment.personalData.preNomplaceHolder"
                )}
              />
              {error && error[`lastName2${index}`] && (
                <p>{translate(errorMessage[`lastName2${index}`])}</p>
              )}
            </div>
          </div>
          <div className="row">
            <div>
              <TextInput
                initialValue={lastName}
                label={translate("physicalperson.personalData.lastName")}
                onValueChange={setLastName}
                placeholder={translate(
                  "payment.personalData.surnamePlaceHolder"
                )}
                validationChecks={[
                  (value) =>
                    validationsGlobal({
                      value,
                      translate,
                      min: 1,
                      disabledData: {},
                      setDisabled: () => {},
                    }),
                ]}
              />
              {error && error[`lastName${index}`] && (
                <p>{translate(errorMessage[`lastName${index}`])}</p>
              )}
            </div>
            <div>
              <TextInput
                initialValue={maidenName}
                label={translate("physicalperson.personalData.maidenName")}
                onValueChange={setMaidenName}
                placeholder={translate(
                  "physicalperson.personalData.maidenName.placeHolder"
                )}
              />
              {error && error[`maidenName${index}`] && (
                <p>{translate(errorMessage[`maidenName${index}`])}</p>
              )}
            </div>
          </div>
          <div className="row">
            <div>
              <DateInput
                label={translate("physicalperson.personalData.birthdate")}
                onValueChange={setBirthdate}
                placeholder={translate("calculatorPage.placeholder.birthDate")}
                overlayButtonLabel={translate(
                  "productQuestions.overlay.button"
                )}
                textDay={translate("calculatorPage.textDay")}
                textMonth={translate("calculatorPage.textMonth")}
                textYear={translate("calculatorPage.textYear")}
              />
            </div>
            <div>
              <TextInput
                initialValue={birthCity}
                label={translate("physicalperson.personalData.birthCity")}
                onValueChange={setBirthCity}
                placeholder={translate(
                  "physicalperson.personalData.birthCityPlaceholder"
                )}
                validationChecks={[
                  (value) =>
                    validationsGlobal({
                      value,
                      translate,
                      min: 1,
                      disabledData: {},
                      setDisabled: () => {},
                    }),
                ]}
              />
              {error && error[`birthCity${index}`] && (
                <p>{translate(errorMessage[`birthCity${index}`])}</p>
              )}
            </div>
          </div>
          <div className="row">
            <div>
              <NumberInput
                initialValue={capital}
                maxLength={availableCapital}
                label={translate("physicalperson.personalData.capital")}
                onValueChange={setCapital}
                placeholder={translate(
                  "physicalperson.personalData.capitalPlaceholder"
                )}
                validationChecks={[
                  (value) =>
                    validationsGlobal({
                      value,
                      translate,
                      min: 1,
                      disabledData: {},
                      setDisabled: () => {},
                    }),
                ]}
              />
              {error && error[`capital${index}`] && (
                <p>{translate(errorMessage[`capital${index}`])}</p>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };

  const PhysicalPersonES = () => {
    return (
      <>
        <div className="grid-beneficiary">
          <div className="row">
            <div>
              <TextInput
                initialValue={name}
                label={translate("physicalperson.personalData.name")}
                onValueChange={setName}
                placeholder={translate("payment.personalData.namePlaceHolder")}
                validationChecks={[
                  (value) =>
                    validationsGlobal({
                      value,
                      translate,
                      min: 3,
                      disabledData: {},
                      field: "name",
                      setDisabled: () => {},
                    }),
                ]}
              />
              {error && error[`name${index}`] && (
                <p>{errorMessage[`name${index}`]}</p>
              )}
            </div>
          </div>
          <div className="row">
            <div>
              <TextInput
                initialValue={lastName}
                label={translate("physicalperson.personalData.lastName")}
                onValueChange={setLastName}
                placeholder={translate(
                  "payment.personalData.surnamePlaceHolder"
                )}
                validationChecks={[
                  (value) =>
                    validationsGlobal({
                      value,
                      translate,
                      min: 3,
                      disabledData: {},
                      field: "lastName",
                      setDisabled: () => {},
                    }),
                ]}
              />
              {error && error[`lastName${index}`] && (
                <p>{errorMessage[`lastName${index}`]}</p>
              )}
            </div>
            <div>
              <TextInput
                initialValue={lastName2}
                label={translate("physicalperson.personalData.lastName2")}
                onValueChange={setLastName2}
                placeholder="Ruiz"
              />
              {error && error[`lastName2${index}`] && (
                <p>{errorMessage[`lastName2${index}`]}</p>
              )}
            </div>
          </div>
          <div className="row">
            <div>
              <TextInput
                initialValue={idNumber}
                label={translate("physicalperson.personalData.idNumber")}
                onValueChange={setIdNumber}
                placeholder="00000000W"
              />
              {error && error[`idNumber${index}`] && (
                <p>{errorMessage[`idNumber${index}`]}</p>
              )}
            </div>
            <div className="capital">
              <NumberInput
                initialValue={capital}
                label={translate("physicalperson.personalData.capital")}
                onValueChange={setCapital}
                placeholder={translate(
                  "physicalperson.personalData.capitalPlaceholder"
                )}
              />
              {error && error[`capital${index}`] && (
                <p>{errorMessage[`capital${index}`]}</p>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };

  return LOCALE === "fr"
    ? PhysicalPersonFR()
    : PhysicalPersonES();
}

export default function FormPhysicalPerson(props) {
  const navigate = useNavigate();
  const { token, leadId } = props;
  const [availableCapital, setAvailableCapital] = useState(null);
  const [physicalPersons, setPhysicalPersons] = useState([]);

  const leadRepository = new HttpLeadRepository(leadId, token);

  const {
    state: { translate },
  } = useContext(I18nContext);

  const person =
    LOCALE === "es" || LOCALE === "pt" || LOCALE === "it"
      ? {
          name: "",
          lastName: "",
          lastName2: "",
          idNumber: "",
          capital: 0,
        }
      : {
          name: "",
          lastName: "",
          lastName2: "",
          capital: 0,
          maidenName: "",
          birthdate: "",
          birthCity: "",
        };

  const [error, setError] = useState();
  const [errorMessage, setErrorMessage] = useState();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const fetchData = async () => {
      await leadRepository.getBeneficiaries().then((res) => {
        if (res.code === 401) {
          navigate("/login");
        } else {
          const filtered = res.beneficiaries.filter(function (obj) {
            if (obj.type === "physicalPerson") {
              delete obj.id;
              delete obj.bankName;
              delete obj.businessName;
              delete obj.fiscalNumber;
              delete obj.loanMortgageNumber;
            }
            return obj.type === "physicalPerson";
          });
          filtered.length === 0 && filtered.push(person);
          setPhysicalPersons(filtered);
          let jetUsedCapital = 0;
          filtered.map(
            (physicalPerson, index) =>
              (jetUsedCapital += physicalPerson.capital)
          );
          setAvailableCapital(
            parseInt(res.availableCapital) + parseInt(jetUsedCapital)
          );
        }
      });
    };

    fetchData();
  }, []);

  useEffect(() => {
    props.handleValues(physicalPersons);
  }, [physicalPersons]);

  const addPerson = () => {
    const newPhysicalPersons = [...physicalPersons];
    newPhysicalPersons.push(person);
    setPhysicalPersons(newPhysicalPersons);
  };

  const removePerson = (index) => {
    physicalPersons.splice(index, 1);
    setPhysicalPersons([...physicalPersons]);
  };

  const isButtonDisabled = () => {
    const inputFields = document.querySelectorAll("input");
    const validInputs = Array.from(inputFields).filter(
      (input) =>
        input.value === "" &&
        (input.name === "name" ||
          input.name === "lastName" ||
          (DOMAIN !== "fr" && input.name === "idNumber") ||
          input.name === "capital")
    );
    if (validInputs.length === 0) {
      return false;
    } else {
      return true;
    }
  };

  const next = () => {
    if (
      !submitValidationError({
        beneficiaries: physicalPersons,
        availableCapital,
        validations,
        translate,
        setError,
        setErrorMessage,
      })
    ) {
      if (DOMAIN === "fr") {
        physicalPersons.forEach((personData, index) => {
          personData.name = personData.lastName2
            ? `${personData.name}, ${personData.lastName2}`
            : personData.name;
          personData.lastName2 = "";
        });
        setPhysicalPersons([...physicalPersons]);
      }
      props.nextQuestion();
    }
  };

  return (
    physicalPersons && (
      <div className="formPhysicalPerson-container">
        <div className="formPhysicalPerson-GridContainer">
          {physicalPersons.map((person, index) => (
            <>
              <Body
                key={index}
                actualUrl={props.actualUrl}
                index={index}
                person={person}
                physicalPersons={physicalPersons}
                handlePhysicalPersons={setPhysicalPersons}
                error={error}
                setError={setError}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
                availableCapital={availableCapital}
                setAvailableCapital={setAvailableCapital}
                translate={translate}
              />
              {physicalPersons.length !== 1 && (
                <div className="formPhysicalPerson-addContainer">
                  <div className="formPhysicalPerson-line"></div>
                  <Remove
                    content={translate("physicalperson.personalData.remove")}
                    onClick={() => removePerson(index)}
                  />
                </div>
              )}
            </>
          ))}

          <div className="formPhysicalPerson-addContainer">
            <div className="formPhysicalPerson-line"></div>

            <Add
              content={translate("physicalperson.personalData.add")}
              onClick={addPerson}
            />
          </div>
          <div className="formPhysicalPerson-buttonContainer">
            <ButtonDS
              label={translate("continuar")}
              onClick={next}
              disabled={isButtonDisabled()}
            />
          </div>
        </div>
      </div>
    )
  );
}
