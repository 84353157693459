import { useContext, useEffect } from 'react';
import { GetlifeContext, useQuery } from '../../context/GetLifeContext.context';
import LoadingSpinner from '../Calculator/LoadingSpinner/LoadingSpinner';
import HttpLeadRepository from '../../api/request/Lead/Lead.service';
import { getTrackingUtms } from '../../api/request/apiLeadTrackingGet';
import { GetLifeTrackerContext } from '../../context/GetLifeTracker.context';
import { useNavigate } from 'react-router';

export interface infoLeadI {
    leadData: {
        intention: string,
        coverage: string,
        lead: {
            birthdate: string,
            phone: string,
            email: string,
            capital: string | number,
            gender: string,
            zipcode: number
        },
        price: number
    },
    leadTracking: {
        ABTestVersion: string | null,
        entryPage: string | null,
        fbclid: string | null,
        gclid: string | null,
        msclkid: string | null,
        referrerUrl: string | null,
        utm_campaign: string | null,
        utm_content: string | null,
        utm_medium: string | null,
        utm_source: string | null,
        utm_term: string | null,
        utm_test: string | null,
    }
}

const WelcomeLead = () => {

    let query = useQuery();
    const lead = query.get("lead");
    const navigate = useNavigate();

    const {
        token,
        setLeadId,
        setToken,
    } = useContext(GetlifeContext);

    const { handleTrackerQuestion, identifyFS } = useContext(
        GetLifeTrackerContext
    );

    const leadRepository = new HttpLeadRepository(lead as string, token);

    const parseTracker = async (data: infoLeadI) => {
        const url = document.baseURI;

        const dataInfo = {
            page: url,
            leadId: lead,
            intent: undefined,
            phone: data.leadData.lead.phone,
            email: data.leadData.lead.email,
            calculatedCapital: parseFloat(data.leadData.lead.capital as string),
            calculatedPremium: data.leadData.price,
            coverage: data.leadData.coverage === "premium" ? "FIPA" : "F",
            gender: data.leadData.lead.gender,
            postalCode: data.leadData.lead.zipcode,
            utm_campaign: data.leadTracking.utm_campaign,
            utm_source: data.leadTracking.utm_source,
            utm_medium: data.leadTracking.utm_medium,
            utm_content: data.leadTracking.utm_content,
            utm_term: data.leadTracking.utm_term,
            utm_test: data.leadTracking.utm_test,
            gclid: data.leadTracking.gclid,
            msclkid: data.leadTracking.msclkid,
            fbclid: data.leadTracking.fbclid,
            platform: "App",
            entryPage: data.leadTracking.entryPage,
            referrerUrl: data.leadTracking.referrerUrl
        };

        let event = "leadCreated";
        handleTrackerQuestion({
            type: "FS",
            event,
            data: {
                birthDate: new Date(data.leadData.lead.birthdate),
                ...dataInfo
            }
        });

        handleTrackerQuestion({
            type: "GA",
            data: {
                event,
                birthDate: data.leadData.lead.birthdate,
                ...dataInfo
            }
        });

        event = "calculatorQuoteGenerated";

        handleTrackerQuestion({
            type: "FS",
            event,
            data: {
                birthDate: new Date(data.leadData.lead.birthdate),
                ...dataInfo
            }
        });
        handleTrackerQuestion({
            type: "GA",
            data: {
                event,
                birthDate: data.leadData.lead.birthdate,
                ...dataInfo
            }
        });

        navigate("/productQuestions");
    }

    const fetchNewData = async () => {
        try {
            const leadData = await leadRepository.getCheckoutData();
            const leadTracking = await getTrackingUtms(lead || "")

            return {
                leadData,
                leadTracking
            }
        } catch (error) {
            throw new Error("Failed to get lead info")
        }
    };

    useEffect(() => {
        if (!token) {
            leadRepository.getToken(lead!).then((res: any) => {
                const { token, brokerId, email } = res;
                setLeadId(lead!)
                setToken(token);
                identifyFS({
                    lead: lead!,
                    data: {
                        emailDomain: email?.split("@")[1],
                        email: email,
                        brokerId: brokerId,
                    },
                });
            });
        }
    }, [token])

    useEffect(() => {
        (async () => {
            if (token) {
                const infoLead = await fetchNewData();
                await parseTracker(infoLead)
            }
        })();
    }, [token]);

    return <>
        <LoadingSpinner />
    </>
}

export default WelcomeLead