export default function apiGetInsuranceSavings(leadId, token) {
    const bearer = "Bearer " + token;
    return fetch(
      process.env.REACT_APP_CUSTOMER_APP_URL + `api/insurance/savings/${leadId}`,
      {
        headers: {
          Authorization: bearer,
        },
      }
    ).then((response) => response.json());
  }
  