import * as React from "react";
import { ProgressionListI } from "../DecisionPageSQL.controller";

interface ProgressionI {
  list: Array<ProgressionListI>;
  title: string;
  type?: string;
}

const ProgressionSQL: React.FC<ProgressionI> = ({ list, title, type }) => {
  return (
    <div
      className="w-[325px] rounded-[8px]"
      style={{
        boxShadow:
          "0px -1px 0px 0px rgba(66, 66, 66, 0.10) inset, 0px 1px 3px 0px rgba(66, 66, 66, 0.10)",
      }}
    >
      <div className="flex items-center w-full bg-[var(--theme-tertiary)] px-5 py-[8px] border border-[#A09FFA] rounded-t-[8px] ">
        <span className="BodyS font-bold text-purple">{title}</span>
      </div>
      <div className="flex flex-col w-full p-6 gap-4 border border-[#A09FFA] border-t-0 rounded-b-[8px]">
        <ul className={`list-elements-progress ${type}`}>
          {list.map((item, index) => (
            <li
              key={index}
              className={`progression__list__container ${item.active}`}
            >
              <span className={`numberlist ${item.active}`}>{index + 1}</span>
              <div className={`progression__list__item ${item.active}`}>
                <div
                  className={
                    item.active
                      ? "BodyL font-bold"
                      : "BodyL font-light text-[#424242]"
                  }
                >
                  {item.title}
                </div>
                <p className="BodyM font-normal text-[#7B7B7B]">
                  {item.description}
                </p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ProgressionSQL;
