import "./BannerEnvironment.scss";
import React, { useContext } from "react";
import { GetlifeContext } from "../../context/GetLifeContext.context";
import { useNavigate } from "react-router";

interface BannerEnvironmentI {
  environment: string | undefined;
  locale: string | undefined;
}

const BannerEnvironment: React.FC<BannerEnvironmentI> = ({
  environment,
  locale,
}) => {
  const { init } = useContext(GetlifeContext);

  const navigate = useNavigate();

  const resetLead = () => {
    init("resert_lead_banner");
    navigate("/login");
  };

  return (
    <>
      <div className={`BannerEnvironment ${environment}`}>
        <p>
          YOU ARE IN {environment} .{locale!.toUpperCase()} ENVIRONMENT
        </p>
        <p className="resetLead" onClick={() => resetLead()}>
          {" "}
          RESET LEAD
        </p>
      </div>
    </>
  );
};

export default BannerEnvironment;
