import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import HttpCheckoutRepository from "../../../../../../api/request/Checkout/Checkout.service";
import HttpLeadRepository from "../../../../../../api/request/Lead/Lead.service";
import { GetlifeContext } from "../../../../../../context/GetLifeContext.context";
import { I18nContext } from "../../../../../../context/i18n.context";
import * as PDFS_ROUTES from "../../../../../../assets/pdfs";
import { GetLifeTrackerContext } from "../../../../../../context/GetLifeTracker.context";
import { StatusContext } from "../../../../../../context/StatusContext";
import { DOMAIN } from "../../../../../../constants/Global";
import { numberFormatPayment } from "../../../../../../utils/Utils";
import { NewCheckoutI } from "../../../../../../api/request/Lead/Interfaces/DataInterface.response";

type userAcceptanceT = "privacyPolicies" | "conditions" | "personalData";

const CheckoutPayController = () => {
  const {
    setLoading,
    utm_campaign,
    utm_source,
    utm_medium,
    utm_content,
    utm_term,
    utm_test,
    gclid,
    msclkid,
    fbclid,
    getEntryPage,
    getReferrerUrl,
    getABTestVersion,
    token,
    leadId
  } = useContext(GetlifeContext);

  const {
    state: { translate },
  } = useContext(I18nContext);
  const { getStatus } = useContext(StatusContext);

  const { handleTrackerQuestion } = useContext(GetLifeTrackerContext);

  const [note, setNote] = useState<string>();
  const [noteUrl, setNoteURL] = useState<string>();
  const [activeButton, setActiveButton] = useState(false);

  const navigate = useNavigate();

  const leadRepository = new HttpLeadRepository(leadId, token);
  const checkoutRepository = new HttpCheckoutRepository(leadId, token);

  const navigateTo = () => navigate("/payment/5");

  const [modalShow, setModalShow] = useState(false);

  const [confirmed, setConfirmed] = useState(false);
  const handleChangeConfirmed = () => {
    setConfirmed(!confirmed);
  };
  const userHasConditionsToAccept = DOMAIN === "it";
  const [userAcceptance, setUserAcceptance] = useState<{
    [key in userAcceptanceT]: boolean;
  }>({
    privacyPolicies: false,
    conditions: false,
    personalData: false,
  });

  const [checkout, setCheckout] = useState<NewCheckoutI>();

  const acceptLegalNote = async () => {
    const responseData = await checkoutRepository.acceptLegalNote().then(async () => {
      sendTrack();
      await isReadyToPaymentValidation()
    }).catch((e) => {
      setLoading(false);
      navigate("/waitingvalidation");
    });

    return responseData
  }

  const isReadyToPaymentValidation = async () => {
    await checkoutRepository.isReadyToPaymentValidation().then(async () => {
      await ensureFundsFromCustomer()
    }).catch((e) => {

      setLoading(false);
      navigate("/waitingvalidation");

    });
  }

  const ensureFundsFromCustomer = async () => {
    await checkoutRepository.ensureFundsFromCustomer().then((response) => {
      if (response.status === "SUCCESS") {
        setLoading(false);
        return navigateTo();
      }

      if (response.status === "VALIDATION_PENDING") {
        setLoading(false);
        navigate("/waitingvalidation");
      }

      if (response.status === "3DS_PENDING") {
        window.location.href = response.secureUrl;
      }

    }).catch((e) => {
      ensureFundsFromCustomer();
    })

  }

  const handleRequest = () => {
    setActiveButton(false);
    setLoading(true);
    acceptLegalNote();
  }

  const sendTrack = () => {
    const event = "policyPurchased";

    const eventData = {
      page: window.location.href,
      leadId: leadId,
      calculatedCapital: checkout!.capital,
      coverage: checkout!.coverage === "premium" ? "FIPA" : "F",
      intent: checkout!.intention,
      calculatedPremium: checkout!.regularPrice,
      paymentMethod: "card",
      utm_source: utm_source,
      utm_campaign: utm_campaign,
      utm_medium: utm_medium,
      utm_content: utm_content,
      utm_term: utm_term,
      utm_test: utm_test,
      gclid: gclid,
      msclkid: msclkid,
      fbclid: fbclid,
      platform: "App",
      discountedPremium: checkout!.promotionalCode
        ? parseFloat(numberFormatPayment(checkout!.priceToPay))
        : parseFloat(numberFormatPayment(checkout!.regularPrice)),
      paymentPeriod: checkout!.period ? checkout!.period.toLowerCase() : "monthly",
      policyId: leadId,
      entryPage: getEntryPage(),
      referrerUrl: getReferrerUrl(),
      ABTestVersion: getABTestVersion(),
    };

    handleTrackerQuestion({
      type: "GA",
      data: {
        event,
        ...eventData,
      },
    });

    handleTrackerQuestion({
      type: "FS",
      event,
      data: eventData,
    });
  }

  const normalizeNote = (url: string) => {
    const notePDF: string = url.split("/").pop() as string;
    const pdf = notePDF.split(".")[0].replaceAll("-", "_");
    return PDFS_ROUTES[pdf as PDFS_ROUTES.PDFST];
  };

  const fetchNote = async () => {
    const response = await leadRepository.getCheckoutNote();
    setNoteURL(response.url);
    const pdfNote = await normalizeNote(response.url);
    setNote(pdfNote);
    setLoading(false);
  };

  useEffect(() => {
    leadRepository.getPaymentData().then((response: NewCheckoutI) => {
      setCheckout(response);
    });
  }, []);

  useEffect(() => {
    (async () => {
      getStatus();
      await fetchNote();
    })();
  }, []);

  useEffect(() => {
    if (!userHasConditionsToAccept) {
      setTimeout(() => setActiveButton(true), 3000);
    }
  }, [userHasConditionsToAccept]);

  useEffect(() => {
    if (userHasConditionsToAccept) {
      const isPendingToAcceptConditions = Object.keys(userAcceptance).some(
        (key) => userAcceptance[key as userAcceptanceT] === false
      );

      if (isPendingToAcceptConditions) {
        setActiveButton(false);
      } else {
        setActiveButton(true);
      }
    }
  }, [userAcceptance, userHasConditionsToAccept]);

  return {
    activeButton,
    confirmed,
    handleChangeConfirmed,
    handleRequest,
    modalShow,
    note,
    noteUrl,
    setModalShow,
    translate,
    userAcceptance,
    setUserAcceptance,
    userHasConditionsToAccept
  };
};

export default CheckoutPayController;
