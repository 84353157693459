import HttpCouponsRepository from "../../api/request/Coupons/Coupons.service";
export interface CheckCouponI {
  leadId: string;
  promotion: string;
  price: number;
  noValidationNeeded?: boolean
}
type ResponseFunction = boolean;
export const checkPromotion = async ({
  leadId,
  promotion,
  price,
}: CheckCouponI): Promise<ResponseFunction> => {
  const couponsRepository = new HttpCouponsRepository(leadId);
  try {
    const checkCoupon = await couponsRepository.checkPromotionalCode({
      promotionalCode: promotion,
      yearlyPrice: price,
    });
    return checkCoupon.is_applicable_promo;
  } catch {
    throw new Error("Check Promotion is not available");
  }
};
