export const renderCoverages = (coverages: any, translate: any) => {
  return (
    <div>
      {coverages.map(
        (key: any) =>
          key !== "DEATH" && (
            <div className="flex flex-row">
              <span className="BodyM font-light text-[#7B7B7B]">
                +{" "}
                {translate(
                  `payment.multiproduct.${key
                    .toLowerCase()
                    .trim()
                    .replace(/[-_\s]+(.)?/g, (_: string, c: string) =>
                      c ? c.toUpperCase() : ""
                    )}`
                )}
              </span>
            </div>
          )
      )}
    </div>
  );
};
