import { Typography } from "get-life-storybook-ts";
import { PRIVACY_POLICY_SUMMARY_PAGE } from "../../Routes/constants/publicRoutes";

import "../../css/LegalPages.scss";

const PrivacyPolicy = () => {
  return (
    <main className="legalPages legalPages--layout">
      <Typography
        variant="H2"
        weight="700"
        text=""
        className="legalPages--title"
      >
        Política de privacidad de Life5 (completa)
      </Typography>
      <section className="legalPages--section">
        <Typography variant="BodyMedium" weight="400" text="">
          En virtud de lo establecido en el Reglamento (UE) 2016/679, del
          Parlamento Europeo y del Consejo de 27 de abril de 2016 relativo a la
          protección de las personas físicas en lo que respecta al tratamiento
          de Datos Personales y a la libre circulación de estos Datos Personales
          (“RGPD”), así como la normativa nacional de desarrollo, a
          continuación, le informamos de nuestra Política de Privacidad que rige
          el tratamiento de los datos personales que nos facilita:
          <br />
          <br />
          Consulta la información rápidamente en el siguiente enlace:{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href={PRIVACY_POLICY_SUMMARY_PAGE}
          >
            política de privacidad resumida
          </a>
          .
        </Typography>
      </section>
      <section className="legalPages--section">
        <Typography
          variant="H3"
          weight="700"
          text=""
          className="legalPages--sectionHeading"
        >
          Responsable del tratamiento de sus datos:
        </Typography>
        <Typography variant="BodyMedium" weight="400" text="">
          En virtud de lo establecido en el Reglamento (UE) 2016/679, del
          Parlamento Europeo y del Consejo de 27 de abril de 2016 relativo a la
          protección de las personas físicas en lo que respecta al tratamiento
          de Datos Personales y a la libre circulación de estos Datos Personales
          (“RGPD”), así como la normativa nacional de desarrollo, a
          continuación, le informamos de nuestra Política de Privacidad que rige
          el tratamiento de los datos personales que nos facilita:
        </Typography>
      </section>
      <section className="legalPages--section">
        <Typography
          variant="H3"
          weight="700"
          text=""
          className="legalPages--sectionHeading"
        >
          Finalidades del tratamiento:
        </Typography>
        <Typography variant="BodyMedium" weight="400" text="">
          Los Datos Personales del Usuario que se recaban a través de los
          Formularios disponibles en el Sitio Web son recabados por LIFE5 con
          las siguientes finalidades:
          <br />
          <br />
          <ol>
            <li>
              Gestionar los servicios solicitados a la Sociedad,
              correspondientes a la contratación de productos relacionados con
              seguros de terceros y del cálculo de las primas de seguros.
            </li>
            <li>Ponerse en contacto con la Sociedad.</li>
            <li>Realizar comunicaciones comerciales.</li>
          </ol>
          <br />
          De conformidad con lo establecido en el RGPD, LIFE5, titular del Sitio
          Web, informa al Usuario de la existencia de un fichero automatizado de
          Datos Personales, creado, gestionado y de responsabilidad de LIFE5.
          <br />
          <br />
          LIFE5 recogerá únicamente los Datos Personales que sean necesarios
          para, exclusivamente, realizar las finalidades que Usted consienta de
          manera libre, informada e inequívoca a través de la aceptación de la
          presente Política de Privacidad que acepta de manera LIFE5 expresa en
          los Formularios.
        </Typography>
      </section>
      <section className="legalPages--section">
        <Typography
          variant="H3"
          weight="700"
          text=""
          className="legalPages--sectionHeading"
        >
          Licitud del tratamiento de los datos personales
        </Typography>
        <Typography variant="BodyMedium" weight="400" text="">
          Los fines del tratamiento descritos en el apartado anterior se
          corresponden con las siguientes bases de licitud:
          <br />
          <br />
          <table>
            <tr>
              <th>Finalidad</th>
              <th>Licitud</th>
            </tr>
            <tr>
              <td>
                <b>Gestionar los servicios solicitados a la Sociedad.</b>
              </td>
              <td>
                El tratamiento es necesario para la ejecución de un contrato en
                el que el titular de los Datos Personales es parte.
              </td>
            </tr>
          </table>
        </Typography>
      </section>
      <section className="legalPages--section">
        <Typography
          variant="H3"
          weight="700"
          text=""
          className="legalPages--sectionHeading"
        >
          Veracidad de los datos
        </Typography>
        <Typography variant="BodyMedium" weight="400" text="">
          El Usuario garantiza que los Datos Personales facilitados son veraces
          y se hace responsable de comunicar a LIFE5 cualquier modificación de
          los mismos. El Usuario responderá de la veracidad de los Datos
          Personales facilitados, eximiendo de toda responsabilidad a la
          Sociedad al no poder alcanzar la finalidad consentida.
        </Typography>
      </section>
      <section className="legalPages--section">
        <Typography
          variant="H3"
          weight="700"
          text=""
          className="legalPages--sectionHeading"
        >
          Conservación de los datos
        </Typography>
        <Typography variant="BodyMedium" weight="400" text="">
          Los Datos Personales se conservarán durante el tiempo necesario para
          la realización de las finalidades para las que fueron recogidos,
          mientras no revoque los consentimientos otorgados. Posteriormente, en
          caso de ser necesario, mantendrá la información bloqueada los plazos
          legalmente establecidos.
        </Typography>
      </section>
      <section className="legalPages--section">
        <Typography
          variant="H3"
          weight="700"
          text=""
          className="legalPages--sectionHeading"
        >
          Comunicación de los datos personales
        </Typography>
        <Typography variant="BodyMedium" weight="400" text="">
          La Sociedad informa al Usuario que sus Datos Personales podrán ser
          comunicados a terceros cuando dicha comunicación sea imprescindible
          para satisfacer las finalidades por las cuales los Datos Personales
          fueron obtenidos. Asimismo, serán comunicados cuando dicha
          comunicación se ampare en una obligación legal o requerimiento
          judicial.
          <br />
          <br />
          Concretamente, se realizarán las siguientes comunicaciones de sus
          Datos Personales a los proveedores de la Sociedad, entidades
          aseguradoras, con los que se contratan los seguros solicitados por los
          Usuarios.
        </Typography>
      </section>
      <section className="legalPages--section">
        <Typography
          variant="H3"
          weight="700"
          text=""
          className="legalPages--sectionHeading"
        >
          Transferencias internacionales de datos
        </Typography>
        <Typography variant="BodyMedium" weight="400" text="">
          Se informa al Usuario que la Sociedad, como consecuencia de los
          servicios contratados, en algunas ocasiones tendrá que realizar
          transferencias internacionales de sus Datos Personales. Estas
          transferencias se realizarán cuando los seguros ofertados se contraten
          con entidades aseguradoras que se encuentren localizadas fuera del
          Espacio Económico Europeo.
          <br />
          <br />
          En este sentido, la Sociedad ha tomado las medidas a su alcance con el
          fin de asegurar que los Datos Personales se tratarán por las entidades
          aseguradoras en concordancia con los niveles de seguridad garantizados
          por la normativa aplicable y de conformidad con el RGPD, así como las
          instrucciones y decisiones emitidas por las diferentes autoridades en
          materia de protección de datos personales.{" "}
        </Typography>
      </section>
      <section className="legalPages--section">
        <Typography
          variant="H3"
          weight="700"
          text=""
          className="legalPages--sectionHeading"
        >
          Decisiones automatizadas
        </Typography>
        <Typography variant="BodyMedium" weight="400" text="">
          Cuando el Usuario solicite la contratación de seguros con terceros o
          solicite el cálculo de las primas que corresponderían a determinados
          seguros, sus Datos Personales podrán ser objeto de decisiones
          automatizadas. En tal caso, solo serán objeto de estas decisiones
          automatizadas realizadas por LIFE5 los Datos Personales que se
          faciliten en los correspondientes Formularios.
          <br />
          <br />
          En este sentido, y en cumplimiento de los establecido en el RGPD,
          Usted consiente de manera libre, inequívoca e informada que sus Datos
          Personales serán objeto de dichas decisiones automatizadas
          exclusivamente para la gestión de los servicios solicitados a la
          Sociedad. Asimismo, el resultado de las decisiones automatizadas podrá
          ser revisadas por el personal de LIFE5, así como por el personal de
          las entidades aseguradoras con las que se contrate el seguro
          correspondiente.
          <br />
          <br />
          Le recordamos que como consecuencia de los productos finales que vaya
          a contratar, los Datos Personales que puedan ser objeto de este tipo
          de tratamiento, en ocasiones podrán ser datos de salud, catalogados,
          de acuerdo con el RGPD, como especiales. Es por ello, que LIFE5 se
          compromete y garantiza al Usuario que ha implementado las medidas de
          seguridad técnicas y organizativas necesarias para llevar a cabo
          dichos tratamientos de conformidad con la normativa aplicable y en
          cumplimiento del RGPD.
        </Typography>
      </section>
      <section className="legalPages--section">
        <Typography
          variant="H3"
          weight="700"
          text=""
          className="legalPages--sectionHeading"
        >
          Derechos del usuario en relación con sus datos
        </Typography>
        <Typography variant="BodyMedium" weight="400" text="">
          Como consecuencia del tratamiento sobre los Datos Personales que
          realiza LIFE5, es considerado como responsable del tratamiento de los
          mismos. Es por ello, que el Usuario, como titular de los Datos
          Personales, tiene derecho a ejercitar los siguientes derechos:
          <br />
          <br />
          <b>Acceso:</b> En el derecho de acceso se facilitará a los interesados
          copia de los datos personales de los que se disponga junto con la
          finalidad para la que han sido recogidos, la identidad de los
          destinatarios de los datos, los plazos de conservación previstos o el
          criterio utilizado para determinarlo.
          <br />
          <br />
          <b>Rectificación:</b> En el derecho de rectificación se procederá a
          modificar los datos de los interesados que fueran inexactos o
          incompletos atendiendo a los fines del tratamiento. El interesado
          deberá indicar en la solicitud a qué datos se refiere y la corrección
          que haya de realizarse, aportando, cuando sea preciso, la
          documentación justificativa de la inexactitud o carácter incompleto de
          los datos objeto de tratamiento.
          <br />
          <br />
          <b>Supresión:</b> En el derecho de supresión se eliminarán los datos
          de los interesados cuando estos manifiesten su negativa al tratamiento
          y no exista una base legal que lo impida, no sean necesarios en
          relación con los fines para los que fueron recogidos, retiren el
          consentimiento prestado y no haya otra base legal que legitime el
          tratamiento o éste sea ilícito.
          <br />
          <br />
          <b>Desistimiento:</b> Tiene derecho a desistir de forma gratuita y en
          cualquier momento del consentimiento otorgado para el tratamiento de
          sus datos personales.
          <br />
          <br />
          <b>Oposición:</b> En el derecho de oposición, cuando los interesados
          manifiesten su negativa al tratamiento de sus datos personales ante el
          responsable, este dejará de procesarlos siempre que no exista una
          obligación legal que lo impida.
          <br />
          <br />
          <b>Limitación:</b> En el derecho de limitación del tratamiento, los
          interesados pueden solicitar la suspensión del tratamiento de sus
          datos para impugnar su exactitud mientras el responsable realiza las
          verificaciones necesarias o en el caso de que el tratamiento se
          realice en base al interés legítimo del responsable o en cumplimiento
          de una misión de interés público, mientras se verifica si estos
          motivos prevalecen sobre los intereses, derechos y libertades del
          interesado. El interesado también puede solicitar la conservación de
          los datos si considera que el tratamiento es ilícito y, en lugar de la
          supresión, solicita la limitación del tratamiento, o si aun no
          necesitándolos ya el responsable para los fines para los que fueron
          recabados, el interesado los necesita para la formulación, ejercicio o
          defensa de reclamaciones.
          <br />
          <br />
          <b>Portabilidad:</b> En el derecho de portabilidad, si el tratamiento
          se efectúa por medios automatizados y se basa en el consentimiento o
          se realiza en el marco de un contrato, los interesados pueden
          solicitar recibir copia de sus datos personales en un formato
          estructurado, de uso común y lectura mecánica. Asimismo, tienen
          derecho a solicitar que sean transmitidos directamente a un nuevo
          responsable, cuya identidad deberá ser comunicada, cuando sea
          técnicamente posible.
          <br />
          <br />
          <b>No ser objeto de decisiones automatizadas:</b> El Usuario tiene
          derecho a no ser objeto de decisiones automatizadas. No obstante,
          cuando solicite este derecho, no podrá seguir contratando algunos de
          los servicios ofertados por LIFE5. El Usuario puede ejercitar todos
          estos derechos, acreditando su identidad con su DNI, en la siguiente
          dirección de email{" "}
          <a href="mailto:asesores@axa.life5.es">asesores@axa.life5.es</a>
          : o por correo ordinario a la siguiente dirección:
          <br />
          <br />
          <b>YOUR LIFE CORREDURÍA DE SEGUROS, S.L.</b> Calle Francisco de Rojas
          3, 6ºD. 28010, Madrid.
          <br />
          <br />
          Por último, de acuerdo con lo establecido en el RGPD, se informa al
          Usuario que en caso de que considere que no se ha visto satisfecho el
          ejercicio solicitado de alguno de los derechos otorgados por esta
          normativa, puede presentar una reclamación ante la Agencia Española de
          Protección de Datos.
        </Typography>
      </section>
      <section className="legalPages--section">
        <Typography
          variant="H3"
          weight="700"
          text=""
          className="legalPages--sectionHeading"
        >
          Seguridad de los datos
        </Typography>
        <Typography variant="BodyMedium" weight="400" text="">
          La protección de la privacidad y sus Datos Personales son muy
          importantes para LIFE5. Por ello, la Sociedad mantiene los niveles de
          seguridad de protección de Datos Personales conforme a la normativa
          aplicable y ha establecido todos los medios técnicos a su alcance para
          evitar la pérdida, mal uso, alteración, acceso no autorizado y robo de
          los Datos Personales que el Usuario facilite a través del Sitio Web.
          <br />
          <br />
          Asimismo, LIFE5 y sus profesionales se comprometen a cumplir con el
          deber de secreto y confidencialidad respecto de los Datos Personales
          de acuerdo con el RGPD.
        </Typography>
      </section>
      <section className="legalPages--section">
        <Typography
          variant="H3"
          weight="700"
          text=""
          className="legalPages--sectionHeading"
        >
          Aceptación y consentimiento
        </Typography>
        <Typography variant="BodyMedium" weight="400" text="">
          El Usuario declara haber sido informado de las condiciones sobre
          protección de Datos Personales, aceptando y consintiendo el
          tratamiento de los mismos por parte de LIFE5, de acuerdo con lo
          establecido en la presente Política de Privacidad y para las
          finalidades indicadas por el Usuario.
        </Typography>
      </section>
    </main>
  );
};

export default PrivacyPolicy;
