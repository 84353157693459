import moment from "moment";

interface SelectI<Type> {
  label: string;
  value: Type;
}

export const optionDateList = (
  translate: (key: string) => string,
  market?: string
): SelectI<string>[] => {
  const today = moment();
  const tomorrow = moment().add(1, "days");
  const nextWeek = moment().add(7, "days");
  const fifteenDays = moment().add(15, "days");
  const oneMonth = moment().add(1, "month");

  if (market === "it") {
    return [
      {
        value: tomorrow.format("YYYY-MM-DD"),
        label: translate("productQuestions.calendar.tomorrow"),
      },
      {
        value: nextWeek.format("YYYY-MM-DD"),
        label: translate("productQuestions.calendar.nextWeek"),
      },
      {
        value: fifteenDays.format("YYYY-MM-DD"),
        label: translate("productQuestions.calendar.fifteenDays"),
      },
      {
        value: "other",
        label: translate("productQuestions.calendar.otheDate"),
      },
    ];
  } else {
    return [
      {
        label: translate("productQuestions.calendar.today"),
        value: today.format("YYYY-MM-DD"),
      },
      {
        value: tomorrow.format("YYYY-MM-DD"),
        label: translate("productQuestions.calendar.tomorrow"),
      },
      {
        value: nextWeek.format("YYYY-MM-DD"),
        label: translate("productQuestions.calendar.nextWeek"),
      },
      {
        value: fifteenDays.format("YYYY-MM-DD"),
        label: translate("productQuestions.calendar.fifteenDays"),
      },
      {
        value: oneMonth.format("YYYY-MM-DD"),
        label: translate("productQuestions.calendar.oneMonth"),
      },
      {
        value: "other",
        label: translate("productQuestions.calendar.otheDate"),
      },
    ];
  }
};
