import * as React from "react";
import { I18nContext } from "../../../../context/i18n.context";
import "./Welcome.styles.scss";
import { GlobalIcon } from "get-life-storybook-ts";

interface WelcomeI {
  name?: string;
  email?: string;
  date?: string;
  city?: string;
}

const Progression: React.FC<WelcomeI> = ({ name, email, date, city }) => {
  const {
    state: { translate },
  } = React.useContext(I18nContext);

  return (
    <div className="welcomeSection">
      <h1 className="welcomeSection__title">
        {translate("welcome.title")}
        {name ? ` ${name},` : `,`}
      </h1>
      <h5 className="welcomeSection_subtitle">
        {translate("welcome.subtitle")}
      </h5>
      <div className="welcomeSection__userInfo">
        {email && (
          <div className="flex flex-row gap-[4px] items-center">
            <GlobalIcon
              iconName="MailIcon"
              color="var(--theme-primary)"
              size="XS"
            />
            <span>{email}</span>
          </div>
        )}
        {date && (
          <div className="flex flex-row gap-[4px] items-center">
            <GlobalIcon
              iconName="Calendar2Icon"
              color="var(--theme-primary)"
              size="XS"
            />
            <span>{date}</span>
          </div>
        )}
        {city && (
          <div className="flex flex-row gap-[4px] items-center">
            <GlobalIcon
              iconName="BuildingIcon"
              color="var(--theme-primary)"
              size="XS"
            />
            <span>{city}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Progression;
