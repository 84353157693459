import { reorderDate } from "get-life-storybook-ts";
import React from "react";
import { GetLifeCalculatorContext } from "../../../context/GetLifeCalculator.context";

const CalculatorPageITController = () => {
  const contextCalculator = React.useContext(GetLifeCalculatorContext);

  const handleClickOrganic = () => {
    contextCalculator.handleClickOrganic({
      birthDate: reorderDate(contextCalculator.dateOfBirth),
      campaign: contextCalculator.gtf_campaign || "calculator-app",
      capital: parseFloat(contextCalculator.insuranceCover),
      email: contextCalculator.email,
      gender: contextCalculator.sex,
      initialCapital: contextCalculator.minCapital,
      phone: contextCalculator.phoneNumber,
      nicotine: contextCalculator.smoke,
      userAcceptsCommunications: contextCalculator.checkedConditions3
    });
  };

  const options = {
    smoke: [
      {
        value: "yes",
        text: contextCalculator.translate("yes"),
        label: contextCalculator.translate("yes"),
        illustration: "SmokerIcon",
        variant: "Illustration"
      },
      {
        value: "no",
        text: contextCalculator.translate("no"),
        label: contextCalculator.translate("no"),
        illustration: "NonSmokerIcon",
        variant: "Illustration"
      },
    ],
  };

  return { contextCalculator, handleClickOrganic, options };
};

export default CalculatorPageITController;
