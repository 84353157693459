import React, { useContext } from "react";
import { Container } from "@material-ui/core";
import "../../../App.scss";
import ProductStackQuestions from "./ProductStackQuestions";
import apiGetInsuranceSavings from "../../../api/request/apiGetInsuranceSavings";
import HttpLeadRepository from "../../../api/request/Lead/Lead.service";
import { useLocation, useNavigate } from "react-router";
import { GetLifeTrackerContext } from "../../../context/GetLifeTracker.context";
import { GetlifeContext } from "../../../context/GetLifeContext.context";

export default function ProductStackQuestionsMain() {
  const {
    leadId,
    loading,
    progressBar,
    setLeadId,
    setLoading,
    setProgressBar,
    setToken,
    token,
    nodeMaxCapitalModal
  } = useContext(GetlifeContext);

  const { handleTrackerQuestion, utm_campaign, utm_source, utm_medium,utm_content, utm_term, utm_test, gclid, msclkid, fbclid } = useContext(GetLifeTrackerContext);

  const navigate = useNavigate();

  const [data, setData] = React.useState({
    answer: "",
    checkOut: "",
    countBack: 1,
    idUsed: [],
    question: [],
  });
  const [popUp, setPopUp] = React.useState({
    callMe: false,
    blockers: false,
  });
  const [responseCode, setResponseCode] = React.useState(true);
  const [popupsName, setPopupsName] = React.useState("");
  const [modeModal, setModeModal] = React.useState("1");
  const [infoModal, setInfoModal] = React.useState("");
  const [questionStack, setQuestionStack] = React.useState([]);

  const leadRepository = new HttpLeadRepository(leadId, token);

  const Location = useLocation();

  const route = Location.pathname;

  React.useEffect(() => {
    if (route === "/productQuestionsSurvey") {
      leadRepository.assingABTest();
    }
    setProgressBar(30);
  }, []);

  React.useEffect(() => {
    if (responseCode === 0) {
      navigate("/step/4");
    } else if (responseCode === -1 || responseCode === "rejected") {
      leadRejectedEvent(responseCode)
      setProgressBar(false);
      navigate("/notcoverage");
    }
  }, [responseCode]);

  const handleChange = (index, name, value, type) => {
    if (type === "array") {
      const idUsedState = data.idUsed;

      idUsedState.push(value);
      value = idUsedState;
      setData({ ...data, idUsed: value });
    } else if (type === "onlyIndex") {
      if (index === "progressBar") {
        setProgressBar(value);
      }
    } else {
      setData({ ...data, [name]: value });
    }
  };

  const handleCallMePopUp = () => {
    setPopUp({
      popUp: { ...popUp, callMe: !popUp.callMe },
    });
  };

  const saveQuestion = (responseAnswer) => {
    const { questionId } = responseAnswer ? responseAnswer : data.question;

    let answer = responseAnswer ? responseAnswer.answers[1] : data.answer;
    if (typeof answer !== "string" && "value" in answer) {
      answer = answer.value;
    } else if (Array.isArray(answer)) {
      answer = answer.join(", ");
    }

    leadRepository
      .saveQuestion({
        answer:
          data.question.type === "number"
            ? answer.replaceAll(/[^0-9]+/g, "")
            : answer,
        questionId,
      })
      .then(() => {
        setQuestionStack([...questionStack, questionId]);
        const eventData = {
          questionId: questionId,
          stack: "Product",
          leadId: leadId,
          page: window.location.href,
          platform: "App",
          answerId: answer,
          pageTitle: "Life5 D2C - QuestionAnswered",
        }
        
        handleTrackerQuestion({
          type: "FS",
          event: "Question Answered",
          data: {
            answerString: answer,
            ...eventData
          }
        });
        handleTrackerQuestion({
          type: "GA",
          data: {
            event: "virtualPageview",
            answers: answer,
            ...eventData
          },
        });

        if (!data.idUsed.includes(questionId)) {
          handleChange("data", "idUsed", questionId, "array");
        }
        refreshNextQuestion();
      })
      .catch();
    handleChange("data", "countBack", 1);
  };

  const nextQuestion = () => {
    setLoading(true);
    window.scrollTo(0, 0);
    saveQuestion();
  };

  const lastQuestion = () => {
    let lastQuestionId = questionStack[(questionStack.length - 1)];
    const progressDecrement = progressBar - 5;
    if (lastQuestionId) {
      setLoading(true);
      handleChange("data", "question", lastQuestionId);
      handleChange("data", "countBack", data.countBack + 1);

      return leadRepository.editProductQuestion(lastQuestionId).then((response) => {
        setData({
          ...data,
          answer: null,
          question: response,
        });
        setLoading(false);
        setPopUp({ callMe: false });
        setResponseCode(response.code);
        handleChange("progressBar", null, progressDecrement, "onlyIndex");
      });
    }
  };

  const leadRejectedEvent = (responseCode) => {
    if (responseCode === -1 || responseCode === "rejected") {
      const event = "leadRejected";
      const eventData = {
        leadId: leadId,
        page: window.location.href,
        utm_source: utm_source,
        utm_campaign: utm_campaign,
        utm_medium: utm_medium,
        utm_content: utm_content,
        utm_term: utm_term,
        utm_test: utm_test,
        gclid: gclid,
        msclkid: msclkid,
        fbclid: fbclid,
      }

      handleTrackerQuestion({
        type: "GA",
        data: {
          event,
          ...eventData
        },
      });
      handleTrackerQuestion({
        type: "FS",
        event,
        data: eventData
      });
    }
  }

  const refreshNextQuestion = () => {
    const progressIncrement = progressBar + 5;
    setLoading(true);

    return leadRepository
      .getProductQuestion()
      .then((response) => {
        if (response.code === 401) {
          navigate("/");
        } else {
          setData({
            ...data,
            answer: data.question.type === "checkbox_optional" ? "none" : null,
            question: response,
          });
          setLoading(false);
          setPopUp({ callMe: false });
          setResponseCode(response.code);

          const eventData = {
            questionId: response.questionId,
            stack: "Product",
            leadId: leadId,
            page: window.location.href,
            platform: "App",
            pageTitle: "Life5 D2C - QuestionViewed",
          }

          if (response.questionId) {
            handleTrackerQuestion({
              type: "GA",
              data: {
                event: "virtualPageview",
                ...eventData
              },
            });

            handleTrackerQuestion({
              type: "FS",
              event: "Question Viewed",
              data: eventData,
            });
          }

          handleChange("progressBar", null, progressIncrement, "onlyIndex");
          
          if (response.newCapital) {
            nodeMaxCapitalModal.current?.handleShowPortal();
          }
        }

        if (response.popups !== undefined && response.popups.length > 0) {
          if (response.popups[0] === "SavingGrafics") {
            apiGetInsuranceSavings(leadId, token).then((responseSaving) => {
              setPopupsName(response.popups[0]);
              setInfoModal(responseSaving.output);
            });
          } else {
            setPopupsName(response.popups[0]);
          }
        }
      })
      .catch((e) => {
       if (e.status === 400) {
        setLoading(false);
        navigate("/notcoverage");
      }});
  };

  return (
    <Container className={"questions-outerContainer"}>
      <ProductStackQuestions
        handleCallMe={handleCallMePopUp}
        handleOnChange={handleChange}
        isLoading={loading}
        nextQuestion={nextQuestion}
        params={data}
        refreshNextQuestion={refreshNextQuestion}
        saveQuestion={saveQuestion}
        handleLeadId={setLeadId}
        handleToken={setToken}
        leadId={leadId}
        showCallMe={popUp.callMe}
        token={token}
        goBack={lastQuestion}
        popups={popupsName}
        infoModal={infoModal}
        setModeModal={(e) => setModeModal(e)}
        modeModal={modeModal}
        route={route}
        questionStack={questionStack}
      />
    </Container>
  );
}
