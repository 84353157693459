import { Typography } from "get-life-storybook-ts";
interface OverlayInfoIpaI {
  translate: (key: string) => string;
}

const OverlayInfoIpa: React.FC<OverlayInfoIpaI> = ({ translate }) => (
  <div className="payment-newUpsellContainer__section__card__overlay">
    <Typography
      variant="BodyMedium"
      text={translate("newUpsell.OverlayInfoCapital.text1")}
    >
      <Typography
        variant="SpanMedium"
        weight="700"
        text={translate("newUpsell.OverlayInfoCapital.text2")}
      />
      <Typography
        variant="SpanMedium"
        text={translate("newUpsell.OverlayInfoCapital.text3")}
      />
    </Typography>
  </div>
);

export default OverlayInfoIpa;
