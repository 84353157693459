import { Typography } from "get-life-storybook-ts";
import "../../css/LegalPages.scss";

const PrivacyPolicySummary = () => {
  return (
    <main className="legalPages legalPages--layout">
      <Typography variant="BodyMedium" weight="400" text="">
        <table className="legalPages--tableFifty">
          <tr>
            <th>Nombre</th>
            <th>Descripción</th>
          </tr>
          <tr>
            <td>
              <b>RESPONSABLE DEL TRATAMIENTO</b>
            </td>
            <td>
              <b>YOUR LIFE CORREDURÍA DE SEGUROS, S.L.</b>
              <br />
              <br />
              CIF nº B – 42.814.236.
              <br />
              <br />
              Calle Francisco de Rojas 3, 6ºD; 28010, Madrid.
            </td>
          </tr>
          <tr>
            <td>
              <b>FINALIDADES</b>
            </td>
            <td>
              <ul>
                <li>
                  Gestionar los servicios solicitados a la Sociedad,
                  correspondientes a la contratación de productos relacionados
                  con seguros de terceros y del cálculo de las primas de
                  seguros.
                </li>
                <li>Ponerse en contacto con la Sociedad.</li>
                <li>Realizar comunicaciones comerciales.</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <b>LICITUD</b>
            </td>
            <td>
              <ul>
                <li>
                  El tratamiento es necesario para la ejecución de un contrato.
                </li>
                <li>El consentimiento otorgado.</li>
                <li>
                  La satisfacción de los intereses legítimos perseguidos por
                  ZIRO en relación con dar a conocer sus nuevos servicios.
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <b>DECISIONES AUTOMATIZADAS</b>
            </td>
            <td>
              Se realizarán decisiones automatizadas sobre los datos personales
              del usuario cuando solicite el cálculo de primas de los seguros a
              contratar.
            </td>
          </tr>
          <tr>
            <td>
              <b>COMUNICACIONES</b>
            </td>
            <td>
              Los datos del vendedor podrán ser comunicados a los colaboradores
              de LIFE5 para la consecución de las finalidades indicadas.
            </td>
          </tr>
          <tr>
            <td>
              <b>DERECHOS</b>
            </td>
            <td>
              <ul>
                <li>Acceso</li>
                <li>Rectificación</li>
                <li>Supresión</li>
                <li>Oposición</li>
                <li>Limitación</li>
                <li>Portabilidad</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <b>EJERCICIO DE LOS DERECHOS</b>
            </td>
            <td>
              Por escrito a la dirección: calle Francisco de Rojas 3, 6ºD;
              28010, Madrid.
              <br />
              Al correo electrónico{" "}
              <a href="mailto:asesores@axa.life5.es">asesores@axa.life5.es</a>
            </td>
          </tr>
        </table>
      </Typography>
    </main>
  );
};

export default PrivacyPolicySummary;
