import FormAdress from "../../forms/FormAdress";
import FormPhysicalPerson from "../../forms/beneficiaries/FormPhysicalPerson";
import FormBankingEntity from "../../forms/beneficiaries/FormBankingEntity";
import FormLegalPerson from "../../forms/beneficiaries/FormLegalPerson";
import Nationality from "../../../../views/Questions/PostStackQuestions/components/Nationality";
import "../formBuilder.scss";
import { DropDown } from "get-life-storybook-ts";

import { range } from "../FormBuilder";
import WidgetVelogica from "../../WidgetVelogica/WidgetVelogica";
import IdCardBiometry from "../../../../views/IdCard/idCardBiometry.component";

const FormType = ({
  actualUrl,
  answer,
  answers,
  capitalLimits,
  componentName,
  chat,
  content,
  countryList,
  handleSubmit,
  handleValues,
  isLoading,
  leadId,
  personalData,
  prepareLabel,
  subtitle,
  token,
  tooltip,
  translate,
  type,
  question,
}) => {
  return (
    <>
      {componentName === "external_underwriting" && (
        //TODO Revisar que existe
        <>{isLoading === false && <WidgetVelogica question={question} />}</>
      )}

      {type === "capital" && (
        <>
          {isLoading === false && (
            <DropDown
              helpContent={tooltip && tooltip !== " " && <p>{tooltip}</p>}
              overlayButtonLabel={translate("productQuestions.overlay.button")}
              onValueChange={(e) => handleValues(e)}
              options={
                type === "capital"
                  ? range({
                      initial: capitalLimits.capitalRangueMin,
                      final: capitalLimits.capitalRangueMax,
                      step: capitalLimits.capitalRangueStep,
                    }).reverse()
                  : answers
              }
              placeholder={prepareLabel(content)}
              query={content}
              supportText={subtitle}
            />
          )}
        </>
      )}

      {type === "formAdress" && (
        <>
          {isLoading === false && (
            <FormAdress
              token={token}
              leadId={leadId}
              nextQuestion={handleSubmit}
              chat={chat}
              handleValues={handleValues}
              answer={answer}
              handleSubmit={handleSubmit}
              actualUrl={actualUrl}
              question={question}
            />
          )}
        </>
      )}

      {type === "formBeneficiariesPhysicalPerson" && (
        <>
          {isLoading === false && (
            <FormPhysicalPerson
              nextQuestion={handleSubmit}
              chat={chat}
              handleValues={handleValues}
              answer={answer}
              token={token}
              leadId={leadId}
              actualUrl={actualUrl}
            />
          )}
        </>
      )}

      {type === "formBeneficiariesBankingEntity" && (
        <>
          {isLoading === false && (
            <FormBankingEntity
              nextQuestion={handleSubmit}
              chat={chat}
              handleValues={handleValues}
              answer={answer}
              token={token}
              leadId={leadId}
              actualUrl={actualUrl}
            />
          )}
        </>
      )}

      {type === "formBeneficiariesLegalPerson" && (
        <>
          {isLoading === false && (
            <FormLegalPerson
              nextQuestion={handleSubmit}
              chat={chat}
              handleValues={handleValues}
              answer={answer}
              token={token}
              leadId={leadId}
              actualUrl={actualUrl}
            />
          )}
        </>
      )}

      {type === "nationality" && (
        <>
          {isLoading === false && (
            <Nationality
              personalData={personalData}
              nextQuestion={handleSubmit}
              chat={chat}
              handleValues={handleValues}
              answer={answer}
              answers={answers}
              content={content}
              subtitle={subtitle}
              countryList={countryList}
              token={token}
              leadId={leadId}
              actualUrl={actualUrl}
            />
          )}
        </>
      )}
      {type === "idCard" ? (
        <IdCardBiometry />
      ) : null}
    </>
  );
};

export default FormType;
