/* eslint-disable jsx-a11y/alt-text */
import {
  AnimatedAlert,
  ButtonDS,
  DataBlockSQL,
  GlobalIcon,
  HelpOverlay,
  TransitionPage,
} from "get-life-storybook-ts";
import "./ConfirmationPageSQL.styles.scss";
import UserIcon from "get-life-storybook-ts/lib/components/Icons/userIcon";
import CheckIcon from "get-life-storybook-ts/lib/components/Icons/checkIcon";
import UmbrellaIcon from "get-life-storybook-ts/lib/components/Icons/umbrellaIcon";
import CalendarIcon from "get-life-storybook-ts/lib/components/Icons/calendarIcon";
import CardIcon from "get-life-storybook-ts/lib/components/Icons/cardIcon";
import TagIcon from "get-life-storybook-ts/lib/components/Icons/tagIcon";
import { capitalize, numberFormatPayment } from "../../utils/Utils";
import Progression from "../DecisionPageSQL/components/Progression.component";
import CouponInfo from "../StepsCalculatorPage/Components/Coupon/CouponInfo.component";
import ConfirmationPageSQLController from "./ConfirmationPageSQL.controller";
import Spinner from "get-life-storybook-ts/lib/components/Icons/Spinner";

const ICONS = {
  user: <UserIcon />,
  check: <CheckIcon />,
  umbrella: <UmbrellaIcon />,
  calendar: <CalendarIcon />,
  card: <CardIcon />,
};

const ConfirmationPageSQL = () => {
  const {
    continueProcess,
    couponInfo,
    data,
    dataBlocksInfo,
    open,
    progression,
    setOpen,
    translate,
  } = ConfirmationPageSQLController();

  const isDataLoaded =
    data !== undefined &&
    dataBlocksInfo !== undefined &&
    progression !== undefined;

  // if(!isDataLoaded) {
  //   return <span className="spinnerLoading"><Spinner height={64} width={64} /></span>;
  // }

  return (
    <div className="confirmationPageSQL">
      {/* {data.promo && (
        <HelpOverlay
          setIsOpen={setOpen}
          isOpen={open}
          buttonLabel={translate("coupons.overlay.button")}
        >
          <CouponInfo
            translate={translate}
            couponInfo={couponInfo}
            couponName={product?.promotion?.name}
          />
        </HelpOverlay>
      )} */}
      <div className="confirmationPageSQL-content">
        <div className="flex flex-col md:pb-8">
          <GlobalIcon
            iconName="FilledCheckIcon"
            className="icon-check"
            color="#018565"
            size="L"
          />
          <span className="H2 font-bold text-[#424242] mb-[12px] mt-[20px] break-words">
            {translate("confirmationPage.sql.title")}
          </span>
          <span className="BodyL font-light text-[#424242] break-words">
            {translate("confirmationPage.sql.confirmation.text1")}
            {data?.email}
            {translate("confirmationPage.sql.confirmation.text2")}
          </span>
        </div>
        <ButtonDS
          label={translate("decision.continue.button")}
          onClick={() => continueProcess()}
          className="w-full md:hidden mt-[12px] mb-[20px]"
          rightIcon="ArrowRightIcon"
        />
        <div className="dataBlockProgressInfo">
          <div className="dataBlock">
            {dataBlocksInfo && (
              <>
                {dataBlocksInfo.map((dataBlock, index) => (
                  <>
                    {dataBlock.space && <hr />}
                    <DataBlockSQL
                      key={`dataBlock-${index}`}
                      title={dataBlock.title}
                      subtitle={dataBlock.subtitle}
                      text={dataBlock.text}
                      icon={ICONS[dataBlock.icon]}
                    />
                  </>
                ))}
                {!!data.promo && data.promo.description !== "" && (
                  <>
                    <hr />{" "}
                    <DataBlockSQL
                      title={translate("confirmationPage.resume.couponSavings")}
                      subtitle={data.promo.description}
                      text={<></>}
                      icon={<TagIcon />}
                    />
                  </>
                )}
              </>
            )}
          </div>
          <div className="verticalLine"></div>
          <div className="progressNextStep">
            <div className="progressComponent">
              {progression && (
                <Progression
                  title={translate("welcome.Progression.title")}
                  list={progression}
                  type=""
                />
              )}
            </div>
            <div className="infoNextStep">
              <div className="infoNextStep--text">
                <div>
                  <GlobalIcon iconName="InfoIcon" color="#7B7B7B" />
                </div>
                <p className="BodyM font-regular text-[#7B7B7B]">
                  {translate("confirmationPage.dni")}
                </p>
              </div>
              <div className="infoNextStep--button">
                <ButtonDS
                  label={translate("decision.continue.button")}
                  onClick={() => continueProcess()}
                  className="w-full"
                  rightIcon="ArrowRightIcon"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationPageSQL;
