/* eslint-disable jsx-a11y/anchor-has-content */
import numberFormat from "../../../utils/Utils";
import QuotePageController from "./QuotePage.controller";
import {
  AdditionalProtection,
  BoxColor,
  ButtonDS,
  CTABanner,
  DownloadableFiles,
  GlobalIcon,
  Price,
  QuoteBanner,
  Slider,
  TextWithInfoIcon,
  Typography,
} from "get-life-storybook-ts";
import { DOMAIN, PHONE_NOT_COVER } from "../../../constants/Global";
import { LanguageT } from "../../../context/GetLifeContext.context";
import {
  GlobalIllnes,
  GlobalOnlyPremium,
  GlobalCapitalStepper,
  GlobalIllnesTitle,
  GlobalIllnesIcon,
  GlobalIllnesContent,
  QuotePageBenefitsList,
} from "../../../utils/InternationlChanges";

import "./QuotePage.styles.scss";
import Spinner from "get-life-storybook-ts/lib/components/Icons/Spinner";

const listPoints = (translate: (key: string) => string): string[] => {
  const points =
    QuotePageBenefitsList[DOMAIN!] || QuotePageBenefitsList["default"];
  return points.map((p) => translate(p));
};

const QuotePage = () => {
  const {
    basicFrom,
    afterPromoText,
    beforePromoText,
    btnContractLabel,
    conditionsPrice,
    coverage,
    downloadableFiles,
    downloadableLoading,
    downloadDocument,
    handleButtonQuote,
    hasTelesubscription,
    hideIpaToogle,
    insuranceCover,
    ipaToggle,
    isAddictionalProtectionApplied,
    isMobile,
    linkRefCall,
    loadingQuote,
    maxCapital,
    minCapital,
    navigateBack,
    renderAddictionalProtection,
    setFlagLink,
    setInsuranceCover,
    showGlobalIllnesTitle,
    showGlobalIllnesContent,
    translate,
    updateIpa,
    updatePrices,
    isStickyVisible,
  } = QuotePageController();

  const coveredSeriousIllnesses = [
    translate("calculatorPage.cancer"),
    translate("calculatorPage.infarct"),
    translate("calculatorPage.stroke"),
    translate("calculatorPage.renalInsufficiency"),
    translate("calculatorPage.paralysis"),
    translate("calculatorPage.vitalOrganTransplant"),
    translate("calculatorPage.alzheimer"),
    translate("calculatorPage.parkinson"),
  ];

  return (
    <main className="QuotePage">
      <section className="QuotePage__journeyquestion" id="quote_page_banner">
        <QuoteBanner
          containerStyle="heroBannerStyle w-full"
          subtitleTextStyle="subtitleTextStyle"
          title={""}
          subtitle={basicFrom !== 0 ? translate("calculatorPage.textYourPrice") : ""}
          btnLabel={btnContractLabel}
          btnOnClick={handleButtonQuote}
          callmeLabel={
            hasTelesubscription && coverage !== "eg"
              ? translate("calculatorPage.QuotePage.maxCapital.button").replace(
                "$",
                `${PHONE_NOT_COVER}`
              )
              : ""
          }
          callmeLabelOnClick={() => setFlagLink(true)}
          content={basicFrom !== 0 ? (
            <div className="flex md:justify-center">
              <Price
                after={afterPromoText}
                decimals={conditionsPrice("decimal")}
                frequency={
                  beforePromoText
                    ? beforePromoText
                    : translate("calculatorPage.QuotePage.from")
                }
                price={conditionsPrice()}
                priceLabel={translate("calculatorPage.textMonthly")}
                loading={loadingQuote}
              />
            </div>
          ) : (
            <>
              <div className="flex md:justify-center">
                <div className="relative w-fit">
                  <div className={`flex flex-col gap-[4px] w-fit transition-all md:items-center md:gap-[16px]`} style={{ opacity: loadingQuote ? 0 : 1 }}>
                    <span className={`font-normal text-[60px] leading-[60px] md:text-[80px] md:leading-[80px] tracking-[-0.02em]`}>{translate("calculator.price.notAvailable")}</span>
                  </div>
                  <span
                    className="absolute top-0 left-0 flex items-center justify-center w-full h-full pointer-events-none transition-all"
                    style={{ opacity: loadingQuote ? 1 : 0 }}
                  >
                    <Spinner />
                  </span>
                </div>
              </div>
            </>
          )
          }
          trustpilot={translate("calculatorPage.QuotePage.trustpilot")}
          actionLabel={translate("calculatorPage.QuotePage.actionLabel")}
          subContent={undefined}
        />
        <a href={`tel:${PHONE_NOT_COVER}`} ref={linkRefCall} />
      </section>
      <section className="QuotePage__cancelanytime">
        <div className="QuotePage__cancelanytime--slider">
          <Slider
            min={minCapital}
            max={maxCapital}
            step={GlobalCapitalStepper[DOMAIN! as LanguageT]}
            initial={parseInt(insuranceCover)}
            onValueChange={(value) => setInsuranceCover(value.toString())}
            onAfterChange={() => updatePrices(parseInt(insuranceCover))}
            capitalFormat={numberFormat}
            sliderLabel={translate("confirmationPage.resume.capital")}
          />
        </div>
        {hasTelesubscription && coverage !== "eg" && (
          <div className="QuotePage__cancelanytime--boxMaxCapital">
            <BoxColor
              title={translate("calculatorPage.QuotePage.maxCapital.title")}
              backgroundColor="#ECECFE"
              borderColor="var(--theme-primary)"
              colorTitle="var(--theme-secondary)"
              description={translate(
                "calculatorPage.QuotePage.maxCapital.description"
              )}
              icon="HeadsetIcon"
            />
          </div>
        )}
        <div className="QuotePage__cancelanytime--products">
          {!coverage && (
            <div className="QuotePage__loadingCoverages">
              <Spinner />
            </div>
          )}
          {coverage && coverage === "eg" && (
            <>
              <div className="w-full">
                <AdditionalProtection
                  title={translate("calculatorPage.mainCoverage")}
                  subtitle={translate("calculatorPage.seriousIllnesses") + ":"}
                  capital={numberFormat(parseInt(insuranceCover), "capital")}
                  initialValue={ipaToggle}
                  onValueChange={() => updateIpa(!ipaToggle)}
                  icon={
                    isMobile ? (
                      ""
                    ) : (
                      <GlobalIcon iconName="ShieldFilledIcon" size="M" />
                    )
                  }
                  included={true}
                  infoText={
                    <p className="flex flex-col gap-[16px] text-[#424242]">
                      {translate("upsell.policy.eg.modal.description")}
                    </p>
                  }
                  overlayTitle={
                    <Typography
                      text={translate("upsell.policy.eg.modal.title")}
                      variant="H3"
                      weight="700"
                      className="text-[#424242]"
                    />
                  }
                  overlayButtonLabel={translate("loginPageOtp.button.close")}
                  hideSwitch={true}
                />
              </div>
              <div className="w-full">
                <AdditionalProtection
                  title={translate("calculatorPage.additionalCoverage")}
                  subtitle={translate("calculatorPage.textCoverageDeath") + ":"}
                  capital={numberFormat(parseInt(insuranceCover), "capital")}
                  initialValue={ipaToggle}
                  onValueChange={() => updateIpa(!ipaToggle)}
                  icon={
                    isMobile ? (
                      ""
                    ) : (
                      <GlobalIcon iconName="Heart2Icon" filled="red" />
                    )
                  }
                  included={true}
                  infoText={
                    <p className="flex flex-col gap-[16px] text-[#424242]">
                      <GlobalIcon iconName="Heart2Icon" size="L" />
                      {translate("calculatorPage.textCoverageDeathInfo")}
                    </p>
                  }
                  overlayTitle={
                    <Typography
                      text={translate(
                        "newUpsell.resumeCart.decease.modalTitle"
                      )}
                      variant="H3"
                      weight="700"
                      className="text-[#424242]"
                    />
                  }
                  overlayButtonLabel={translate("loginPageOtp.button.close")}
                  hideSwitch={true}
                />
              </div>
            </>
          )}
          {coverage && coverage !== "eg" && (
            <>
              <div className="w-full">
                <AdditionalProtection
                  title={translate("calculatorPage.textCoverage")}
                  subtitle={translate("calculatorPage.textCoverageDeath")}
                  capital={
                    DOMAIN === "it"
                      ? numberFormat(parseInt(insuranceCover), "capital")
                      : undefined
                  }
                  initialValue={ipaToggle}
                  onValueChange={() => updateIpa(!ipaToggle)}
                  icon={
                    isMobile ? (
                      ""
                    ) : (
                      <GlobalIcon iconName="Heart2Icon" filled="red" />
                    )
                  }
                  included={true}
                  infoText={
                    <p className="flex flex-col gap-[16px] text-[#424242]">
                      <GlobalIcon iconName="Heart2Icon" size="L" />
                      {translate("calculatorPage.textCoverageDeathInfo")}
                    </p>
                  }
                  overlayTitle={
                    <Typography
                      text={translate(
                        "newUpsell.resumeCart.decease.modalTitle"
                      )}
                      variant="H3"
                      weight="700"
                      className="text-[#424242]"
                    />
                  }
                  overlayButtonLabel={translate("loginPageOtp.button.close")}
                  hideSwitch={true}
                />
              </div>
              <div className="w-full">
                <AdditionalProtection
                  title={translate("calculatorPage.textAdditionalProtection")}
                  subtitle={renderAddictionalProtection()}
                  capital={
                    DOMAIN === "it"
                      ? numberFormat(parseInt(insuranceCover) / 2, "capital")
                      : coverage === "eg"
                        ? numberFormat(parseInt(insuranceCover), "capital")
                        : undefined
                  }
                  initialValue={ipaToggle}
                  onValueChange={() => updateIpa(!ipaToggle)}
                  icon={
                    isMobile ? "" : <GlobalIcon iconName="ShieldFilledIcon" />
                  }
                  added={ipaToggle}
                  included={isAddictionalProtectionApplied()}
                  infoText={
                    <p className="flex flex-col gap-[16px] text-[#424242]">
                      <GlobalIcon
                        iconName={
                          coverage === "eg"
                            ? "UmbrellaIcon"
                            : GlobalIllnesIcon[DOMAIN! as LanguageT]
                        }
                        size="L"
                      />
                      {showGlobalIllnesContent()}
                    </p>
                  }
                  overlayTitle={
                    <Typography
                      text={showGlobalIllnesTitle()}
                      variant="H3"
                      weight="700"
                      className="text-[#424242]"
                    />
                  }
                  hideSwitch={hideIpaToogle()}
                  overlayButtonLabel={translate("loginPageOtp.button.close")}
                />
              </div>
            </>
          )}
        </div>
        {coverage === "eg" ? (
          <div className="QuotePage__seriousIllnesses">
            <h3 className="H3">
              {translate("calculatorPage.includedSeriousIllnesses")}
            </h3>
            <div className="QuotePage__seriousIllnessesList">
              {coveredSeriousIllnesses.map((illness, idx) => {
                return (
                  <div
                    key={`${illness}_${idx}`}
                    className="QuotePage__seriousIllnessesElement"
                  >
                    <GlobalIcon iconName="CircleCheckFilledIcon" size="XXXS" />
                    <span className="BodyM font-MW">{illness}</span>
                  </div>
                );
              })}
            </div>
          </div>
        ) : null}
        {downloadableFiles && (
          <div className="QuotePage__cancelanytime--downloadFiles">
            <TextWithInfoIcon
              content={translate("calculatorPage.downloadableFilesTitle")}
              infoIcon={true}
              weight="400"
              variant="BodyXSmall"
              color="secondarygrey"
              iconSize="XXXS"
              iconColor="#7B7B7B"
            />

            <DownloadableFiles
              files={downloadableFiles}
              className="md:!flex-row"
              loading={downloadableLoading}
              onClick={downloadDocument}
            />
          </div>
        )}
      </section>
      <section className="QuotePage__journeyquestion--cta">
        <CTABanner
          title={translate("calculatorPage.textQuestions")}
          subtitle={translate("calculatorPage.textHelp")}
          subtitleStyle="text-darkGray60"
          listPoints={listPoints(translate)}
          listStyle="text-darkGray80"
        />
      </section>

      <section className="QuotePage__buttons">
        <ButtonDS
          label={btnContractLabel}
          onClick={handleButtonQuote}
          rightIcon="ArrowRightIcon"
          className="QuotePage__buttons--primary"
        />
        <ButtonDS
          ghost
          buttonType="secondary"
          label={translate("back")}
          onClick={() => navigateBack()}
          leftIcon="ArrowBackIcon"
          className="QuotePage__buttons--secondary"
        />
      </section>
      <div
        className={`${isMobile ? "QuotePage__buttons--sticky" : "hidden"}`}
        style={{
          opacity: isStickyVisible ? 1 : 0,
        }}
      >
        {hasTelesubscription && coverage !== "eg" && (
          <ButtonDS
            buttonType="secondary"
            ghost
            label={translate(
              "calculatorPage.QuotePage.maxCapital.button.sticky"
            )}
            onClick={() => setFlagLink(true)}
          />
        )}
        <ButtonDS
          className="!w-full"
          label={btnContractLabel}
          onClick={handleButtonQuote}
        />
      </div>
    </main>
  );
};

export default QuotePage;
