import React from 'react';
import Sucess from './../../../assets/img/success.svg';
import './circleNumber.scss';

export default function CircleNumber(props) {
    const { content, completed, active } = props;

    return (
        <div
            className={
                active
                    ? 'circleNumber-mainContainer'
                    : 'circleNumber-mainContainerDisabled'
            }
        >
            {!completed && (
                <p style={!active ? { color: '#a0a0a0' } : { color: 'white' }}>
                    {content}
                </p>
            )}

            {completed && <img src={Sucess} alt="Completado" />}
        </div>
    );
}
// <div className='circleNumber-mainContainer'>
