export const PHONE_NOT_COVER = process.env.REACT_APP_TEL;

export const CALENDAR_MEET =
  "https://meetings-eu1.hubspot.com/meetings/equipo-de-ventas/capitales-elevados";

export const DOMAIN = process.env.REACT_APP_DOMAIN_LOCALE;

export const ENV = process.env.REACT_APP_ENVIROMENT;

export const PARSE_DOCS: any = (translate: any) => [{
  "offer-w-base": translate("downloadableFiles.conditionsPdf"),
  "ipa-any-cause-exclusions": translate("downloadableFiles.conditionsPdf"),
  "serious-diseases-exclusions": translate("downloadableFiles.conditionsPdf"),
  "legal-note": translate("downloadableFiles.notesPdf"),
  "ipid": translate("downloadableFiles.ipidPdf"),
  "devis": translate("downloadableFiles.devis"),
  "agl-budget": translate("downloadableFiles.aglBudget")
}]

export const GOOGLE_OAUTH_ID = process.env.REACT_APP_GOOGLE_OAUTH_ID;
