import { VariantType, WeightType } from "get-life-storybook-ts/lib/atoms/Typography/Typography";

export interface CustomWeCallYouStylesI {
  titleVariant: VariantType;
  titleWeight: WeightType;
  bodyTextVariant: VariantType;
  bodyTextWeight: WeightType;
}
export const customWeCallYouStyles: CustomWeCallYouStylesI = {
  titleVariant: "H3",
  titleWeight: "700",
  bodyTextVariant: "BodyMedium",
  bodyTextWeight: "500",
};
