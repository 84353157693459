import { ErrorResponse } from "get-life-storybook-ts/lib/components/Validations";
import React from "react";
import { validationsGlobal } from "../utils/validations";
import { I18nContext } from "./i18n.context";
import { useLocation } from "react-router";
import { AgeValidation } from "../utils/InternationlChanges";
import { LanguageT } from "./GetLifeContext.context";

interface FormBuilderContextI {
  disabled: boolean;
  formError: ErrorResponse | undefined;
  checkedConditions: boolean;
  setCheckedConditions: (value: boolean) => void;
}

export const FormBuilderContext = React.createContext(
  {} as FormBuilderContextI
);

const DOMAIN = process.env.REACT_APP_DOMAIN_LOCALE;

const MAX_WEIGHT = 250;
const MIN_WEIGHT = 25;
const MAX_HEIGHT = 220;
const MIN_HEIGHT = 120;
const MAX_AGE: number = AgeValidation[DOMAIN as LanguageT]["maxAge"];
const MIN_AGE = AgeValidation[DOMAIN as LanguageT]["minAge"];

export const validateType = [
  "height_question",
  "weight_question",
  "postal_code_question",
  "email_question",
  "phone_question",
  "dateOfBirth"
];

export const FormBuilderProvider = ({
  children,
  name,
  answer,
  question,
}: any) => {
  const [disabled, setDisabled] = React.useState<boolean>(true);
  const [checkedConditions, setCheckedConditions] = React.useState<boolean>(false)

  const [formError, setFormError] = React.useState<ErrorResponse>();

  const { componentName, type } = question;

  const {
    state: { translate },
  } = React.useContext(I18nContext);
  const location = useLocation();

  const componentValidation = () => {
    const newError: any = {
      height_question: validationsGlobal({
        min: MIN_HEIGHT,
        max: MAX_HEIGHT,
        field: "height",
        value: parseFloat(answer),
        translate,
      }),
      weight_question: validationsGlobal({
        min: MIN_WEIGHT,
        max: MAX_WEIGHT,
        field: "weight",
        value: parseFloat(answer),
        translate,
      }),
      postal_code_question: validationsGlobal({
        value: answer,
        translate,
        exactly: 5,
        field: "zipCode",
      }),
      email_question: validationsGlobal({
        field: "email",
        value: answer,
        translate
      }),
      phone_question: validationsGlobal({
        field: "phone",
        value: answer,
        translate,
      }),
      dateOfBirth: validationsGlobal({
        value: answer,
        translate,
        min: MIN_AGE,
        max: MAX_AGE,
        field: "dateOfBirth",
      }),

    };


    if ((componentName === "email_question" && !checkedConditions)) {
      setDisabled(true);
    } else {
      setDisabled(newError[componentName].error);
    }


    if (answer) {
      setFormError(newError[componentName]);
    }
  };

  const validations = () => {
    if (name === "idCard") {
      setDisabled(true);
    } else if (type === "beneficiaries") {
      if (DOMAIN === "it") {
        if (checkedConditions && answer) {
          setDisabled(false);
        } else {
          setDisabled(true);
        }
      } else {
        setDisabled(false);
      }
    }else if (
      componentName === "spain_nationality_question" ||
      type === "checkbox_optional"
    ) {
      setDisabled(false);
    } else if (!answer || answer.length === 0) {
      if (
        (name === "phone" &&
          location.pathname.includes("/prestackQuestions"))
      ) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    } else if (validateType.includes(componentName)) {
      componentValidation();
    } else {
      setDisabled(false);
    }
  };

  React.useEffect(() => {
    validations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, answer, checkedConditions]);
  return (
    <FormBuilderContext.Provider value={{ disabled, formError, checkedConditions, setCheckedConditions }}>
      {children}
    </FormBuilderContext.Provider>
  );
};
