import {
  CapitalStep,
  ContactStep,
  PersonalDataStep,
  RiskyJobsStep,
  SingleSelectStep,
} from "get-life-storybook-ts/lib/components/CalculatorSteps";
import CalculatorPageFRController from "./CalculatorPageFR.controller";
import QuotePage from "../QuotePage/QuotePage.component";
import PhoneValidationModal from "../../../components/PhoneValidationModal/PhoneValidationModal";

const CalculatorPageFR = () => {
  const { contextCalculator, handleClickOrganic, options, reorderDate } =
    CalculatorPageFRController();
  return (
    contextCalculator && (
      <>
        {contextCalculator.stepCalculator === "1" && (
          <PersonalDataStep
            dateOfBirth={reorderDate(contextCalculator.dateOfBirth)}
            disabledButton={
              contextCalculator.disabledButtons.dateOfBirth ||
              contextCalculator.disabledButtons.zipCode ||
              !contextCalculator.sex
            }
            handleClick={contextCalculator.handleClickPersonalData}
            invalidDateMessage={contextCalculator.translate("error.isNull")}
            initialErrorZipCode={contextCalculator.error}
            postcode={contextCalculator.postcode}
            setDateOfBirth={contextCalculator.setDateOfBirth}
            setPostcode={contextCalculator.setPostcode}
            setSex={contextCalculator.setSex}
            sex={contextCalculator.sex}
            textBtnContinue={contextCalculator.translate("continuar")}
            textDateInput={contextCalculator.translate(
              "calculatorPage.dateLabel"
            )}
            textDay={contextCalculator.translate("calculatorPage.textDay")}
            textMonth={contextCalculator.translate("calculatorPage.textMonth")}
            textSex={contextCalculator.translate("calculatorPage.sexLabel")}
            textSexF={contextCalculator.translate("calculatorPage.sexF")}
            textSexM={contextCalculator.translate("calculatorPage.sexM")}
            textYear={contextCalculator.translate("calculatorPage.textYear")}
            textZP={contextCalculator.translate("calculatorPage.zpLabel")}
            validations={contextCalculator.validations}
            initalErrorBirthDate={contextCalculator.errorBirthDate}
          />
        )}
        {contextCalculator.stepCalculator === "2" && (
          <SingleSelectStep
            back={contextCalculator.translate("calculatorPage.question.back")}
            disabledButton={contextCalculator.disabledNull(
              contextCalculator.smoke
            )}
            handleClick={contextCalculator.navigateTo}
            handleClickBack={contextCalculator.navigateBack}
            subtitle={contextCalculator.translate("calculatorPage.tooltip.smoke")}
            onValueChange={contextCalculator.setSmoke}
            options={options.smoke}
            title={contextCalculator.translate("calculatorPage.question.smoke")}
            textBtnContinue={contextCalculator.translate(
              "calculatorPage.question.continue"
            )}
          />
        )}
        {contextCalculator.stepCalculator === "3" && (
          <RiskyJobsStep
            back={contextCalculator.translate("calculatorPage.question.back")}
            handleClick={contextCalculator.navigateTo}
            handleClickBack={contextCalculator.navigateBack}
            disabledButton={contextCalculator.disabledNull(
              contextCalculator.job
            )}
            onValueChange={contextCalculator.setJob}
            options={contextCalculator.professions}
            query={contextCalculator.translate("calculatorPage.question.job")}
            textBtnContinue={contextCalculator.translate(
              "calculatorPage.question.continue"
            )}
            overlayButtonLabel={contextCalculator.translate(
              "productQuestions.overlay.button"
            )}
          />
        )}
        {contextCalculator.stepCalculator === "4" && (
          <SingleSelectStep
            back={contextCalculator.translate("calculatorPage.question.back")}
            handleClick={contextCalculator.navigateTo}
            handleClickBack={contextCalculator.navigateBack}
            disabledButton={contextCalculator.disabledNull(
              contextCalculator.workDistance
            )}
            onValueChange={(e: string) => {
              const value = {
                true: true,
                false: false
              }[e];
              contextCalculator.setWorkDistance(value as boolean);
            }}
            options={options.workDistance}
            title={contextCalculator.translate(
              "calculatorPage.question.workDistance"
            )}
            subtitle={contextCalculator.translate(
              "calculatorPage.supportText.workDistance"
            )}
            textBtnContinue={contextCalculator.translate(
              "calculatorPage.question.continue"
            )}
          />
        )}
        {contextCalculator.stepCalculator === "5" && (
          <SingleSelectStep
            back={contextCalculator.translate("calculatorPage.question.back")}
            handleClick={contextCalculator.navigateTo}
            handleClickBack={contextCalculator.navigateBack}
            disabledButton={contextCalculator.disabledNull(
              contextCalculator.workHeight
            )}
            onValueChange={(e: string) => {
              const value = {
                true: true,
                false: false
              }[e];
              contextCalculator.setWorkHeight(value as boolean);
            }}
            options={options.workHeight}
            title={contextCalculator.translate(
              "calculatorPage.question.workHeight"
            )}
            textBtnContinue={contextCalculator.translate(
              "calculatorPage.question.continue"
            )}
          />
        )}
        {contextCalculator.stepCalculator === "6" && (
          <SingleSelectStep
            back={contextCalculator.translate("calculatorPage.question.back")}
            disabledButton={contextCalculator.disabledNull(
              contextCalculator.workWeight
            )}
            handleClick={contextCalculator.navigateTo}
            handleClickBack={contextCalculator.navigateBack}
            onValueChange={(e: string) => {
              const value = {
                true: true,
                false: false
              }[e];
              contextCalculator.setWorkWeight(value as boolean);
            }}
            options={options.workWeight}
            title={contextCalculator.translate(
              "calculatorPage.question.workWeight"
            )}
            textBtnContinue={contextCalculator.translate(
              "calculatorPage.question.continue"
            )}
          />
        )}
        {contextCalculator.stepCalculator === "7" && (
          <CapitalStep
            TextOtherCapital={contextCalculator.translate(
              "calculatorPage.otherCapital"
            )}
            back={contextCalculator.translate("back")}
            backCapital={contextCalculator.translate(
              "calculatorPage.backCapital"
            )}
            disabledButton={contextCalculator.disabledButtons.capital}
            handleClick={contextCalculator.navigateTo}
            handleClickBack={contextCalculator.navigateBack}
            insuranceCover={contextCalculator.insuranceCover}
            maxCapital={contextCalculator.maxCapital}
            minCapital={contextCalculator.minCapital}
            otherCapitalBetween={contextCalculator.translate(
              "calculatorPage.otherCapitalBetween"
            )}
            overlayButtonLabel={contextCalculator.translate(
              "productQuestions.overlay.button"
            )}
            setInsuranceCover={contextCalculator.setInsuranceCover}
            subtextCapitalQuestion={contextCalculator.translate(
              "calculatorPage.subtextCapitalQuestion"
            )}
            textAnd={contextCalculator.translate(
              "aggregator.personalInformation.title3"
            )}
            textCapitalQuestion={contextCalculator.translate(
              "calculatorPage.capitalQuestion"
            )}
            textContinue={contextCalculator.translate("continuar")}
            validations={contextCalculator.validations}
            country="fr"
          />
        )}

        {contextCalculator.stepCalculator === "8" && (
          <>
            <ContactStep
              back={contextCalculator.translate("back")}
              btnGetPrice={contextCalculator.translate(
                "calculatorPage.btnGetPrice"
              )}
              checkedConditions={contextCalculator.checkedConditions}
              disabledButton={
                contextCalculator.disabledButtons.phone ||
                contextCalculator.disabledButtons.email ||
                !contextCalculator.checkedConditions ||
                !contextCalculator.checkedConditions2
              }
              email={contextCalculator.email}
              handleClick={handleClickOrganic}
              handleClickBack={contextCalculator.navigateBack}
              initialErrorPhoneNumber={contextCalculator.error}
              initialErrorEmail={contextCalculator.errorEmail}
              phoneNumber={contextCalculator.phoneNumber!}
              setCheckedConditions={contextCalculator.handleCheckConditions}
              setEmail={contextCalculator.setEmail}
              setPhoneNumber={contextCalculator.setPhoneNumber}
              setUrl={contextCalculator.setUrl}
              textAcceptDataProcessing={contextCalculator.translate(
                "calculatorPage.acceptDataProcessing"
              )}
              textEmailQuestion={contextCalculator.translate(
                "calculatorPage.emailQuestion"
              )}
              textPhoneQuestion={contextCalculator.translate(
                "calculatorPage.phoneQuestion"
              )}
              validations={contextCalculator.validations}
              secondCheck={true}
              textAcceptDataProcessing2={contextCalculator.translate(
                "calculatorPage.acceptDataProcessing2"
              )}
              setCheckedConditions2={contextCalculator.handleCheckConditions2}
            />
            <PhoneValidationModal
              open={contextCalculator.phoneValidationModal}
              setOpen={contextCalculator.setPhoneValidationModal}
              phoneValue={contextCalculator.phoneNumber}
              PhoneOnValueChange={(e: any) => contextCalculator.setPhoneNumber(e)}
              phoneError={contextCalculator.error}
              GeneratePhoneValidationCode={contextCalculator.RegeneratePhoneValidationCode}
              ValidatePhoneValidationCode={contextCalculator.ValidatePhoneValidationCode}
            />
          </>
        )}
        {contextCalculator.stepCalculator === "9" && (
          <QuotePage />
        )}
      </>
    )
  );
};

export default CalculatorPageFR;
