/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  GetlifeContext,
  LanguageT,
} from "../../../../../../context/GetLifeContext.context";
import { I18nContext } from "../../../../../../context/i18n.context";
import HttpCheckoutRepository from "../../../../../../api/request/Checkout/Checkout.service";
import HttpSignatureRepository from "../../../../../../api/request/Signature/Signature.service";
import useRecursiveTimeout from "../../../../../../hooks/useRecursiveTimeout";
import ErrorResponse from "../../../../../../api/core/ErrorResponse";
import { ResponseStatus } from "../../../../../../api/core/ResponseStatus";
import HttpDocumentsRepository from "../../../../../../api/request/Documents/Documents.service";

type SignTextI = {
  [k in LanguageT]: { email: string; phone: string };
};

export const signText: SignTextI = {
  es: {
    email: "ayuda@life5.com",
    phone: process.env.REACT_APP_TEL_POST!,
  },
  fr: {
    email: "support@life5.com",
    phone: process.env.REACT_APP_TEL_POST!,
  },
  en: {
    email: "ayuda@life5.com",
    phone: process.env.REACT_APP_TEL_POST!,
  },
  pt: {
    email: "ayuda@life5.com",
    phone: process.env.REACT_APP_TEL_POST!,
  },
  it: {
    email: "supporto@life5.it",
    phone: process.env.REACT_APP_TEL_POST!,
  },
  wl_es: {
    email: "ayuda@life5.com",
    phone: process.env.REACT_APP_TEL_POST!,
  },
  sql_es: {
    email: "ayuda@life5.com",
    phone: process.env.REACT_APP_TEL_POST!,
  },
};

const CheckoutSignController = () => {
  const navigate = useNavigate();
  const [signedStatus, setSignedStatus] = useState(false);
  const [contractStatus, setContractStatus] = useState("");
  const { token, leadId, setLoading, domain, loading } =
    useContext(GetlifeContext);
  const {
    state: { translate },
  } = useContext(I18nContext);
  const [iframeUrl, setIframeUrl] = useState("");
  const [isSignaturit, setIsSignaturit] = useState<boolean>(false);

  const documentsRepository = new HttpDocumentsRepository(leadId, token);
  const checkoutRepository = new HttpCheckoutRepository(leadId, token);
  const signatureRepository = new HttpSignatureRepository(leadId, token);

  const fetchCreateContract = async () => {
    try {
      await signatureRepository.postCreateContract();
      await recursiveRequestFunction();
    } catch (error) {
      if ((error as ErrorResponse).status === ResponseStatus.CONFLICT)
        navigate("/waitingsignature?signed=0");
    }
  };

  const recursiveRequestFunction = async () => {
    try {
      const response = await signatureRepository.getSignatureEmbedded();
      if (response.url) {
        setIframeUrl(response.url);
        if (response.url.includes("signaturit")) {
          setIsSignaturit(true);
        }
        return response.url;
      } else {
        throw new Error();
      }
    } catch (error: any) {
      if (error.status === 422) {
        navigate("/agent-confirmation");
      }
      setTimeout(async () => recursiveRequestFunction(), 5000);
    }

    return;
  };

  useEffect(() => {
    (async () => {
      await fetchCreateContract();
    })();
  }, []);

  const downloadContract = async () => {
    await documentsRepository
      .downloadDocument("unsigned-contract")
      .then((res) => {
        window.open(res.documentUrl, "_blank");
      });
  };

  const fetchSignStatus = async () => {
    try {
      const res = await signatureRepository.getSignStatus();
      setContractStatus(res.status);
      if (res.status === "SIGNED") {
        setSignedStatus(true);
      }
      if (res.status === "NEGLECTED") {
        navigate("/agent-confirmation");
      }
    } catch (error) {
      console.log("Error in apiGetSignStatus", error);
    }
  };

  const handleOnSignDelayedButtonClicked = () => {
    checkoutRepository.finishWaitingSignature().then(() => {
      navigate("/waitingsignature?signed=0");
    });
  };

  // Consulta de status cada 10 segundos.
  useRecursiveTimeout(async () => {
    await fetchSignStatus();
  }, 10000);

  useEffect(() => {
    if (signedStatus === true) {
      checkoutRepository.finishSigned().then(() => {
        navigate("/waitingsignature?signed=1");
      });
    }
  }, [leadId, token, signedStatus]);

  useEffect(() => {
    if (iframeUrl && loading) {
      setLoading(false);
    }
  }, [iframeUrl]);

  return {
    contractStatus,
    domain,
    downloadContract,
    handleOnSignDelayedButtonClicked,
    iframeUrl,
    isSignaturit,
    signText,
    translate,
  };
};

export default CheckoutSignController;
