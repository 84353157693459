function open() {
    window.HubSpotConversations.widget.open();
}

function refresh() {
    window.HubSpotConversations.widget.refresh();
}

function handleHubspot() {
    if (window.HubSpotConversations) {
        refresh();
    } else {
        window.hsConversationsOnReady = [refresh];
    }
}

function openHubspot() {
    if (window.HubSpotConversations) {
        open();
    } else {
        window.hsConversationsOnReady = [open];
    }
}

export { openHubspot, handleHubspot };
