import { useContext } from "react";
import {
  CustomModal,
  Typography,
} from "get-life-storybook-ts";
import { I18nContext } from "../../context/i18n.context";
import { GetlifeContext } from "../../context/GetLifeContext.context";
import { customWeCallYouStyles } from "../../constants/CustomStyles/WeCallYou";
import { ButtonsModalI } from "get-life-storybook-ts/lib/organisms/Modal/CustomModal";
import numberFormat from "../../utils/Utils";

interface BodyPartI {
  translate: (key: string) => string
  capital?: number
}

interface ModalPropsI {
  capital: number
}

const ModalTitle = ({translate} : BodyPartI) => (
  <div className="flex items-center gap-2">
    <Typography
      variant={customWeCallYouStyles.titleVariant}
      weight={customWeCallYouStyles.titleWeight}
      text={translate("maxCapital.modal.title")}
    />
  </div>)

const ModalBody = ({
  translate,capital
}: BodyPartI
) => (
  <>
    <Typography
      variant={customWeCallYouStyles.bodyTextVariant}
      weight={customWeCallYouStyles.bodyTextWeight}
      text={translate("maxCapital.modal.body").replace(
        "$",
        numberFormat(capital!, "capital").toString() || ""
      )}
      className="pb-[16px]"
    />
  </>
);

const MaxCapitalModal = ({capital}: ModalPropsI) => {
  const { state: { translate } } = useContext(I18nContext)
  const { nodeMaxCapitalModal } = useContext(GetlifeContext)

  const ModalButton: ButtonsModalI = {
    showBoth: false,
    preventClose: true,
    buttonInfo: {
      primary: {
        label: translate("maxCapital.modal.button"),
        onClick: () => nodeMaxCapitalModal.current?.handleClosePortal(),
      },
    }
  }

  return (
    <CustomModal 
      ref={nodeMaxCapitalModal}
      ModalTitle={<ModalTitle translate={translate}/>}
      ModalBody={<ModalBody translate={translate} capital={capital}/>}
      ModalActions={ModalButton}
    />
  )
};

export default MaxCapitalModal;
