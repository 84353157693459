import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./css/loginPage.scss";
import { GetlifeContext } from "../../context/GetLifeContext.context";
import { TextInput, ButtonDS, Typography } from "get-life-storybook-ts";
import {
  LOGIN_STATUS_SIGNATUREPENDING,
  LOGIN_STATUS_COMPLETE,
  LOGIN_STATUS_OPS_REVIEW,
  LOGIN_STATUS_IT_REVIEW,
  LOGIN_STATUS_INSURER_REVIEW,
} from "../../utils/staticData";
import { I18nContext } from "../../context/i18n.context";
import HttpLeadRepository from "../../api/request/Lead/Lead.service";
import { GetLifeTrackerContext } from "../../context/GetLifeTracker.context";
import { getTrackingUtms } from "../../api/request/apiLeadTrackingGet";
import { redirect } from "../../Routes/redirect";
import { GoogleLogin } from '@react-oauth/google';
import { DOMAIN, GOOGLE_OAUTH_ID } from "../../constants/Global";
import { jwtDecode } from "jwt-decode";
import { getCookie } from "../../hooks/getCookie";

export default function LoginOtpPage(props) {
  const [userEmail, setUserEmail] = useState("");
  const [emailDomain, setEmailDomain] = useState("");
  const [domainUrl, setDomainUrl] = useState("");

  const [emailSent, setEmailSent] = useState(false);

  const navigate = useNavigate();

  const {
    init,
    setLeadId,
    setBrokerId,
    setLoading,
    setProgressBar,
    setToken,
    setUserStatus,
    gtf_campaign,
    getEntryPage,
    getReferrerUrl,
    saveNewFbclid,
    saveNewGclid,
    saveNewMsclkid,
    setToast,
    setToastIsOpen,
    setThemeColor,
    setLogo,
    setBrokerPhone,
    setBrokerSchedule,
    DOMAIN
  } = useContext(GetlifeContext);

  const {
    state: { translate },
  } = React.useContext(I18nContext);

  const { handleTrackerQuestion, identifyFS } = React.useContext(
    GetLifeTrackerContext
  );

  const leadRepository = new HttpLeadRepository();

  const location = useLocation();
  const path = location.pathname.split("/");

  const statusProvided = (userStatus, locationUrl) => {
    if (userStatus) {
      if (
        userStatus !== LOGIN_STATUS_SIGNATUREPENDING &&
        userStatus !== LOGIN_STATUS_COMPLETE &&
        userStatus !== LOGIN_STATUS_OPS_REVIEW &&
        userStatus !== LOGIN_STATUS_IT_REVIEW &&
        userStatus !== LOGIN_STATUS_INSURER_REVIEW
      ) {
        navigate("/decision");
      } else {
        navigate(`${redirect(userStatus)}${locationUrl && locationUrl}`);
      }
    }
  }

  const prepareUrlDomain = (domain) => {
    const emails = {
      gmail: {
        name: "Gmail",
        url: "https://mail.google.com/",
      },
      googlemail: {
        name: "Gmail",
        url: "https://mail.google.com/",
      },
      yahoo: {
        name: "Yahoo",
        url: "https://mail.yahoo.com",
      },
      msn: {
        name: "Outlook",
        url: "https://go.microsoft.com/fwlink/p/",
      },
      live: {
        name: "Outlook",
        url: "https://go.microsoft.com/fwlink/p/",
      },
      outlook: {
        name: "Outlook",
        url: "https://go.microsoft.com/fwlink/p/",
      },
      hotmail: {
        name: "Hotmail",
        url: "https://go.microsoft.com/fwlink/p/",
      },
      getlife: {
        name: "Gmail",
        url: "https://mail.google.com/",
      },
    };
    if (emails[domain]) {
      setDomainUrl(emails[domain]["url"]);
      setEmailDomain(emails[domain]["name"]);
    }
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    let domain = await userEmail.split("@")[1];
    domain = await domain.split(".")[0];

    leadRepository.loginOtp(userEmail).then((r) => {
      const event = "OTPRequested";

      const dataInfo = {
        email: userEmail,
        leadId: r.leadId,
        page: window.location.href,
        utm_campaign: getCookie("utm_campaign"),
        utm_source: getCookie("utm_source"),
        utm_medium: getCookie("utm_medium"),
        utm_content: getCookie("utm_content"),
        utm_term: getCookie("utm_term"),
        utm_test: getCookie("utm_test"),
        gclid: getCookie("gclid"),
        fbclid: getCookie("fbclid"),
        tid: getCookie("tid"),
        platform: "App",
        entryPage: getEntryPage(),
        referrerUrl: getReferrerUrl(),
      };

      handleTrackerQuestion({
        type: "FS",
        event,
        data: dataInfo,
      });

      handleTrackerQuestion({
        type: "GA",
        data: {
          event,
          ...dataInfo,
        },
      });

      prepareUrlDomain(domain);
      setEmailSent(true);
    });
  };

  const fetchLogin = async (data) => {
    try {
      const response = await leadRepository.login(data);
      if (response === "RQ not valid") {
        throw new Error();
      }
      const { token, id, status, email, brokerId, themeColor, logo, brokerPhone, brokerSchedule } = response;

      const utms = await getTrackingUtms(id);

      if (utms.fbclid) saveNewFbclid(utms.fbclid);

      if (utms.gclid) saveNewGclid(utms.gclid);

      if (utms.msclkid) saveNewMsclkid(utms.msclkid);

      handleTrackerQuestion({
        type: "tracker",
        data: {
          leadUlid: id,
          campaign: gtf_campaign || "login-app",
        },
      });

      identifyFS({
        lead: id,
        data: {
          emailDomain: email && email.split("@")[1],
          email: email,
          brokerId: brokerId,
        },
      });

      setToken(token || "");
      setLeadId(id || "");
      setBrokerId(brokerId);
      setUserStatus(status);
      setLoading(false);
      statusProvided(status, location.search);
      setThemeColor(themeColor)
      setLogo(logo)
      if (DOMAIN === "sql_es") {
        setBrokerPhone(brokerPhone)
        setBrokerSchedule(brokerSchedule)
      }



      const event = "OTPLogin";

      const dataInfo = {
        email: email,
        leadId: id,
        page: window.location.href,
        campaign: gtf_campaign,
        utm_campaign: getCookie("utm_campaign"),
        utm_source: getCookie("utm_source"),
        utm_medium: getCookie("utm_medium"),
        utm_content: getCookie("utm_content"),
        utm_term: getCookie("utm_term"),
        utm_test: getCookie("utm_test"),
        gclid: getCookie("gclid"),
        fbclid: getCookie("fbclid"),
        tid: getCookie("tid"),
        platform: "App",
        entryPage: getEntryPage(),
        referrerUrl: getReferrerUrl(),
      };

      handleTrackerQuestion({
        type: "FS",
        event,
        data: dataInfo,
      });

      handleTrackerQuestion({
        type: "GA",
        data: {
          event,
          ...dataInfo,
        },
      });

      if (
        status !== LOGIN_STATUS_SIGNATUREPENDING &&
        status !== LOGIN_STATUS_COMPLETE &&
        status !== LOGIN_STATUS_OPS_REVIEW &&
        status !== LOGIN_STATUS_IT_REVIEW &&
        status !== LOGIN_STATUS_INSURER_REVIEW
      ) {
        navigate("/decision");
      } else {
        navigate(redirect(status) + location.search);
      }
    } catch (error) {
      init("catch_login_otp");
      navigate("/otpDisabled");
      setLoading(false);
    }
  };

  useEffect(() => {
    setProgressBar(false);
    (async () => {
      if (path.includes("login") && path[2]) {
        setLoading(true);
        const data = {
          otp: path[2],
        };

        await fetchLogin(data);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEmail = (e) => setUserEmail(e);

  const GoogleLoginOnSuccess = (credentialResponse) => {
    setLoading(true);

    const decoded = jwtDecode(credentialResponse.credential);

    if (decoded?.email) {
      const event = "SSORequested";

      const dataInfo = {
        email: decoded.email,
        leadId: null,
        page: window.location.href,
        campaign: gtf_campaign,
        utm_campaign: getCookie("utm_campaign"),
        utm_source: getCookie("utm_source"),
        utm_medium: getCookie("utm_medium"),
        utm_content: getCookie("utm_content"),
        utm_term: getCookie("utm_term"),
        utm_test: getCookie("utm_test"),
        gclid: getCookie("gclid"),
        fbclid: getCookie("fbclid"),
        tid: getCookie("tid"),
        platform: "App",
        entryPage: getEntryPage(),
        referrerUrl: getReferrerUrl(),
      };

      handleTrackerQuestion({
        type: "FS",
        event,
        data: dataInfo,
      });

      handleTrackerQuestion({
        type: "GA",
        data: {
          event,
          ...dataInfo,
        },
      });
    }

    leadRepository
      .GoogleSSO(credentialResponse.credential)
      .then(async (response) => {
        const { token, id, status, email, brokerId } = response;
        setToken(token || "");
        setLeadId(id || "");
        setUserStatus(status);
        setBrokerId(brokerId);

        const utms = await getTrackingUtms(id);

        if (utms.fbclid) saveNewFbclid(utms.fbclid);
        if (utms.gclid) saveNewGclid(utms.gclid);

        handleTrackerQuestion({
          type: "tracker",
          data: {
            leadUlid: id,
            campaign: gtf_campaign || "login-app",
          },
        });

        identifyFS({
          lead: id,
          data: {
            emailDomain: email && email.split("@")[1],
            email: email,
            brokerId: undefined,
          },
        });

        const event = "SSOLogin";

        const dataInfo = {
          email: email,
          leadId: id,
          page: window.location.href,
          campaign: gtf_campaign,
          utm_campaign: getCookie("utm_campaign"),
          utm_source: getCookie("utm_source"),
          utm_medium: getCookie("utm_medium"),
          utm_content: getCookie("utm_content"),
          utm_term: getCookie("utm_term"),
          utm_test: getCookie("utm_test"),
          gclid: getCookie("gclid"),
          fbclid: getCookie("fbclid"),
          tid: getCookie("tid"),
          platform: "App",
          entryPage: getEntryPage(),
          referrerUrl: getReferrerUrl(),
        };

        handleTrackerQuestion({
          type: "FS",
          event,
          data: dataInfo,
        });

        handleTrackerQuestion({
          type: "GA",
          data: {
            event,
            ...dataInfo,
          },
        });

        if (
          status !== LOGIN_STATUS_SIGNATUREPENDING &&
          status !== LOGIN_STATUS_COMPLETE &&
          status !== LOGIN_STATUS_OPS_REVIEW &&
          status !== LOGIN_STATUS_IT_REVIEW &&
          status !== LOGIN_STATUS_INSURER_REVIEW
        ) {
          navigate("/decision");
        } else {
          navigate(redirect(status) + location.search);
        }
      })
      .catch((error) => {
        const { status } = error;

        if (status === 401) {
          setToast({
            onClose: () => setToastIsOpen(false),
            title: translate("loginPageOtp.googleSSONotAuthorized.title"),
            message: translate("loginPageOtp.googleSSONotAuthorized.message"),
            state: "normal",
            autoCloseIn: 3000,
          });
          setToastIsOpen(true);
        }
        if (status === 500) {
          setToast({
            onClose: () => setToastIsOpen(false),
            title: translate("loginPageOtp.googleSSOServerError.title"),
            message: translate("loginPageOtp.googleSSOServerError.message"),
            state: "warning",
            autoCloseIn: 3000,
          });
          setToastIsOpen(true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const GoogleLoginOnError = () => {
    setToast({
      onClose: () => setToastIsOpen(false),
      title: translate("loginPageOtp.googleSSOError.title"),
      message: translate("loginPageOtp.googleSSOError.message"),
      state: "error",
      autoCloseIn: 3000,
    });
    setToastIsOpen(true);
  };

  return (
    <div className="loginPage">
      {!emailSent ? (
        <main className="loginPage--main--otp">
          <h1 className="loginPage--main__title">
            {translate("loginPageOtp.title")}
          </h1>
          {GOOGLE_OAUTH_ID ? (
            <h6
              className="loginPage--main__subtitle"
              dangerouslySetInnerHTML={{
                __html: translate("loginPageOtp.googleSSO.subtitle"),
              }}
            />
          ) : (
            <h6 className="loginPage--main__subtitle">
              {translate("loginPageOtp.subtitle1")}
              <span>{translate("loginPageOtp.subtitle2")}</span>
              {translate("loginPageOtp.subtitle3")}
              <span>{translate("loginPageOtp.subtitle4")}</span>.
            </h6>
          )}
          {GOOGLE_OAUTH_ID ? (
            <>
              <div className="loginPage--googleSSO">
                <GoogleLogin
                  onSuccess={GoogleLoginOnSuccess}
                  onError={GoogleLoginOnError}
                  locale={DOMAIN}
                  size="large"
                  type="standard"
                  text="continue_with"
                  shape="pill"
                  width="100%"
                />
              </div>
              <div className="loginPage--divider">
                <Typography
                  variant="Span"
                  text={translate("loginPageOtp.googleSSO.continue")}
                  weight="500"
                />
              </div>
            </>
          ) : null}
          <form className="loginPage--main__form">
            <div className="otp--form__input">
              <div className="otp--form__input">
                <TextInput
                  label="E-mail"
                  initialValue={userEmail}
                  onValueChange={handleEmail}
                />
              </div>
            </div>
            <div className="form--btn">
              <ButtonDS
                label={translate("loginPageOtp.btnEnviar")}
                disabled={userEmail === ""}
                onClick={handleSubmit}
              />
            </div>
          </form>
        </main>
      ) : (
        <main className="changePassword--main">
          <div className="changePassword--main__imgElipse">
            <img
              src="https://storage.googleapis.com/getlife-bucket-1/public/image/v2/email_icon.png"
              alt="Logo de Getlife"
              title="Logo de Getlife"
              className="changePassword--main__img"
            />
          </div>
          <h1 className="changePassword--main__title">
            {translate("loginPageOtp.changePassword.title")}
          </h1>
          {emailDomain ? (
            <>
              <h6 className="changePassword--main__subtitle">
                <span>
                  {translate("loginPageOtp.changePassword.subtitle1")}
                </span>
                <a href={domainUrl} target="_blank" rel="noreferrer">
                  {emailDomain}
                </a>
                <span>
                  {translate("loginPageOtp.changePassword.subtitle2")}
                </span>
              </h6>
              <h6 className="changePassword--main__subtitle">
                <span>
                  {translate("loginPageOtp.changePassword.subtitle3")}
                </span>
              </h6>
            </>
          ) : (
            <h6 className="changePassword--main__subtitle">
              <span>{translate("loginPageOtp.changePassword.subtitle0")}</span>
            </h6>
          )}
        </main>
      )}
    </div>
  );
}
