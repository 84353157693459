import React from 'react';
import './../../../components/Components/modal/customModal.scss';
import { Dialog, Slide } from '@material-ui/core';

import { Close } from '@material-ui/icons';
import { ButtonDS } from 'get-life-storybook-ts';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function BlockersPopUP(props) {

    const body = (
        <div className="blockers--container">
            <Close
                className="customModal-close"
                onClick={() => props.handleModal()}
            />
            <h1 className="blockersPopUp-contentExclusion">Se excluye:</h1>
            <h3 className="blockersPopUp-contentProps">
                {props.content ? props.content : null}
            </h3>

            <div className="customModal-buttonContainer">
                {props.buttonSecondary === true && (
                    <ButtonDS
                        buttonType='secondary'
                        onClick={props.buttonSecondarySubmit}
                        label={props.buttonSecondaryContent}
                        className='w-full'
                    />
                )}
                <ButtonDS
                    label={props.buttonPrimaryContent}
                    onClick={props.buttonPrimarySubmit}
                    className='w-full'
                />
            </div>
        </div>
    );

    return (
        <div>
            <Dialog
                fullWidth={false}
                maxWidth={'lg'}
                open={props.open}
                onClose={props.close}
                TransitionComponent={Transition}
            >
                {body}
            </Dialog>
        </div>
    );
}
