/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useRef, useState } from "react";
import HttpLeadRepository from "../../../api/request/Lead/Lead.service";
import {
  GetlifeContext,
  LanguageT,
  useQuery,
} from "../../../context/GetLifeContext.context";
import { useNavigate } from "react-router";
import { GetLifeTrackerContext } from "../../../context/GetLifeTracker.context";
import { GetLifeCalculatorContext } from "../../../context/GetLifeCalculator.context";
import {
  GlobalAllowTelesubscription,
  GlobalIllnes,
  GlobalIllnesContent,
  GlobalIllnesTitle,
  GlobalOnlyPremium,
  GlobalPromoCodesToApply,
} from "../../../utils/InternationlChanges";
import { DataPublicI } from "../../../api/request/Lead/Interfaces/DataInterface.response";
import { I18nContext } from "../../../context/i18n.context";
import { CALENDAR_MEET, DOMAIN, PARSE_DOCS } from "../../../constants/Global";
import { useDevice } from "get-life-storybook-ts";
import { CoverageT } from "../../../api/request/Lead/Interfaces/ProductInterface.response";
import { InsuranceCalculateDataI } from "../../../api/request/Lead/Interfaces/InsuranceCalculate";
import { isInViewport, parsePriceDecimal } from "../../../utils/Utils";
import { DownloadableFilesT } from "get-life-storybook-ts/lib/components/DownloadableFiles/DownloadableFiles";
import HttpDocumentsRepository from "../../../api/request/Documents/Documents.service";
import HttpCouponsRepository from "../../../api/request/Coupons/Coupons.service";
import { getCookie } from "../../../hooks/getCookie";

const QuotePageController = () => {
  const {
    init,
    leadId,
    setBrokerId,
    setLeadId,
    setToken,
    token,
    setThemeColor,
    setLogo,
  } = useContext(GetlifeContext);
  const { handleTrackerQuestion, identifyFS } = useContext(
    GetLifeTrackerContext
  );
  const {
    state: { translate },
  } = useContext(I18nContext);
  const { navigateBack } = useContext(GetLifeCalculatorContext);

  let query = useQuery();
  const { isMobile } = useDevice();
  const navigate = useNavigate();
  const lead = query.get("lead");

  const [basicFrom, setBasicFrom] = useState<number>(0);
  const [egPrice, setEgPrice] = useState<number>(0);
  const [coverage, setCoverage] = useState<string>("");
  const [loadingQuote, setLoadingQuote] = useState<boolean>(true);
  const [insuranceCover, setInsuranceCover] = useState<string>("");
  const [intention, setIntention] = useState<string>("");
  const [ipaToggle, setIpaToggle] = useState<boolean>(true);
  const [isCouponApplied, setIsCouponApplied] = useState<boolean>(false);
  const [maxCapital, setMaxCapital] = useState<number>(0);
  const [minCapital, setMinCapital] = useState<number>(0);
  const [premiumFrom, setPremiumFrom] = useState<number>(0);
  const [pricePromotion, setPricePromotion] = useState<any>();
  const [afterPromoText, setAfterPromoText] = useState<string>("");
  const [beforePromoText, setBeforePromoText] = useState<string>("");
  const [hasTelesubscription, setHasTelesubscription] =
    useState<boolean>(false);
  const [btnContractLabel, setBtnContractLabel] = useState<string>(
    translate("calculatorPage.question.continue")
  );
  const [downloadableFiles, setDownloadableFiles] =
    useState<DownloadableFilesT>();
  const [downloadableLoading, setDownloadableLoading] =
    useState<boolean>(false);
  const [flagLink, setFlagLink] = useState<boolean>(false);
  const [isStickyVisible, setIsStickyVisible] = useState<Boolean>(false);
  const linkRefCall = useRef<any>();

  const leadRepository = new HttpLeadRepository(leadId, token);
  const couponsRepository = new HttpCouponsRepository(leadId, token);

  const getToken = () => {
    leadRepository.getToken(lead || leadId).then((res) => {
      const { token, brokerId, email } = res;

      if (!token) {
        init("no_token", false);
        navigate("/login");
      } else {
        setToken(token);
        setBrokerId(brokerId);
        identifyFS({
          lead: leadId,
          data: {
            emailDomain: email?.split("@")[1],
            email: email,
            brokerId: brokerId,
          },
        });
      }
    });
  };

  useEffect(() => {
    if (lead && !token) getToken();
  }, []);

  const conditionsPrice = (decimal?: string) => {

    if (pricePromotion) {
      return parsePriceDecimal(pricePromotion, decimal && "decimal");
    }
    if (coverage === "premium") {
      return parsePriceDecimal(premiumFrom, decimal && "decimal");
    }

    if (coverage === "eg") {
      return parsePriceDecimal(egPrice, decimal && "decimal");
    }
    return parsePriceDecimal(basicFrom, decimal && "decimal");
  };

  const documentsRepository = new HttpDocumentsRepository(leadId, token);

  const getAvailablesDocuments = async (lead: string) => {
    documentsRepository.getAvailablesDocuments(lead).then((resp) => {
      let allDocs: DownloadableFilesT = [
        {
          label: "",
          type: "",
        },
      ];
      resp.forEach((element: string) => {
        allDocs.push({
          label: PARSE_DOCS(translate)[0][element],
          type: element,
        });
      });

      setDownloadableFiles(allDocs!);
    });
  };

  const downloadDocument = async (type: string) => {
    documentsRepository.downloadDocument(type).then((res) => {
      window.open(res.documentUrl, "_blank");
    });
  };

  useEffect(() => {
    if (token && lead) getAvailablesDocuments(lead);
  }, [token, lead]);

  const prepareLeadForQuestions = (route: string) => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    navigate(route);
  };

  const navigateToQuestions = () => {
    prepareLeadForQuestions("/productQuestions");
  };

  const handleButtonQuote = () => {
    if (
      hasTelesubscription &&
      GlobalAllowTelesubscription[DOMAIN! as LanguageT]
    ) {
      window.open(CALENDAR_MEET);
    } else {
      navigateToQuestions();
    }
  };

  const getBtnContractButtonLabel = (telesubscription: boolean) => {
    if (telesubscription) {
      setBtnContractLabel(
        translate(translate("telesubscription.button.calendar"))
      );
    } else {
      setBtnContractLabel(translate("calculatorPage.question.continue"));
    }
  };

  const updatePrices = (price: number) => {
    setLoadingQuote(true);
    leadRepository
      .getTarrif({ capital: price, insuranceType: coverage as CoverageT })
      .then((data: InsuranceCalculateDataI) => {
        fetQuoteData(lead || leadId);
      });
  };

  const updateIpa = (ipa: boolean) => {
    setIpaToggle(ipa);
    setLoadingQuote(true);
    let NewIpa = ipa ? "premium" : "basic";
    leadRepository
      .getTarrif({
        capital: parseFloat(insuranceCover),
        insuranceType: NewIpa as CoverageT,
      })
      .then((data: any) => {
        getAvailablesDocuments(lead!);
        setBasicFrom(data.basicPrice);
        setEgPrice(data.egPrice);
        setPremiumFrom(data.premiumPrice);
        setInsuranceCover(data.capital);
        setCoverage(ipa ? "premium" : "basic");
        setHasTelesubscription(data.undeterminedTarification);
        getBtnContractButtonLabel(data.undeterminedTarification);
        leadRepository.getQuoteData(leadId).then((leadData) => {
          setThemeColor(leadData.themeColor);
          setLogo(leadData.logo);
          setAfterPromoText(leadData.afterPromoText ?? "");
          setBeforePromoText(leadData.beforePromoText ?? "");
          setLoadingQuote(false);
        });
      });
  };

  const fetchApplyCoupon = async (leadId: string) => {
    const gtf_campaign = getCookie("gtf_campaign");
    try {
      await couponsRepository.applyCouponQuote({
        code: (gtf_campaign || GlobalPromoCodesToApply[DOMAIN as LanguageT])!,
        leadId: leadId,
      });
      handleTrackerQuestion({
        type: "FS",
        event: `appliedCoupon`,
        data: {
          promotionalCode:
            gtf_campaign || GlobalPromoCodesToApply[DOMAIN as LanguageT],
          leadId: lead,
          market: DOMAIN,
        },
      });
      setIsCouponApplied(true);
    } catch (error) {
      fetQuoteData(lead!);
    }
  };

  const fetQuoteData = async (newLead: string) => {
    try {
      setLoadingQuote(true);
      const response: DataPublicI = await leadRepository.getQuoteData(newLead);
      setThemeColor(response.themeColor);
      setLogo(response.logo);
      setLeadId(newLead);
      setHasTelesubscription(response.hasTelesubscription);
      getBtnContractButtonLabel(response.hasTelesubscription);
      setMinCapital(response.minCapital);
      setMaxCapital(response.maxCapital);
      setInsuranceCover(response.capital.toString());
      setCoverage(response.coverage);
      setIpaToggle(response.coverage === "basic" ? false : true);
      setIntention(response.intention);
      setBasicFrom(response.basicPrice);
      setEgPrice(response.egPrice);
      setPremiumFrom(response.premiumPrice);
      setAfterPromoText(response.afterPromoText ?? "");
      setBeforePromoText(response.beforePromoText ?? "");
      if (response?.priceSummary?.hasDiscount) {
        if (response.priceSummary.priceToPay === 1.01) {
          setPricePromotion(1.0);
        } else {
          setPricePromotion(response.priceSummary.priceToPay);
        }
      }
      setLoadingQuote(false);
    } catch (error) {
      setLoadingQuote(false);
      init("token_not_authorized", false);
      navigate("/login");
    }
  };

  useEffect(() => {
    (async () => {
      if (lead && token) {
        const gtf_campaign = getCookie("gtf_campaign");
        if (
          (gtf_campaign || GlobalPromoCodesToApply[DOMAIN as LanguageT]) &&
          !isCouponApplied
        ) {
          await fetchApplyCoupon(lead);
        } else {
          await fetQuoteData(lead);
        }
      }
    })();
  }, [lead, token, isCouponApplied]);

  useEffect(() => {
    if (flagLink) {
      linkRefCall.current?.click();
      setFlagLink(false);
    }
  }, [flagLink]);

  useEffect(() => {
    setHasTelesubscription(hasTelesubscription);
  }, [hasTelesubscription]);

  useEffect(() => {
    const toggleStickyBar = () => {
      const quoteBanner = document.getElementById("quote_page_banner");
      const isCTAInViewport: Boolean = isInViewport(quoteBanner);
      if (isCTAInViewport === isStickyVisible) {
        setIsStickyVisible(!isCTAInViewport);
      }
    };
    document.addEventListener("scroll", toggleStickyBar);
    return () => window.removeEventListener("scroll", toggleStickyBar);
  }, [isStickyVisible]);

  const renderAddictionalProtection = () => {
    if ((DOMAIN === "es" || DOMAIN === "wl_es") && coverage === "eg") {
      return `${translate("calculatorPage.textAdditionalProtectionEG")}:`;
    }
    return translate(GlobalIllnes[DOMAIN! as LanguageT]);
  };

  const isAddictionalProtectionApplied = () => {
    return (
      {
        it: true,
        fr: true,
        es: coverage === "eg",
      }[DOMAIN || ""] || false
    );
  };

  const hideIpaToogle = () => {
    if ((DOMAIN === "es" || DOMAIN === "wl_es") && coverage === "eg") {
      return true;
    }
    return GlobalOnlyPremium[DOMAIN! as LanguageT];
  };

  const showGlobalIllnesTitle = () => {
    if ((DOMAIN === "es" || DOMAIN === "wl_es") && coverage === "eg") {
      return translate("calculatorPage.titleAdditionalProtectionEg");
    }
    return translate(GlobalIllnesTitle[DOMAIN! as LanguageT]);
  };

  const showGlobalIllnesContent = () => {
    if ((DOMAIN === "es" || DOMAIN === "wl_es") && coverage === "eg") {
      return translate("calculatorPage.contentAdditionalProtectionEg");
    }
    return translate(GlobalIllnesContent[DOMAIN! as LanguageT]);
  };

  return {
    basicFrom,
    btnContractLabel,
    conditionsPrice,
    coverage,
    downloadableFiles,
    downloadableLoading,
    downloadDocument,
    handleButtonQuote,
    hasTelesubscription,
    hideIpaToogle,
    insuranceCover,
    intention,
    ipaToggle,
    isAddictionalProtectionApplied,
    isMobile,
    linkRefCall,
    loadingQuote,
    maxCapital,
    minCapital,
    navigateBack,
    navigateToQuestions,
    premiumFrom,
    pricePromotion,
    renderAddictionalProtection,
    setFlagLink,
    setInsuranceCover,
    showGlobalIllnesTitle,
    showGlobalIllnesContent,
    translate,
    updateIpa,
    updatePrices,
    afterPromoText,
    beforePromoText,
    isStickyVisible,
  };
};

export default QuotePageController;
