import HttpGlobal from "../Global/HttpGlobal.class";

export default class HttpPublicRepository extends HttpGlobal {
  private readonly API_URL_PUBLIC:
    | string
    | undefined = `${process.env.REACT_APP_CUSTOMER_APP_URL}api/public`;

  getValidationActuarialAge = async (date: string) => {
    const response = await this.generalGet(
      `${this.API_URL_PUBLIC}/age/validation/${date}`
    );
    return response;
  };
}
