import * as React from "react";
import Box from "../../../LibraryComponents/Box/Box.component";
import { ProgressionListI } from "../DecisionPage.controller";

interface ProgressionI {
  list: Array<ProgressionListI>;
  title: string;
  type?: string;
}

const Progression: React.FC<ProgressionI> = ({ list, title, type }) => {
  return (
    <Box title={title}>
      <ul className={`list-elements ${type}`}>
        {list.map((item, index) => (
          <li key={index} className={`progression__list__container ${item.active}`}>
            <span className={`numberlist ${item.active}`}>{index + 1}</span>
            <div className={`progression__list__item ${item.active}`}>
              {item.title}
              <span>{item.description}</span>
            </div>
          </li>
        ))}
      </ul>
    </Box>
  );
};

export default Progression;
