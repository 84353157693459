import CalculatorQuestions from "../../Questions/CalculatorQuestions/CalculatorQuestions";
import StepsCalculatorPageController from "./StepsCalculatorPage.controller";

const StepsCalculatorPage = () => {
  const {
    blockersText,
    checkAnswerVsBlockers,
    data,
    handleBlockers,
    handleCallMe,
    handleLeadId,
    handleShowHeader,
    handleToken,
    lastQuestion,
    leadId,
    loading,
    nextQuestion,
    popUp,
    questionStack,
    refreshNextQuestion,
    saveQuestion,
    setData,
    token,
  } = StepsCalculatorPageController();

  return (
    <div className={"w-full questions-outerdiv"}>
      <CalculatorQuestions
        blockersText={blockersText}
        checkAnswerVsBlockers={checkAnswerVsBlockers}
        goBack={lastQuestion}
        handleBlockers={handleBlockers}
        handleCallMe={handleCallMe}
        handleLeadId={handleLeadId}
        handleOnChange={setData}
        handleSelectorDisplayed={handleShowHeader}
        handleToken={handleToken}
        isLoading={loading}
        lastQuestion={lastQuestion}
        leadId={leadId}
        nextQuestion={nextQuestion}
        params={data}
        popUpBlockers={popUp.blockers}
        refreshNextQuestion={refreshNextQuestion}
        saveQuestion={saveQuestion}
        showBlockerModal={popUp.blockers}
        showCallMe={popUp.callMe}
        token={token}
        questionStack={questionStack}
      />
    </div>
  );
};

export default StepsCalculatorPage;
