import DecisionPageController from "./DecisionPageSQL.controller";
import moment from "moment";
import "./DecisionPageSQL.styles.scss";
import { ButtonDS } from "get-life-storybook-ts";
import numberFormat from "../../utils/Utils";
import WelcomeSQL from "./components/Welcome/WelcomeSQL.component";
import ProgressionSQL from "./components/Progression.component";

const DecisionPageSQL = (): JSX.Element => {
  const { data, progression, translate, continueProcess, brokerPhone } =
    DecisionPageController();

  return (
    <>
      <div className="w-full flex flex-col max-w-[740px]">
        <div className="w-full flex justify-center pt-[60px] pb-[40px]">
          <WelcomeSQL />
        </div>
        <div className="w-full flex flex-col gap-6 items-center pb-10 justify-between md:flex-row md:items-start md:gap-0">
          <div
            className="w-[327px] rounded-[8px]"
            style={{
              boxShadow:
                "0px -1px 0px 0px rgba(66, 66, 66, 0.10) inset, 0px 1px 3px 0px rgba(66, 66, 66, 0.10)",
            }}
          >
            <div className="flex items-center w-full bg-[var(--theme-tertiary)] px-5 py-[8px] border border-[#A09FFA] rounded-t-[8px]">
              <span className="BodyS font-bold text-purple">
                {translate("welcome.sql.guarantee.title")}
              </span>
            </div>
            <div className="flex flex-col w-full px-5 pt-6 pb-4 border borde-[1px] border-[#A09FFA] break-words border-t-0">
              <span className="BodyS text-[#686868]">
                {translate("welcome.sql.people")}
              </span>
              <span className="BodyXL font-bold text-purple">
                {data?.email}
              </span>
              <span className="BodyS text-[#7B7B7B]">
                {data?.birthDate &&
                  moment(data?.birthDate).format("DD/MM/YYYY")}
              </span>
            </div>
            <div className="flex flex-col w-full px-5 pt-6 pb-4 border border-[#A09FFA] break-words border-t-0">
              <span className="BodyS text-[#686868]">
                {translate("welcome.sql.totalCapital")}
              </span>
              <span className="BodyXL font-bold text-purple">
                {data?.sumCapitals && numberFormat(data.sumCapitals, "capital")}
              </span>
              {data?.intention && data.intention.length != -1 && (
                <span className="BodyS text-[#7B7B7B]">
                  {translate(`welcome.sql.intention.${data.intention[0]}`)}
                </span>
              )}
            </div>
            <div className="flex flex-col w-full px-5 pt-5 pb-4 border border-[#A09FFA] break-words border-t-0 rounded-b-[8px]">
              <span className="BodyS text-[#686868]">
                {translate("welcome.sql.coverage")}
              </span>
              <span className="BodyXL font-bold text-purple">
                {translate("welcome.guarantee.death")}
              </span>
              {data?.coverages &&
                data.coverages.length != -1 &&
                data.coverages.map((key: string) => {
                  if (key !== "DEATH")
                    return (
                      <span className="BodyS text-[#7B7B7B]">
                        +{" "}
                        {translate(`welcome.multiproduct.${key.toLowerCase()}`)}
                      </span>
                    );
                })}
            </div>
          </div>
          <div className="opacity-0 w-[1px] self-stretch bg-[var(--theme-primary)] md:opacity-100"></div>
          <div className="flex flex-col gap-[40px]">
            <ProgressionSQL
              title={translate("welcome.Progression.title")}
              list={progression}
              type=""
            />
            <div className="flex flex-col px-6 gap-4">
              <span className="BodyM text-[#424242]">
                {translate("welcome.continue.question")}
              </span>
              <ButtonDS
                label={translate("welcome.continue.button")}
                rightIcon="ArrowRightIcon"
                onClick={() => continueProcess()}
                size="50"
                className="flex-1"
              />
            </div>
          </div>
        </div>

        {/* <div className="decisionPage-container">
          
          <div className="progression-container">
            <Progression
              title={translate("decisionPage.Progression.title")}
              list={progression}
              type=""
            />
          </div>
          <div className="continue-container">
            <p className="text-continue">
              {translate("decision.continue.question")}
            </p>
            <ButtonDS
              rightIcon="ArrowGoRightIcon"
              label={translate("decision.continue.button")}
              onClick={() => continueProcess()}
            />
            <ButtonDS
              buttonType="secondary"
              label={translate("decision.reset.button")}
              onClick={() => resetQuote()}
            />
            <p className="text-continue">
              {translate("decision.call.question")} <br></br>{" "}
              <a href={`tel:${process.env.REACT_APP_TEL}`}>
                {translate("decision.call.link")} {process.env.REACT_APP_TEL}
              </a>{" "}
            </p>
          </div>
        </div> */}
      </div>
      <div className="flex bg-[var(--theme-quaternary)] w-full px-[24px] py-[50px]">
        <div className="max-w-[740px] mx-auto flex flex-col items-center gap-[20px] text-center">
          <span className="H2 font-bold text-purple">
            {translate("welcome.sql.contact.title")}
          </span>
          <p className="BodyL text-[#424242] w-fit">
            {translate("welcome.call.question")}{" "}
            <a
              className="text-purple"
              href={`tel:${
                brokerPhone ? brokerPhone : process.env.REACT_APP_TEL
              }`}
            >
              {brokerPhone ? brokerPhone : process.env.REACT_APP_TEL}
            </a>{" "}
            <ButtonDS
              label={translate("welcome.call.checkWithBroker")}
              leftIcon="PhoneCallIcon"
              buttonType="secondary"
              className="w-full mt-[20px]"
              style={{ textDecoration: "unset" }}
              href={`tel:${
                brokerPhone ? brokerPhone : process.env.REACT_APP_TEL
              }`}
            />
          </p>
        </div>
      </div>
    </>
  );
};

export default DecisionPageSQL;
