/* eslint-disable react-hooks/exhaustive-deps */
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import HttpLeadRepository from "../../api/request/Lead/Lead.service";
import HttpDocumentsRepository from "../../api/request/Documents/Documents.service";
import { GetlifeContext } from "../../context/GetLifeContext.context";
import { I18nContext } from "../../context/i18n.context";
import { useLocation, useNavigate } from "react-router";
import {
  CoverageT,
  ProductI,
  NewProductI,
} from "../../api/request/Lead/Interfaces/ProductInterface.response";
import { GetLifeTrackerContext } from "../../context/GetLifeTracker.context";
import {
  DayAvailable,
  GlobalDomainQuestions,
  DaysAvailable,
} from "../../utils/InternationlChanges";
import { useDevice, GlobalIcon } from "get-life-storybook-ts";
import { coverList, notCoverList } from "../../utils/dataForNewUpsell";
import { datePickerI } from "get-life-storybook-ts/lib/molecules/CustomPolicy/CustomPolicy";
import { DataI } from "../../api/request/Lead/Interfaces/DataInterface.response";
import { StatusContext } from "../../context/StatusContext";
import { DownloadableFilesT } from "get-life-storybook-ts/lib/components/DownloadableFiles/DownloadableFiles";
import { PARSE_DOCS } from "../../constants/Global";
import { isInViewport } from "../../utils/Utils";
import { getCookie } from "../../hooks/getCookie";

const STORAGE = "https://storage.googleapis.com/getlife-bucket-1/public";

const IPID = `${STORAGE}/pdf/IPID-GTD.pdf`;

export interface UpsellControllerI {
  product: ProductI;
  checkOut: DataI;
  setUpsellData: Dispatch<SetStateAction<DataI | null>>;
  setUpsellProduct: Dispatch<SetStateAction<ProductI | null>>;
}

const UpsellController = () => {
  const {
    handleCallMe,
    leadId,
    token,
    domain,
    backFlag,
    isABtesting,
    sendEventsNewLead,
    setSendEventsNewLead,
    utm_campaign,
    utm_source,
    utm_content,
    utm_medium,
    utm_term,
    utm_test,
    gclid,
    msclkid,
    fbclid,
    gtf_campaign,
    getEntryPage,
    getReferrerUrl,
    getABTestVersion,
    nodeMaxCapitalModal,
  } = useContext(GetlifeContext);
  const { handleTrackerQuestion, identifyFS } = useContext(
    GetLifeTrackerContext
  );
  const {
    state: { translate },
  } = useContext(I18nContext);
  const { getStatus } = useContext(StatusContext);

  const location = useLocation();
  const paymentLocation = location?.pathname.split("/")[1];

  const nodeConditionsModal: any = useRef(null);

  const handleConditions = () =>
    nodeConditionsModal.current?.handleShowPortal();

  const [product, setProduct] = useState<NewProductI>();

  const [capital, setCapital] = useState(0);
  const [ipa, setIpa] = useState("");
  const [newIpa, setNewIpa] = useState<CoverageT | boolean>(false);

  const [upGradedOpen, setUpGradedOpen] = useState(false);
  const [changeDone, setChangeDone] = useState(false);
  const [capitalAdded, setCapitalAdded] = useState(false);
  const [capitalAddedAmount, setCapitalAddedAmount] = useState(0);

  const [isStickyVisible, setIsStickyVisible] = useState<Boolean>(false);

  const [priceChange, setPriceChange] = useState(false);

  const [downloadableFiles, setDownloadableFiles] =
    useState<DownloadableFilesT>();

  const [afterPromoText, setAfterPromoText] = useState<string>("");
  const [beforePromoText, setBeforePromoText] = useState<string>("");

  const { isMobile } = useDevice();

  const refIPA = useRef<any>();

  const linkRef = useRef<HTMLAnchorElement | null>(null);

  const leadRepository = new HttpLeadRepository(leadId, token);

  const today = new Date();
  const aMonthMore = new Date().setMonth(today.getMonth() + 1);

  const handleUpgradedClose = () => {
    setUpGradedOpen(false);
  };

  const handleIpaChange = (newIpa: any) => {
    setNewIpa(newIpa);
    setIpa(newIpa);
  };

  const handleProduct = async (
    newCapital: number,
    newIpa: any
  ): Promise<any> => {
    setCapital(newCapital);
    setIpa(newIpa);
    setPriceChange(true);
    try {
      await leadRepository.updateProductUpsell({
        capital: newCapital,
        ipa,
        offer: "adjusted",
      });
      const upsellData: NewProductI = await leadRepository.getUpsellData();

      setProduct(upsellData);
      setPriceChange(false);
      if (upsellData.downgraded) {
        setCapital(upsellData.downgradedCapitalMaximum);
        nodeMaxCapitalModal.current?.handleShowPortal();
        return handleProduct(upsellData.downgradedCapitalMaximum, newIpa);
      }
      if (upsellData) {
        setAfterPromoText(upsellData?.afterPromoText);
        setBeforePromoText(upsellData?.beforePromoText);
      }
    } catch (error) {}
  };

  const navigate = useNavigate();

  const selectPlan = () => {
    leadRepository
      .updateProductUpsell({
        capital,
        ipa,
        offer: "adjusted",
      })
      .then(() => {
        navigate(`/${paymentLocation}/3`);
      });
  };

  const correctPrice = (property: keyof NewProductI): any => {
    return product ? product[property] : null;
  };

  const documentsRepository = new HttpDocumentsRepository(leadId, token);

  const getAvailablesDocuments = async (lead: string) => {
    documentsRepository.getAvailablesDocuments(lead).then((resp) => {
      let allDocs: DownloadableFilesT = [
        {
          label: "",
          type: "",
        },
      ];
      resp.forEach((element: string) => {
        allDocs.push({
          label: PARSE_DOCS(translate)[0][element],
          type: element,
        });
      });

      setDownloadableFiles(allDocs!);
    });
  };

  const downloadDocument = async (type: string) => {
    documentsRepository.downloadDocument(type).then((res) => {
      window.open(res.documentUrl, "_blank");
    });
  };

  const openLink = () => {
    linkRef.current?.click();
  };

  const externalLink = {
    showLink: domain === "fr",
    linkClick: openLink,
    linkText: translate("upsell.IPID.text"),
    linkRef,
    linkUrl: IPID,
  };

  const changeCapitalTooltip = (price: string) => {
    setChangeDone(!changeDone);
    handleProduct(Number(price), ipa);
    setCapitalAdded(false);
  };

  const addCapital = async () => {
    !capitalAdded && setCapitalAddedAmount(product!.extraCapitalAmount || 0);
    let newCapital = capitalAdded
      ? product!.capital - capitalAddedAmount
      : product!.capital + product!.extraCapitalAmount;
    setCapitalAdded(!capitalAdded);
    await handleProduct(newCapital, ipa);
  };

  const correctPricePromotion = (property: keyof NewProductI) => {
    if (!!product!.promotion && product!.regularPrice !== product!.priceToPay) {
      return product!.priceToPay;
    }

    return correctPrice(property);
  };

  const renderPriceValidation = () => {
    const date = new Date(aMonthMore);
    return `${translate("newUpsell.banner.priceValidate")} ${date.getDate()}/${
      date.getMonth() + 1
    }/${date.getFullYear()}`;
  };

  const datePickerProps = (): datePickerI => {
    const today = new Date();
    const startDate = new Date(
      new Date(today).setDate(today.getDate() + DayAvailable[domain])
    );
    let finalDate = new Date(
      new Date().setDate(today.getDate() + DaysAvailable[domain])
    );
    const object = {
      initialDate: product!.effectDate,
      locale: GlobalDomainQuestions[domain],
      title: translate("upsell.DatePicker.title"),
      subtext1: translate("upsell.DatePicker.subtitle1"),
      subtext2: translate("upsell.DatePicker.subtitle2"),
      subtext3: translate("upsell.DatePicker.subtitle3"),
      textToday: translate("upsell.DatePicker.textToday"),
      textTomorrow: translate("upsell.DatePicker.textTomorrow"),
      textAfterTomorrow: translate("upsell.DatePicker.textAfterTomorrow"),
      notShowIpa: true,
      onValueChange: changeInitDate,
      overlayButtonLabel: translate("productQuestions.overlay.button"),
      maxDate: finalDate.toString(),
      minDate: startDate,
    };

    return object;
  };

  const listSectionData = [
    {
      iconElement: (
        <div>
          <GlobalIcon iconName="ShieldCheckIcon" size="XS" color="#018565" />
        </div>
      ),
      title: translate("newUpsell.listSection.cover"),
      list: coverList(translate, domain, ipa),
    },
    {
      iconElement: (
        <div>
          <GlobalIcon iconName="MoodConfuzedIcon" size="XS" color="#686868" />
        </div>
      ),
      title: translate("newUpsell.listSection.notCover"),
      list: notCoverList(translate, domain, ipa),
    },
  ];

  const changeInitDate = (date: any) => {
    let dateFormat = `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()}`;
    leadRepository.updateEffectDate(dateFormat).then((res) => {
      getProductData();
    });
  };

  const getProductData = async () => {
    await leadRepository.getUpsellData().then((response: NewProductI) => {
      setProduct(response);

      setCapital(response.capital);
      setIpa(response.coverage);
      setUpGradedOpen(response.upgraded);
      setAfterPromoText(response.afterPromoText ?? "");
      setBeforePromoText(response.beforePromoText ?? "");

        const event = "finalQuoteGenerated";
        const href = window?.location?.href ?? location.pathname;
        const dataInfo = {
          email: response.email,
          phone: response.phone,
          postalCode: response.zipcode,
          gender: response.gender,
          platform: "App",
          page: href,
          leadId: leadId,
          calculatedCapital: response.capital,
          calculatedPremium: response.finalYearlyPrice,
          coverage: response.coverage === "premium" ? "FIPA" : "F",
          utm_source: getCookie("utm_source"),
          utm_campaign: getCookie("utm_campaign"),
          utm_medium: getCookie("utm_medium"),
          utm_content: getCookie("utm_content"),
          utm_term: getCookie("utm_term"),
          utm_test: getCookie("utm_test"),
          gclid: getCookie("gclid"),
          msclkid: getCookie("msclkid"),
          fbclid: getCookie("fbclid"),
          entryPage: getEntryPage(),
          referrerUrl: getReferrerUrl(),
          ABTestVersion: getABTestVersion()
        }
        handleTrackerQuestion({
          type: "FS",
          event,
          data: {
            birthDate: new Date(response.birthdate),
            ...dataInfo
          }
        });

        handleTrackerQuestion({
          type: "GA",
          data: {
            event,
            birthDate: response.birthdate,
            ...dataInfo
          },
        });
    });
  };

  useEffect(() => {
    (async () => {
      await getProductData();
    })();
  }, []);

  useEffect(() => {
    if (process.env.REACT_APP_ENVIROMENT !== "DEV") {
      if (!backFlag) {
        getStatus();
      }
    }
  }, []);

  useEffect(() => {
    if (newIpa) {
      setNewIpa(false);
      handleProduct(capital, ipa);
    }
  }, [ipa]);

  useEffect(() => {
    if (sendEventsNewLead === "true") {
      const dataInfo = {
        page: window.location.href,
        leadId: leadId,
        intent: undefined,
        phone: product!.phone,
        email: product!.email,
        calculatedCapital: product!.capital,
        calculatedPremium: product!.finalYearlyPrice,
        coverage: product!.coverage === "premium" ? "FIPA" : "F",
        gender: product!.gender,
        postalCode: product!.zipcode,
        utm_campaign: utm_campaign,
        utm_source: utm_source,
        utm_medium: utm_medium,
        utm_content: utm_content,
        utm_term: utm_term,
        utm_test: utm_test,
        gclid: gclid,
        msclkid: msclkid,
        fbclid: fbclid,
        platform: "App",
        entryPage: getEntryPage(),
        referrerUrl: getReferrerUrl(),
        ABTestVersion: getABTestVersion(),
      };

      identifyFS({
        lead: leadId,
        data: {
          emailDomain: product!.email && product!.email.split("@")[1],
          email: product!.email,
          brokerId: "1",
        },
      });

      let event = "leadCreated";
      handleTrackerQuestion({
        type: "FS",
        event,
        data: {
          birthDate: new Date(product!.birthdate),
          ...dataInfo,
        },
      });
      handleTrackerQuestion({
        type: "GA",
        data: {
          event,
          birthDate: product!.birthdate,
          ...dataInfo,
        },
      });

      event = "calculatorQuoteGenerated";
      handleTrackerQuestion({
        type: "FS",
        event,
        data: {
          birthDate: new Date(product!.birthdate),
          ...dataInfo,
        },
      });
      handleTrackerQuestion({
        type: "GA",
        data: {
          event,
          birthDate: product!.birthdate,
          ...dataInfo,
        },
      });
      handleTrackerQuestion({
        type: "tracker",
        data: {
          leadUlid: leadId,
          campaign: gtf_campaign ?? "info-app",
        },
      });

      setSendEventsNewLead("false");
    }
  }, []);

  useEffect(() => {
    getAvailablesDocuments(leadId);
  }, [product]);

  useEffect(() => {
    const toggleStickyBar = () => {
      const quoteBanner = document.getElementById("upsell_page_resume_cart");
      const quoteCTA = document.getElementById("upsell_page_upsell_cta");
      const isBannerInViewport: Boolean = isInViewport(quoteBanner);
      const isCTAInViewport: Boolean = isInViewport(quoteCTA);
      if (isBannerInViewport || isCTAInViewport === isStickyVisible) {
        setIsStickyVisible(!(isBannerInViewport || isCTAInViewport));
      }
    };
    document.addEventListener("scroll", toggleStickyBar);
    return () => window.removeEventListener("scroll", toggleStickyBar);
  }, [isStickyVisible]);

  const couponInfo =
    product && product.promotion
      ? {
          conditions: product?.promotion?.conditions,
          exceptions: product?.promotion?.exceptions,
          code: product?.promotion?.promotionalCode,
        }
      : undefined;

  return {
    addCapital,
    capital,
    capitalAdded,
    capitalAddedAmount,
    changeCapitalTooltip,
    changeDone,
    correctPrice,
    correctPricePromotion,
    datePickerProps,
    domain,
    externalLink,
    handleCallMe,
    handleIpaChange,
    handleUpgradedClose,
    ipa,
    isMobile,
    listSectionData,
    priceChange,
    refIPA,
    renderPriceValidation,
    selectPlan,
    setChangeDone,
    translate,
    upGradedOpen,
    product,
    downloadableFiles,
    isABtesting,
    downloadDocument,
    afterPromoText,
    beforePromoText,
    isStickyVisible,
    nodeConditionsModal,
    handleConditions,
    couponInfo,
  };
};

export default UpsellController;
