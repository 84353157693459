const getUsedCapital = (inputFeilds) => {
  const validInputs = Array.from(inputFeilds).filter(
    (input) => input.type === "number"
  );
  let used = 0;
  validInputs.forEach((input, index) => {
    if(input.value){
      return (used += parseInt(input?.value));
    }
    return used
  });
  const capitalValues = validInputs.map((input) => parseInt(input.value) || 0);

  return { total: used, capitalValues };
};
export default getUsedCapital;
