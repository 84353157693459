import FormBuilder from "../../../../../components/Components/FormBuilder/FormBuilder";
import BeneficiariesFreeChoiceController from "./BeneficiariesFreeChoice.controller";
import FormButton from "../../../../../components/Components/FormBuilder/FormButtons";
import { FormBuilderProvider } from "../../../../../context/FormBuilder.context";
import { DOMAIN } from "../../../../../constants/Global";

export default function BeneficiariesFreeChoice() {
  const {
    beneficiaries,
    loading,
    question,
    sendAnswer,
    setBeneficiaries,
    translate,
  } = BeneficiariesFreeChoiceController();

  return (
    <FormBuilderProvider answer={beneficiaries} question={question}>
      <div className={"questions-outerContainer"}>
        <div className={"questions-container"}>
          <div className={`questions-innerContainer ${DOMAIN === "it" ? "questions-innerContainer-it" : ""}`}>
            <FormBuilder
              answer={beneficiaries}
              callback={undefined}
              capitalLimits={undefined}
              chat={undefined}
              countryList={undefined}
              cityList={undefined}
              error={undefined}
              handleSubmit={sendAnswer}
              handleValues={setBeneficiaries}
              isDisplayed={false}
              isOneChoinceQuestionRoute={false}
              onBlur={undefined}
              personalData={undefined}
              question={question}
              setIsDisplayed={undefined}
              setValidateQuestionDateInput={undefined}
              terzoReferente={undefined}
              terzoError={undefined}
              setTerzoReferente={undefined}
              showTerzo={undefined}
              setShowTerzo={undefined}
            />
          </div>
        </div>
        {question.name === "formBeneficiaries" && (
          <FormButton
            answer={beneficiaries}
            componentName={undefined}
            customDisabled={beneficiaries.length === 0}
            goBack={undefined}
            handleSubmit={sendAnswer}
            isLoading={loading}
            isOneChoinceQuestion={undefined}
            question={question}
            showButton={true}
            translate={translate}
            validateQuestionDateInput={undefined}
            questionStack={[]}
            isSticky={false}
          />
        )}
      </div>
    </FormBuilderProvider>
  );
}
