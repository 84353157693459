import * as React from "react";
import { useNavigate } from "react-router";
import HttpLeadRepository from "../../api/request/Lead/Lead.service";
import {
  GetlifeContext,
  UserStatusT,
} from "../../context/GetLifeContext.context";
import { I18nContext } from "../../context/i18n.context";
import { redirect } from "../../Routes/redirect";
import { GetLifeTrackerContext } from "../../context/GetLifeTracker.context";

export interface ProgressionListI {
  description: string;
  title: string;
  active?: "active";
}

export interface ResponseResetQuote {
  token: string;
  id: string;
  status: UserStatusT;
}

const DecisionPageController = () => {
  const {
    handleCallMe,
    leadId,
    setLeadId,
    setLoading,
    setToken,
    brokerPhone,
    token,
    userStatus,
    setThemeColor,
    setLogo,
  } = React.useContext(GetlifeContext);

  const {
    state: { translate },
  } = React.useContext(I18nContext);

  const [data, setData] = React.useState<any>();

  const [progression, setProgression] = React.useState<Array<ProgressionListI>>(
    []
  );

  const navigate = useNavigate();
  const leadRepository = new HttpLeadRepository(leadId, token);

  const fetch = async () => {
    const response = await leadRepository.getInfoLeadV2();
    const { themeColor, logo } = response;
    setThemeColor(themeColor);
    setLogo(logo);
    setProgression([
      {
        description: translate(
          "decisionPage.Progression.basicInfo.description"
        ),
        title: translate("decisionPage.Progression.basicInfo.title"),
        active: response.basicInfo && "active",
      },
      {
        description: translate(
          "decisionPage.Progression.healthQuestions.description"
        ),
        title: translate("decisionPage.Progression.healthQuestions.title"),
        active: response.healthQuestions && "active",
      },
      {
        description: translate(
          "decisionPage.Progression.paidAndSigned.description"
        ),
        title: translate("decisionPage.Progression.paidAndSigned.title"),
        active: response.paidAndSigned && "active",
      },
      {
        description: translate("decisionPage.Progression.final.description"),
        title: translate("decisionPage.Progression.final.title"),
        active:
          response.basicInfo &&
          response.healthQuestions &&
          response.paidAndSigned &&
          "active",
      },
    ]);
    setData(response);
    setLoading(false);
  };

  const closePage = () => {
    window.location.replace(`${process.env.REACT_APP_DOMAIN_URL}`);
  };

  const continueProcess = () => {
    setToken(token || "");
    setLeadId(leadId || "");
    if (userStatus === "telesubscription") {
      navigate(`/calculator/4?lead=${leadId}`);
    } else {
      navigate(redirect(userStatus));
    }
  };

  React.useEffect(() => {
    (async () => {
      setLoading(true);
      fetch();
    })();
  }, [leadId]);

  return {
    data,
    progression,
    translate,
    handleCallMe,
    closePage,
    continueProcess,
    brokerPhone
  };
};

export default DecisionPageController;
