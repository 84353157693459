import { StripeElementChangeEvent } from "@stripe/stripe-js";
import CardSetupForm from "../CardSetupForm/CardSetupForm.component";
import { ButtonDS, CheckboxAtom, Typography } from "get-life-storybook-ts";
import StripeIcon from "get-life-storybook-ts/lib/components/Icons/StripeIcon";
import numberFormat from "../../../../../../utils/Utils";
import { useContext } from "react";
import { StripeContext } from "../../../../../../context/StripeContext.context";
import { GetlifeContext, LanguageT } from "../../../../../../context/GetLifeContext.context";
import { ConditionsLink, PrivacityLink } from "../../../../../../utils/InternationlChanges";

interface SetUpI {
  clientSecret: string;
  name: string;
  lastName: string;
}

interface MessageI {
  message: string;
  replace: string | React.ReactNode;
  symbol?: "#" | "$";
}

export interface PaymentCardContentI {
  buttonLabel: string;
  cardError: boolean;
  creditCardData: any;
  handleCheckbox: (value: boolean) => void;
  handleCheckbox2: (value: boolean) => void;
  handleStripeStatus: (
    key: "error",
    value: StripeElementChangeEvent["error"],
    keySecond: "complete",
    valueSecond: StripeElementChangeEvent["complete"]
  ) => void;
  isButtonDisabled: boolean;
  stripeInputStatus: {
    complete: StripeElementChangeEvent["complete"];
    error: StripeElementChangeEvent["error"];
  };
  setupIntent: SetUpI;
  translate: (key: string) => string;
  payConfirm: boolean;
  payConfirm2: boolean;
}

const LOCALE = process.env.REACT_APP_DOMAIN_LOCALE;

const conditions = ConditionsLink[LOCALE as LanguageT];
const privacity = PrivacityLink[LOCALE as LanguageT];

export const getMessage = ({
  message,
  replace,
  symbol = "$",
}: MessageI): JSX.Element => (
  <>
    {message.substring(0, message.indexOf(symbol))}
    {replace}
    {message.substring(message.indexOf(symbol) + 1, message.length)}
  </>
);

const PaymentCardContent: React.FC<PaymentCardContentI> = ({
  buttonLabel,
  creditCardData,
  handleCheckbox,
  handleCheckbox2,
  handleStripeStatus,
  isButtonDisabled,
  payConfirm,
  payConfirm2,
  stripeInputStatus,
  setupIntent,
  translate,
}) => {
  const { handleSubmit } = useContext(StripeContext);
  const { domain } = useContext(GetlifeContext);

  return (
    <div className="PaymentCardContent" style={{ width: "100%" }}>
      {creditCardData === true && (
        <div className="checkoutConfirmation-sectionContainer">
          <div className="checkoutConfirmation-cardSection">
            {Object.keys(setupIntent).length !== 0 && (
              <div className="checkoutConfirmation-payContentContainer">
                <CardSetupForm
                  translate={translate}
                  stripeInputStatus={stripeInputStatus}
                  handleStripeStatus={handleStripeStatus}
                />
              </div>
            )}
            <div className="checkoutConfirmation-payCheckBoxContainer">
              <div className="checkoutConfirmation-payCheckbox">
                <CheckboxAtom
                  isLegal
                  checked={payConfirm}
                  onValueChange={handleCheckbox}
                  label={
                    <Typography variant="BodyMedium" text="" color="dark-gray-100">
                      {getMessage({
                        message: translate(
                          "payment.paymentCard.checkBox.unifiedMessage"
                        ),
                        replace: (
                          <Typography
                            variant="SpanMedium"
                            weight="700"
                            text={numberFormat(0.5, "price")}
                          />
                        ),
                      })}
                      {domain === "fr" &&
                        getMessage({
                          message: translate(
                            "payment.paymentCard.checkBox.cotisation"
                          ),
                          replace: (
                            <Typography
                              variant="SpanMedium"
                              weight="700"
                              text={numberFormat(2.5, "price")}
                            />
                          ),
                        })}
                      <br />
                      {getMessage({
                        message: translate(
                          "payment.paymentCard.checkBox.conditionsMessage"
                        ),
                        replace: (
                          <Typography
                            variant="LinkMedium"
                            text={translate(
                              "payment.paymentCard.checkBox.conditionLink"
                            )}
                            href={conditions}
                            target="_blank"
                            rel="noreferrer"
                          />
                        ),
                      })}

                      {getMessage({
                        message: translate(
                          "payment.paymentCard.checkBox.policyMessage"
                        ),
                        replace: (
                          <Typography
                            variant="LinkMedium"
                            text={translate(
                              "payment.paymentCard.checkBox.policyLink"
                            )}
                            href={privacity}
                            target="_blank"
                            rel="noreferrer"
                          />
                        ),
                      })}
                    </Typography>
                  }
                />

                {domain === "it" && <CheckboxAtom
                  isLegal
                  checked={payConfirm2}
                  onValueChange={handleCheckbox2}
                  label={
                    <Typography variant="BodyMedium" text={translate("paymentCard.second.checkbox")} color="dark-gray-100"></Typography>
                  }
                />}
              </div>
              <div className="PaymentCardContent__stripe">
                <Typography
                  weight="700"
                  color="purple"
                  variant="BodyXSmall"
                  text={translate("paymentCard.paymentStripe")}
                />
                <StripeIcon />
              </div>
              <div className="checkoutConfirmation-buttonContainer">
                <ButtonDS
                  onClick={() => handleSubmit()}
                  label={buttonLabel}
                  disabled={isButtonDisabled}
                  className="w-full"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

PaymentCardContent.propTypes = {};

export default PaymentCardContent;
