import { Typography } from "get-life-storybook-ts";
import "../../css/LegalPages.scss";

const TermsAndConditions = () => {
  return (
    <main className="legalPages legalPages--layout">
      <Typography
        variant="H2"
        weight="700"
        text=""
        className="legalPages--title"
      >
        Términos y condiciones de Servicio de Life5 - Aviso Legal
      </Typography>
      <section className="legalPages--section">
        <Typography
          variant="H3"
          weight="700"
          text=""
          className="legalPages--sectionHeading"
        >
          Identificación y contacto del titular del sitio web
        </Typography>
        <Typography variant="BodyMedium" weight="400" text="">
          A través del presente Aviso Legal, le informamos que la página web con
          URL <b>life5.es</b> (en lo sucesivo, el “Sitio Web”), bajo la marca
          comercial “LIFE5”, es <b>YOUR LIFE CORREDURÍA DE SEGUROS S.L.</b>{" "}
          inscrita en el Registro Mercantil de Madrid en el tomo 41.416, folio
          70, hoja M-733980, inscripción 1ª; con Código de Identificación Fiscal
          (CIF) B42814236 y con domicilio social en la Calle Francisco de Rojas,
          Nº 3, 6º Derecha, 28010, Madrid, CAM, España; autorizada como corredor
          de seguros, persona jurídica, ante la Dirección General de Seguros y
          Fondos de Pensiones (DGSFP) con la clave J3945.
          <br />
          <br />
          Puede obtener más información sobre LIFE5 poniéndose en contacto
          enviando un correo electrónico a la siguiente dirección:{" "}
          <a href="mailto:asesores@axa.life5.es">asesores@axa.life5.es</a>.
        </Typography>
      </section>
      <section className="legalPages--section">
        <Typography
          variant="H3"
          weight="700"
          text=""
          className="legalPages--sectionHeading"
        >
          Objeto y condiciones generales de uso del sitio web
        </Typography>
        <Typography variant="BodyMedium" weight="400" text="">
          El objeto del presente Aviso Legal es poner a disposición de aquellos
          que accedan, naveguen o consulten el contenido del Sitio Web (en
          adelante, el <b>“Usuario”</b> o los <b>“Usuarios”</b>), las normas que
          regulan la utilización del presente Sitio Web. Adicionalmente, el
          Sitio Web posibilita a los Usuarios la contratación de productos de
          LIFE5 o en los que LIFE5 actúa como intermediario a través de sus
          formularios facilitados (en adelante, <b>“Clientes”</b>).
          <br />
          <br />
          Los Clientes, en tanto que deseen ostentar dicha condición,
          formalizarán su relación con LIFE5 mediante un acuerdo en tal sentido
          (Condiciones Generales de Contratación), y por tanto se verán
          obligados, según corresponda, además de por el presente Aviso Legal,
          por los términos y condiciones particulares recogidos en dicho
          documento. Este Sitio Web ha sido desarrollado con fines informativos
          y comerciales, con la pretensión de acercar a los Usuarios información
          sobre los productos y servicios por vía electrónica de LIFE5. Así como
          establecer una plataforma para la contratación de los servicios de
          LIFE5 o de terceros de los LIFE5 actúa como intermediario.
          <br />
          <br />
          Los Usuarios, deberán en todo caso leer atentamente el presente Aviso
          Legal en cada una de las ocasiones en que utilice el Sitio Web, en
          tanto que el contenido del mismo podrá sufrir modificaciones según
          LIFE5 lo considere oportuno.
          <br />
          <br />
          En caso de que alguna de las disposiciones contenidas en el presente
          Aviso Legal sea declarada nula, se procederá a la retirada o
          sustitución de la misma. En cualquier caso, dicha declaración de
          nulidad no afectará a la validez del resto de disposiciones recogidas
          en este Aviso Legal.
          <br />
          <br />
          El Usuario es consciente y acepta que el uso del Sitio Web se realiza
          en todo caso bajo su única y exclusiva responsabilidad y se compromete
          a lo siguiente:
          <br />
          <br />
          <ul>
            <li>
              A usar el Sitio Web de la manera y en la forma que en este
              documento se establece.
            </li>
            <li>
              A no llevar a cabo ninguna conducta que pudiera dañar la imagen,
              los intereses y los derechos de LIFE5.
            </li>
            <li>
              No utilizar el presente Sitio Web y su información y contenidos
              con fines ilícitos y/o a no realizar actuaciones que pudieran
              perjudicar derechos y/o intereses de terceros.
            </li>
            <li>
              No realizar actos que pudieran dañar, inutilizar o sobrecargar el
              Sitio Web, o que impidiera, de cualquier forma, la normal
              utilización del mismo.
            </li>
            <li>
              Abstenerse de copiar o extraer ningún contenido del Sitio Web para
              su uso privado o público, salvo en los casos autorizados por ley o
              expresamente consentidos por LIFE5.
            </li>
          </ul>
          <br />
          El Usuario responderá frente a LIFE5, o frente a terceros, de los
          daños y perjuicios que se hubieran podido originar como consecuencia
          del incumplimiento de dichas obligaciones.
        </Typography>
      </section>
      <section className="legalPages--section">
        <Typography
          variant="H3"
          weight="700"
          text=""
          className="legalPages--sectionHeading"
        >
          Aceptación de las condiciones generales de uso
        </Typography>
        <Typography variant="BodyMedium" weight="400" text="">
          El Usuario que acceda y utilice el Sitio Web estará aceptando en su
          totalidad y, por lo tanto, obligado a cumplir, los términos y
          condiciones recogidos en el presente Aviso Legal en la versión
          publicada por LIFE5 en el momento mismo en que se produzca el acceso
          (en lo sucesivo, las <b>“Condiciones Generales de Uso”</b>).
          <br />
          <br />
          En caso de que el Usuario no estuviera de acuerdo con las presentes
          Condiciones Generales de Uso, deberá abandonar este Sitio Web, no
          pudiendo acceder ni disponer de los servicios que en él se ofrecen.
          <br />
          <br />
          El Usuario declara disponer de la capacidad jurídica suficiente para
          vincularse por las presentes Condiciones Generales de Uso. En caso de
          que el Usuario sea menor de edad, deberá contar con la autorización
          previa de sus progenitores o de sus tutores legales, quienes
          resultarán responsables del uso que el menos a su cargo haga del Sitio
          Web.
        </Typography>
      </section>
      <section className="legalPages--section">
        <Typography
          variant="H3"
          weight="700"
          text=""
          className="legalPages--sectionHeading"
        >
          Condiciones generales de contratación
        </Typography>
        <Typography variant="BodyMedium" weight="400" text="">
          El/la cliente de LIFE5 podrá contratar su seguro de vida a través de
          la página web de life5.es o por vía telefónica en el teléfono{" "}
          <b>919 495 510</b>. Para el proceso de contratación será necesario
          responder a un cuestionario sobre el capital a contratar, datos
          personales y de salud. Las respuestas a este cuestionario permiten
          evaluar el riesgo y dar un precio ajustado a la situación de cada
          cliente. En este punto se podrá escoger entre 3 productos diferentes
          específicos para cada cliente, donde se especifican todas las
          condiciones como el capital y las garantías a contratar.
          <br />
          <br />
          Tras la selección del producto deseado, se procederá a el pago del
          mismo. En el caso de contratar el producto por vía telefónica, se
          podrá elegir el pago anual o mensual y si se quiere pagar con tarjeta
          o a través de domiciliación bancaria. Actualmente, si la contratación
          se realiza a través de la web el pago será mensual y con tarjeta.
          <br />
          <br />
          El/la cliente recibirá un email con la confirmación de pago efectuado.
          Una vez confirmado, se pasará a la selección de beneficiarios del
          seguro e introducción de datos personales. Finalmente el cliente,
          deberá aceptar la nota legal de su póliza de vida. Para terminar, el
          cliente o asegurado procederá a la firma del contrato de forma online.
          El tiempo límite para la firma de este será de 30 días, después de
          este periodo, si no ha sido firmando, el contrato quedará cancelado.
          Tras la firma del contrato el cliente estará asegurado.
          <br />
          <br />
          Si el cliente desea la factura en formato electrónico podrá
          solicitarla en la dirección de correo electrónico{" "}
          <a href="mailto:asesores@axa.life5.es">asesores@axa.life5.es</a> y
          podrá recibir la factura en papel sin ningún coste asociado previa
          solicitud. Todo el proceso de compra se realizará en español.
          <br />
          <br />
          Si tras confirmar la compra usted detecta algún error, deberá
          contactar lo antes posible con LIFE5.
        </Typography>
      </section>
      <section className="legalPages--section">
        <Typography
          variant="H3"
          weight="700"
          text=""
          className="legalPages--sectionHeading"
        >
          Derecho de desistimiento
        </Typography>
        <Typography variant="BodyMedium" weight="400" text="">
          Tiene usted derecho a desistir del presente contrato en un plazo de
          treinta días naturales sin necesidad de justificación. El plazo de
          desistimiento expirará a los treinta días naturales del día de la
          celebración del contrato.
          <br />
          <br />
          Para ejercer el derecho de desistimiento, deberá usted notificarnos a
          Life5, Paseo de la Castellana 140 1ºA, <b>919 495 510</b>,{" "}
          <a href="mailto:asesores@axa.life5.es">asesores@axa.life5.es</a>
          , su decisión de desistir del contrato a través de una declaración
          inequívoca (por ejemplo, por correo electrónico). Podrá utilizar el
          modelo de formulario de desistimiento que figura a continuación,
          aunque su uso no es obligatorio. Tiene usted asimismo la opción de
          comunicar su deseo de desistimiento por vía telefónica o a través de
          su área de cliente, donde podrá rellenar un formulario. Si recurre a
          esa opción, le comunicaremos sin demora en un soporte duradero (por
          ejemplo, por correo electrónico) la recepción de dicho deseo de
          desistimiento.
          <br />
          <br />
          Para cumplir el plazo de desistimiento, basta con que la comunicación
          relativa al ejercicio por su parte de este derecho sea enviada antes
          de que venza el plazo correspondiente.
          <br />
          <br />
          En caso de desistimiento por su parte, le devolveremos todos los pagos
          recibidos de usted, sin ninguna demora indebida y, en todo caso, a más
          tardar 14 días naturales a partir de la fecha en la que se nos informe
          de su decisión de desistir del presente contrato. Procederemos a
          efectuar dicho reembolso utilizando el mismo medio de pago empleado
          por usted para la transacción inicial, a no ser que haya usted
          dispuesto expresamente lo contrario; en todo caso, no incurrirá en
          ningún gasto como consecuencia del reembolso.
          <br />
          <br />
          <b>Modelo de formulario de desistimiento</b>
          <br />
          <br />
          (sólo debe cumplimentar y enviar el presente formulario si desea
          desistir del contrato)
          <br />
          <br />
          <ul>
            <li>
              A la atención de Life5, Paseo de la{" "}
              <a href="mailto:asesores@axa.life5.es">asesores@axa.life5.es</a>:
            </li>
            <li>
              Por la presente le comunico/comunicamos () que desisto de
              mi/desistimos de nuestro () contrato de venta del siguiente
              bien/prestación del siguiente servicio (*)
            </li>
            <li>Contratado el/pedido el/recibido el (*)</li>
            <li>
              Nombre del consumidor y usuario o de los consumidores y usuarios
            </li>
            <li>
              Domicilio del consumidor y usuario o de los consumidores y
              usuarios
            </li>
            <li>
              Firma del consumidor y usuario o de los consumidores y usuarios
              (solo si el presente formulario se presenta en papel)
            </li>
            <li>Fecha</li>
          </ul>
          <br />
          (*) Táchese lo que no proceda.
        </Typography>
      </section>
      <section className="legalPages--section">
        <Typography
          variant="H3"
          weight="700"
          text=""
          className="legalPages--sectionHeading"
        >
          Zonas restringidas en el sitio web
        </Typography>
        <Typography variant="BodyMedium" weight="400" text="">
          El acceso general al Sitio Web de LIFE5 no requiere la suscripción o
          el registro por parte de los Usuarios.
          <br />
          <br />
          Sin embargo, el acceso a la plataforma de contratación estará
          condicionado a la obtención previa de la condición de Cliente, y a la
          asignación de un identificador y su correspondiente contraseña,
          establecidas por el Cliente a través de su formulario de registro.
          Dicho registro se realizará a través de los pasos establecidos en el
          Sitio Web.
          <br />
          <br />
          El Usuario que no haya obtenido previamente las correspondientes
          credenciales, otorgadas por LIFE5, no intentará acceder a las zonas de
          acceso restringido, ni intentará soslayar o manipular los mecanismos
          de protección establecidos en dicho acceso.
          <br />
          <br />
          El acceso no autorizado a las zonas de acceso restringido por Usuarios
          que no hayan sido debidamente autorizados estará sujeto a
          responsabilidad civil y/o penal.
        </Typography>
      </section>
      <section className="legalPages--section">
        <Typography
          variant="H3"
          weight="700"
          text=""
          className="legalPages--sectionHeading"
        >
          Responsabilidad por los daños y perjuicios de los usuarios
        </Typography>
        <Typography variant="BodyMedium" weight="400" text="">
          <b>Responsabilidad del usuario</b>
          <br />
          <br />
          Los Usuarios responderán de los daños y perjuicios de toda naturaleza
          que LIFE5 pueda sufrir, directa o indirectamente, como consecuencia
          del incumplimiento de cualquiera de las obligaciones derivadas de las
          Condiciones Generales de Uso o por la reproducción total o parcial no
          autorizada expresamente por escrito, para cualquier finalidad.
          <br />
          <br />
          Es responsabilidad del Usuario adoptar todas las medidas técnicas
          adecuadas para controlar razonablemente las amenazas que pueden surgir
          durante las transmisiones de datos e información que tienen lugar en
          Internet. Entre dichas medidas, el Usuario deber tener sistemas
          actualizados de detección de software malicioso, tales como virus,
          troyanos, etc., tener actualizados los parches de seguridad de los
          correspondientes navegadores y sistemas operativos, así como utilizar
          redes cuya seguridad esté verificada.
          <br />
          <br />
          <b>Responsabilidad de Life5</b>
          <br />
          <br />
          LIFE5 toma medidas para garantizar la exactitud de la información del
          Sitio Web.
          <br />
          <br />
          La información respecto a los productos y servicios que ofrece Life5
          en el Sitio Web debe considerarse a modo de introducción y no refleja
          todos los términos y condiciones aplicables a cada uno de los
          productos y/o servicios comercializados por Life5, teniendo un mero
          carácter indicativo, orientativo y estimativo.
          <br />
          <br />
          Igualmente Life5 no garantiza la utilidad de su Sitio Web o de sus
          servicios para ninguna actividad en particular por lo que el acceso al
          Sitio Web y el uso de sus contenidos se realiza bajo la
          responsabilidad del usuario.
          <br />
          <br />
          Nuestra mayor preocupación es proteger la información. Guardamos la
          información que recogemos en servidores seguros y cumplimos con las
          medidas de seguridad requeridas por la legislación española sobre
          protección de datos para proteger su información personal.
          <br />
          <br />
          No obstante, ninguna transmisión de Internet es 100% segura o está
          libre de errores, ni tampoco los datos guardados son invulnerables. No
          podemos garantizar la seguridad de nuestro Sitios Web, bases de datos
          o servicios, así como tampoco podemos garantizar que la información
          que nos envíe no sea interceptada mientras nos la está transmitiendo a
          través de Internet. No somos responsables de las acciones de terceros.{" "}
        </Typography>
      </section>
      <section className="legalPages--section">
        <Typography
          variant="H3"
          weight="700"
          text=""
          className="legalPages--sectionHeading"
        >
          Propiedad intelectual e industrial
        </Typography>
        <Typography variant="BodyMedium" weight="400" text="">
          Todos los contenidos del Sitio Web y de la Newsletters, incluyendo, a
          título enunciativo no limitativo, las marcas comerciales, logotipos,
          gráficos, imágenes, archivos de sonido y vídeo, código de
          programación, textos, iconos, y los nombres que identifican servicios
          (en lo sucesivo, el «Contenido«) son propiedad de LIFE5 incluyendo el
          material de prensa y los contenidos de la web, y están protegidos por
          la legislación vigente en materia de derechos de propiedad intelectual
          e industrial.
          <br />
          <br />
          El acceso del Usuario a este Sitio Web no implica ningún tipo de
          renuncia, transmisión ni cesión, total o parcial, de dichos derechos
          por parte de los titulares, por esta razón. Por tanto, el acceso a
          estos Contenidos por parte de los Usuarios no les otorga, bajo ningún
          concepto, la posibilidad de su copia, venta, modificación,
          reproducción, publicación, cesión, transmisión, o creación de nuevos
          productos o servicios derivados de los Contenidos del Sitio Web.
          <br />
          <br />
          Es por ello que el Usuario de este Sitio Web no podrá, salvo
          autorización previa, expresa y por escrito de LIFE5 o de terceros
          titulares de derechos de propiedad intelectual o industrial, copiar,
          distribuir, descargar, modificar, suprimir, borrar, alterar, publicar,
          transmitir, o aprovecharse de cualquier forma de dichos Contenidos.
          <br />
          <br />
          En este sentido, el Usuario no adquiere derechos o licencia en
          relación a ningún derecho de propiedad intelectual o industrial
          relacionados con el Sitio Web ni los Contenidos del mismo, más allá
          del derecho a navegar por el presente Sitio Web de acuerdo con la
          finalidad y los términos expuestos en el presente Aviso Legal.
        </Typography>
      </section>
      <section className="legalPages--section">
        <Typography
          variant="H3"
          weight="700"
          text=""
          className="legalPages--sectionHeading"
        >
          Política de enlaces
        </Typography>
        <Typography variant="BodyMedium" weight="400" text="">
          <b>Establecimiento de enlaces con el sitio web</b>
          <br />
          <br />
          Queda prohibido el establecimiento de enlaces con destino al Sitio Web
          sin contar con la autorización de LIFE5 de forma previa, expresa y por
          escrito.
          <br />
          <br />
          En caso de que se autorice el enlace por LIFE5 de acuerdo con los
          términos previamente establecidos, el mismo deberá establecerse en los
          siguientes términos:
          <br />
          <br />
          <ol>
            <li>
              No se realizarán desde la página web que introduce el enlace (en
              lo sucesivo, “Sitio Enlazante”) manifestaciones falsas, inexactas
              o incorrectas sobre el Sitio Web y/o LIFE5.
            </li>
            <li>
              En ningún caso la autorización otorgada por LIFE5 para la
              realización de un enlace significa cualquier tipo de patrocinio,
              colaboración, verificación o supervisión del contenido y/o los
              servicios que se prestan a través del Sitio Enlazante ni que sea
              responsable del contenido de dicho sitio.
            </li>
            <li>
              El Sitio Enlazante deberá cumplir fielmente con la Ley y no podrá,
              bajo ninguna circunstancia, albergar contenidos, propios o de
              terceros, que: (i) sean ilícitos, nocivos o contrarios a la moral,
              al orden público y a las buenas costumbres; o (ii) induzcan o
              puedan inducir en el Usuario la falsa concepción de que LIFE5
              suscribe, se adhiere o de cualquier manera apoya, las ideas,
              manifestaciones o expresiones, lícitas o ilícitas del remitente; o
              (iii) resulten inapropiados o no pertinentes en relación con la
              actividad de LIFE5.
            </li>
          </ol>
          <br />
          En el supuesto de incumplimiento de cualquiera de los términos antes
          enunciados, LIFE5 procederá de forma inmediata y sin previa
          notificación a la supresión del enlace.
          <br />
          <br />
          <b>Enlaces externos desde el sitio web</b>
          <br />
          <br />
          Cualquier acceso a algún sitio web de terceros a través de la
          navegación por el Sitio Web no comporta recomendación, promoción,
          identificación o conformidad de LIFE5 con las manifestaciones,
          contenidos o servicios proporcionados a través de dicho sitio Web.
          <br />
          <br />
          En el momento en el que el Usuario hace uso de un enlace publicado en
          el Sitio Web y es redireccionado a un sitio web de un tercero, está
          abandonando el Sitio Web y, por tanto, deja de estar bajo la
          protección de las presentes Condiciones Generales de Uso.
          <br />
          <br />
          En consecuencia, LIFE5 no se hace responsable del contenido de dichos
          sitios web de terceros, ni de sus condiciones de uso y políticas de
          privacidad, siendo el Usuario de dichos sitios Web el único
          responsable de comprobarlos y aceptarlos cada vez que acceda y use los
          mismos.
        </Typography>
      </section>
      <section className="legalPages--section">
        <Typography
          variant="H3"
          weight="700"
          text=""
          className="legalPages--sectionHeading"
        >
          Política de Cookies
        </Typography>
        <Typography variant="BodyMedium" weight="400" text="">
          LIFE5 utiliza cookies por cuenta propia o de un tercero contratado
          para la prestación de servicios de medición de audiencias. Las cookies
          utilizadas en el Sitio Web, se asocian únicamente con un usuario
          anónimo y su dispositivo móvil o PC, por lo que no proporcionan por sí
          los datos personales del Usuario.
          <br />
          <br />
          Para obtener más información relacionada con las cookies utilizadas en
          el presente Sitio Web, el Usuario puede acceder a nuestra Política de
          Cookies.{" "}
        </Typography>
      </section>
      <section className="legalPages--section">
        <Typography
          variant="H3"
          weight="700"
          text=""
          className="legalPages--sectionHeading"
        >
          Derecho de exclusión
        </Typography>
        <Typography variant="BodyMedium" weight="400" text="">
          LIFE5 se reserva el derecho a denegar o retirar el acceso al portal
          y/o a los servicios ofrecidos sin necesidad de preaviso, a instancia
          propia o de tercero, a aquellos usuarios que incumplan las presentes
          Condiciones Generales de Uso. Para obtener más información relacionada
          con las cookies utilizadas en el presente Sitio Web, el Usuario puede
          acceder a nuestra Política de Cookies.
        </Typography>
      </section>
      <section className="legalPages--section">
        <Typography
          variant="H3"
          weight="700"
          text=""
          className="legalPages--sectionHeading"
        >
          Ley aplicable y jurisdicción
        </Typography>
        <Typography variant="BodyMedium" weight="400" text="">
          “Nuestra entidad se encuentra adherida a Confianza Online (Asociación
          sin ánimo de lucro), inscrita en el Registro Nacional de Asociaciones
          Grupo 1, Sección 1, número nacional 594400, CIF G85804011, Carrera de
          San Jerónimo, 18, 4º 1, 28014 Madrid (España). Para más información:{" "}
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.confianzaonline.es/"
          >
            www.confianzaonline.es
          </a>
          <br />
          Estas Condiciones Generales se rigen por la ley española. Así mismo,
          en cumplimiento de lo dispuesto en la Ley de Resolución Alternativa de
          Litigios, informamos a los consumidores que, como entidad adherida y
          en los términos del Código Ético, los usuarios podrán acudir a
          Confianza Online para la resolución alternativa de eventuales
          controversias (
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.confianzaonline.es/como-reclamar/formulario-de-reclamaciones/"
          >
            https://www.confianzaonline.es/como-reclamar/formulario-de-reclamaciones/
          </a>
          ). Si éstas se refieren a transacciones electrónicas con consumidores,
          o sobre protección de datos cuando estén relacionadas con este ámbito,
          las reclamaciones serán resueltas por el Comité de Mediación de
          Confianza Online, acreditado para la resolución alternativa de
          litigios en materia de consumo. Si las reclamaciones versan sobre
          publicidad digital, o sobre protección de datos relacionada con este
          ámbito, serán sometidas al Jurado de la Publicidad de AUTOCONTROL.
          <br />
          Además te recordamos que puedes acceder a la plataforma de resolución
          de litigios en línea de la Unión Europea siguiendo este enlace:{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://ec.europa.eu/consumers/odr/main/"
          >
            ec.europa.eu/consumers/odr/main/
          </a>
          .
          <br />
          Para obtener más información relacionada con las cookies utilizadas en
          el presente Sitio Web, el Usuario puede acceder a nuestra Política de
          Cookies.
        </Typography>
      </section>
    </main>
  );
};

export default TermsAndConditions;
