import { ButtonDS, Illustration, Typography } from "get-life-storybook-ts";
import { I18nContext } from "../../../../../context/i18n.context";
import { useContext, useEffect, useState } from "react";
import { GetlifeContext } from "../../../../../context/GetLifeContext.context";
import { PHONE } from "../../../../../constants/Globals";
import "./../css/waitingValidation.scss";

export default function WaitingSignature() {
  const [illustration, setIllustration] = useState("");
  const {
    state: { translate },
  } = useContext(I18nContext);

  const { handleCallMe } = useContext(GetlifeContext);

  const getRandomizedIllustration = () => {
    const illustrations = ["SigningIllustration", "ReadingIllustration"];
    return illustrations[Math.floor(Math.random() * illustrations.length)];
  };

  useEffect(() => {
    setIllustration(getRandomizedIllustration());
  }, []);

  return (
    <section className="waitingValidation-section">
      <div className="waitingValidation-illustration">
        <Illustration
          illustration={illustration}
          height={"264"}
          width={"unset"}
        />
      </div>
      <Typography
        variant="H1"
        text={translate("waitingValidation.title")}
        weight="700"
        className="waitingValidation-title"
      />
      <Typography
        variant="Body"
        weight="400"
        text={""}
        className="waitingValidation-body"
      >
        <span
          dangerouslySetInnerHTML={{
            __html: translate("waitingValidation.description"),
          }}
        />
      </Typography>
      <div className="waitingValidation-buttons">
        <ButtonDS
          label={translate("waitingValidation.call")}
          leftIcon="PhoneCallIcon"
          className="waitingValidation-button"
          href={`tel:${PHONE}`}
        />
        <ButtonDS
          label={translate("waitingValidation.bookCall")}
          leftIcon="HeadPhones"
          buttonType="secondary"
          className="waitingValidation-button"
          onClick={handleCallMe}
        />
      </div>
    </section>
  );
}
