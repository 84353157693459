import { reorderDate } from "get-life-storybook-ts";
import React from "react";
import { GetLifeCalculatorContext } from "../../../context/GetLifeCalculator.context";

const CalculatorPageESController = () => {
  const contextCalculator = React.useContext(GetLifeCalculatorContext);

  const handleClickOrganic = () => {
    contextCalculator.handleClickOrganic({
      birthDate: reorderDate(contextCalculator.dateOfBirth),
      campaign: contextCalculator.gtf_campaign || "calculator-app",
      capital: parseFloat(contextCalculator.insuranceCover),
      email: contextCalculator.email,
      gender: contextCalculator.sex,
      initialCapital: contextCalculator.minCapital,
      phone: contextCalculator.phoneNumber,
      ucc: contextCalculator.ucc
    });
  };

  return { contextCalculator, handleClickOrganic };
};

export default CalculatorPageESController;
