import { useEffect, useState } from "react";

const CouponController = ({ couponCode }: { couponCode?: string }) => {
  const [code, setCode] = useState<string>(couponCode || "");
  const [disabled, setDisabled] = useState<boolean>(!code);

  const handleChange = (value: string) => {
    setDisabled(false);
    setCode(value);
  };

  useEffect(() => {
    if (!!couponCode && couponCode !== code) {
      setCode(couponCode);
    }
  }, [couponCode]);

  return {
    code,
    disabled,
    handleChange,
  };
};

export default CouponController;
