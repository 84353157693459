export const getCookie = (name: string, defaultValue: string | null = null) => {
  const isBrowser = typeof window !== "undefined";

  return (
    (isBrowser &&
      document.cookie.split("; ").reduce((r, v): any => {
        const parts = v.split("=");
        if (parts[0] === name) {
          if (parts[1] === "") return "defined";
          if (parts[1] === "null") return null;
          return decodeURIComponent(parts[1]);
        } else {
          return r;
        }
      }, "")) ||
    defaultValue
  );
};
