import * as React from "react";
import { I18nContext } from "../../../../context/i18n.context";
import "./Welcome.styles.scss";

const WelcomeSQL: React.FC = () => {
  const {
    state: { translate },
  } = React.useContext(I18nContext);

  return (
    <div className="flex flex-col gap-3 flex-1 px-[24px] md:px-[0px]">
      <span className="H1 font-bold text-[var(--theme-primary)]">
        {translate("welcome.sql.title")}
      </span>
      <span className="BodyL text-[var(--dark-gray)]">
        {translate("welcome.sql.subtitle")}
      </span>
    </div>
  );
};

export default WelcomeSQL;
