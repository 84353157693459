import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router";
import Lottie from "lottie-react";
import Animation from "./../../Components/lotties/lottie_post_stack.json";
import "./../lotties/prePostStackAnimation.scss";
import { I18nContext } from "../../../context/i18n.context";

export default function PrePostStackAnimation() {
  const navigate = useNavigate();
  const navigateToCoverage = () => navigate("/confirmation");
  useEffect(() => {
    setTimeout(function () {
      navigateToCoverage();
    }, 2000);
  });
  const {
    state: { translate },
  } = useContext(I18nContext);
  return (
    <div className="presPostStackAnimation-container">
      <Lottie
        animationData={Animation}
        className="presPostStackAnimation-animation"
      />
      <p className="presPostStackAnimation-p">{translate("animation.title")}</p>
      <p className="presPostStackAnimation-p">{translate("animation.text")}</p>
    </div>
  );
}
