import {
  ButtonDS,
  FormCustom,
  GlobalIcon,
  RenderTrustpilot,
  TableInfo,
  Typography,
  UpsellIconSection,
} from "get-life-storybook-ts";
import FacilePartnerController from "./FacilePartnerController";
import {
  arrayForm,
  buttonForm,
  dataTable,
  faqsList,
  headerTable,
} from "./constants";

import "./FacilePartner.scss";
import ConditionsSummary from "./ConditionsSummary/ConditionsSummary";
import AccordionCustom from "../../components/AccordionCustom/AccordionCustom.component";

const FacilePartner = () => {
  const { translate, leadData, form, sendData, prices, handleCallMe } =
    FacilePartnerController();

  return (
    <div className="partnerPage">
      <div className="organic">
        <div className="organic__header">
          <Typography
            text={translate("partners.header.title")}
            variant="H1"
            weight="700"
          />
          <Typography text={""} variant="H3" weight="500">
            {translate("partners.header.subtitle")}
            <GlobalIcon iconName="FacileIcon" />
          </Typography>
        </div>
        <div className="organic__information">
          <ConditionsSummary prices={prices} capital={leadData?.capital} />
        </div>
        {arrayForm && (
          <FormCustom
            arrayForms={arrayForm(translate)}
            buttonConfig={buttonForm(translate)}
            configForm="organic__form"
            errorMessage={translate("partners.form.error.global")}
            formTitle={translate("partners.form.title")}
            initialValues={form}
            submitForm={sendData}
          />
        )}
      </div>
      <div>
        <UpsellIconSection
          title={translate("newUpsell.iconSection.title")}
          data={[
            {
              icon: "CalendarOutlineIcon",
              text: translate("newUpsell.iconSection.text1"),
            },
            {
              icon: "SaveMoneyIcon",
              text: translate("newUpsell.iconSection.text2"),
            },
            {
              icon: "PriceIcon",
              text: translate("newUpsell.iconSection.text3"),
            },
          ]}
        />
      </div>
      <div className="comparison">
        <div className="comparison__table">
          <TableInfo
            title={translate("partners.table.title")}
            data={dataTable(translate)}
            columns={headerTable(translate) as any}
          />
        </div>
        <div className="comparison__CTA">
          <Typography
            text={translate("partners.comparison.cta.titleCTA")}
            variant="H2"
            weight="700"
          />
          <Typography text={""} variant="Body" weight="400">
            <span
              dangerouslySetInnerHTML={{
                __html: translate("partners.comparison.cta.paragraph"),
              }}
            />
          </Typography>
          <ButtonDS
            label={translate("partners.comparison.cta.callUsButton")}
            leftIcon="PhoneCallIcon"
            buttonType="tertiary"
            onClick={handleCallMe}
          />
        </div>
      </div>
      <RenderTrustpilot
        comments={[
          {
            commentText: translate("truspilot.opinion.text1"),
            commentUser: translate("truspilot.opinion.name1"),
          },
          {
            commentText: translate("truspilot.opinion.text2"),
            commentUser: translate("truspilot.opinion.name2"),
          },
          {
            commentText: translate("truspilot.opinion.text3"),
            commentUser: translate("truspilot.opinion.name3"),
          },
          {
            commentText: translate("truspilot.opinion.text4"),
            commentUser: translate("truspilot.opinion.name4"),
          },
          {
            commentText: translate("truspilot.opinion.text5"),
            commentUser: translate("truspilot.opinion.name5"),
          },
          {
            commentText: translate("truspilot.opinion.text6"),
            commentUser: translate("truspilot.opinion.name6"),
          },
          {
            commentText: translate("truspilot.opinion.text7"),
            commentUser: translate("truspilot.opinion.name7"),
          },
          {
            commentText: translate("truspilot.opinion.text8"),
            commentUser: translate("truspilot.opinion.name8"),
          },
        ]}
        excelentText={translate("newUpsell.truspilotSection.excelentText")}
        rating={4.8}
        title={translate("newUpsell.truspilotSection.title")}
        trustpilotText={translate("newUpsell.truspilotSection.trustpilotText")}
      />
      <div className="faqs">
        <Typography
          text={translate("partners.faqs.title")}
          variant="H3"
          weight="700"
          className="faqs__title"
        />
        {faqsList && faqsList.length > 0
          ? faqsList.map((faq) => {
              const { title, description } = faq;
              return (
                <AccordionCustom
                  key={`${title}_${description}`}
                  headerContent={translate(title)}
                  accordionContent={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: translate(description),
                      }}
                    />
                  }
                />
              );
            })
          : null}
      </div>
    </div>
  );
};

export default FacilePartner;
