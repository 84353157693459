import { useDevice } from "get-life-storybook-ts";
import { useEffect, useContext } from "react";
import HttpIntegrationsRepository from "../../../api/request/Integrations/Integrations.service";
import { GetlifeContext } from "../../../context/GetLifeContext.context";
import LoadingSpinner from "../../../views/Calculator/LoadingSpinner/LoadingSpinner";

const WidgetVelogica = (props: any) => {
  const { leadId, setQuestion, setTokenVelogica, tokenVelogica } =
    useContext(GetlifeContext);

  const integrationsRepository = new HttpIntegrationsRepository();

  const { isMobile } = useDevice();

  const fetchVelogica = async () => {
    try {
      const data = await integrationsRepository.getToken(leadId);
      setTokenVelogica(data.access_token);
      setQuestion(JSON.stringify(props.question));
    } catch (error) {}
  };

  useEffect(() => {
    (async () => {
      if(!tokenVelogica){
        await fetchVelogica();
      }
    })();
  }, []);

  return (
    <div>
      {!tokenVelogica ? (
        <LoadingSpinner />
      ) : (
        <iframe
          title="Velogica iframe"
          width={isMobile ? "360px" : "800px"}
          style={{height: isMobile ? "calc(80vh - 80px)" : "900px"}}
          src={`${process.env.REACT_APP_VELOGICA_IFRAME}${tokenVelogica}`}
        />
      )}
    </div>
  );
};

export default WidgetVelogica;
