export const questionsPostPayment = (translate: (key: string) => string, DOMAIN : string) => {
  return [
    {
      name: "nationality",
      type: "nationality",
      content: "Nacionalidad, residencia y documento de identidad",
      subtitle:
        "¿Cuál es tu nacionalidad, país de residencia y documento de identidad (DNI/NIE)?",
      componentName: null,
      answers: [
        { text: "Nacionalidad", value: "Seleccionar de la lista" },
        { text: "Residencia", value: "Seleccionar de la lista" },
        {
          text: "Documento de identidad",
          value: "Ej. 34234876K",
          validations: {
            name: "dni",
            type: "string",
            errorText: "Introduce un NIF/NIE válido",
            required: true,
            requiredText: "Este campo es obligatorio",
            regExp: "idNumberFunction",
          },
        },
      ],
    },
    {
      name: "adress",
      type: "formAdress",
      content: "introduce tu direccion",
      componentName: null,
      contentP:
        "Necesitamos tu dirección postal para saber a dónde dirigirnos. ¿Podrías indicarme por favor tu dirección?",
      subtitle: "¡Si has llegado hasta aquí, no te falta nada!",
    },
    {
      name: "beneficiaries",
      type: "beneficiaries",
      content: translate("coverage.beneficiary.question"),
      subtitle: DOMAIN !== "it" ? translate("coverage.beneficiary.subtittle") : null,
      componentName: null,
    },
    {
      name: "beneficiariesFreeSelection",
      type: "checkbox",
      content:
        "Escoge a continuación qué clase de beneficiario recibirá el capital (puedes escoger más de un tipo)",
      subtitle: "¡Perfecto!",
      componentName: null,
      answers: [
        {
          label: "Persona física (por ejemplo, un amigo o un hijo)",
          value: "physicalPerson",
        },
        {
          label: "Persona jurídica (por ejemplo, una ONG)",
          value: "legalPerson",
        },
        {
          label: "Entidad bancaria (por ejemplo, el banco donde tienes la hipoteca)",
          value: "bankingEntity",
        },
      ],
    },

    {
      name: "beneficiariesPhysicalPerson",
      type: "formBeneficiariesPhysicalPerson",
      componentName: null,
      content: "Has elegido Persona Física ",
      subtitle: "",
    },

    {
      name: "beneficiariesBankingEntity",
      type: "formBeneficiariesBankingEntity",
      componentName: null,
      content: "Si has elegido Entidad Bancaria ",
      subtitle: "",
    },
    {
      name: "beneficiariesLegalPerson",
      type: "formBeneficiariesLegalPerson",
      componentName: null,
      content: "Has elegido Persona Jurídica ",
      subtitle: "",
    },
    {
      name: "idCard",
      type: "idCard",
      componentName: null,
      content: "Sube tu DNI/NIE",
      subtitle:
        "Primero tienes que presentar la parte frontal de tu DNI/NIE y después la parte posterior.",
    },
  ];
};

export const beneficiariesQuestions = (translate: (key: string) => string) => [
  {
    name: "formBeneficiaries",
    type: "checkbox",
    componentName: null,
    answers:
     ( process.env.REACT_APP_DOMAIN_LOCALE === "es" || process.env.REACT_APP_DOMAIN_LOCALE === "pt" || process.env.REACT_APP_DOMAIN_LOCALE === "it")
        ? [
            {
              label: translate("coverage.beneficiary.physical"),
              value: "physicalPerson",
            },
            {
              label: translate("coverage.beneficiary.legal"),
              value: "legalPerson",
            },
            {
              label: translate("coverage.beneficiary.bank"),
              value: "bankingEntity",
            },
          ]
        : [
            {
              label: translate("coverage.beneficiary.physical"),
              value: "physicalPerson",
            },
            {
              label: translate("coverage.beneficiary.legal"),
              value: "legalPerson",
            },
          ],
    content: translate("coverage.beneficiary.title"),
    subtitle: "",
  },
  {
    name: "formBeneficiariesPhysicalPerson",
    type: "formBeneficiariesPhysicalPerson",
    componentName: null,
    content: "Introduce a continuación los detalles del beneficiario. ",
    subtitle:
      "Las personas físicas hacen referencia a personas como nosotros, como por ejemplo un primo, amigo o hermano. Puedes añadir tantos como quieras. ",
  },
  {
    name: "formBeneficiariesBankingEntity",
    type: "formBeneficiariesBankingEntity",
    componentName: null,
    content:
      "Introduce a continuación los detalles de la entidad bancaria y de tu préstamo/hipoteca. ",
    subtitle:
      "En caso de que hayas solicitado un préstamo o una hipoteca con el banco, ellos recibirán el importe que indiques a continuación en caso de siniestro. ",
  },

  {
    name: "formBeneficiariesLegalPerson",
    type: "formBeneficiariesLegalPerson",
    componentName: null,
    content: "Introduce a continuación los detalles de la persona jurídica.  ",
    subtitle:
      "Las personas jurídicas hacen referencia a empresas u organizaciones, como por ejemplo, la empresa de un hermano o una ONG. ",
  },
];
