/* eslint-disable no-template-curly-in-string */
import { useContext, useEffect, useState } from "react";
import "./css/notCoverage.scss";
import { handleHubspot } from "../../utils/HandleHubspot";
import AccordionCustom from "../../components/AccordionCustom/AccordionCustom.component";
import { I18nContext } from "../../context/i18n.context";
import { ButtonDS, Illustration, Typography } from "get-life-storybook-ts";
import { GetlifeContext } from "../../context/GetLifeContext.context";
import { PHONE_NOT_COVER } from "../../constants/Global";

const LOCALE = process.env.REACT_APP_DOMAIN_LOCALE;

export default function NotCoverage() {
  const [openAlternatives, setOpenAlternatives] = useState(true);
  const [openCover, setOpenCover] = useState(false);
  const [openStart, setOpenStart] = useState(false);
  const [illustration, setIllustration] = useState("");
  const {
    state: { translate },
  } = useContext(I18nContext);

  const { handleCallMe } = useContext(GetlifeContext);

  const handleOpenAlternatives = () => {
    setOpenAlternatives(!openAlternatives);
    setOpenCover(false);
    setOpenStart(false);
  };
  const handleOpenCover = () => {
    setOpenCover(!openCover);
    setOpenAlternatives(false);
    setOpenStart(false);
  };
  const handleOpenStart = () => {
    setOpenStart(!openStart);
    setOpenAlternatives(false);
    setOpenCover(false);
  };

  function openPhone() {
    window.open("tel:" + PHONE_NOT_COVER);
  }

  const ButtonHelp = () => (
    <ButtonDS
      label={translate("notCoverage.talkExpert")}
      leftIcon="PhoneCallIcon"
      buttonType="tertiary"
      ghost={true}
      onClick={openPhone}
      className="notCoverage-buttonHelp"
    />
  );

  useEffect(() => {
    localStorage.removeItem("token");
    localStorage.removeItem("lead");
    handleHubspot();
  }, []);

  const getRandomizedIllustration = () => {
    const illustrations = [
      "PhoneIllustration",
      "WorkerIllustration",
      "QuoteIllustration",
      "SearchIllustration"
    ];
    return illustrations[Math.floor(Math.random() * illustrations.length)];
  };

  useEffect(() => {
    setIllustration(getRandomizedIllustration());
  }, []);

  const faqAlternatives =
    {
      es: true,
      fr: true,
    }[LOCALE] | false;

  const faqExplanation =
    {
      es: true,
    }[LOCALE] | false;

  const faqRestart =
    {
      es: true,
    }[LOCALE] | false;

  const hasFaqs = faqAlternatives || faqExplanation || faqRestart;

  return (
    <section className="notCoverage-section">
      <div className="notCoverage-illustration">
        <Illustration
          illustration={illustration}
          height={"264"}
          width={"unset"}
        />
      </div>
      <Typography
        variant="H1"
        text={translate("notCoverage.apologies.title")}
        weight="700"
        className="notCoverage-title"
      />
      <Typography
        variant="Body"
        weight="500"
        text={translate("notCoverage.apologies.content")}
        className="notCoverage-body"
      />
      <div className="notCoverage-buttons">
        <ButtonDS
          label={translate("notCoverage.apologies.callUs").replace(
            "${telephone}",
            process.env.REACT_APP_TEL
          )}
          leftIcon="PhoneCallIcon"
          className="notCoverage-button"
          href={`tel:${process.env.REACT_APP_TEL}`}
        />
        <ButtonDS
          label={translate("notCoverage.apologies.weCallYou")}
          leftIcon="HeadPhones"
          buttonType="secondary"
          className="notCoverage-button"
          onClick={handleCallMe}
        />
      </div>
      {hasFaqs ? (
        <div className="notCoverage-faqs">
          {faqAlternatives ? (
            <AccordionCustom
              headerContent={translate("notCoverage.alternatives.title")}
              accordionContent={
                <div className="notCoverage-faqsText">
                  <p
                    className="notcoverage--accordion__text"
                    dangerouslySetInnerHTML={{
                      __html: translate("notCoverage.alternatives.first"),
                    }}
                  />
                  <ButtonHelp />
                </div>
              }
              handleOnClick={handleOpenAlternatives}
              expanded={openAlternatives}
            />
          ) : null}
          {faqExplanation ? (
            <AccordionCustom
              headerContent={
                <h3 className="notcoverage--accordion__title">
                  {translate("notCoverage.notCover.title")}
                </h3>
              }
              handleOnClick={handleOpenCover}
              expanded={openCover}
              accordionContent={
                <div className="notCoverage-faqsText">
                  <p
                    className="notcoverage--accordion__text"
                    dangerouslySetInnerHTML={{
                      __html: translate("notCoverage.notCover.first"),
                    }}
                  />
                  <ButtonHelp />
                </div>
              }
            />
          ) : null}
          {faqRestart ? (
            <AccordionCustom
              headerContent={
                <h3 className="notcoverage--accordion__title">
                  {" "}
                  {translate("notCoverage.startNewProcess.title")}
                </h3>
              }
              handleOnClick={handleOpenStart}
              expanded={openStart}
              accordionContent={
                <div className="notCoverage-faqsText">
                  <p
                    className="notcoverage--accordion__text"
                    dangerouslySetInnerHTML={{
                      __html: translate("notCoverage.startNewProcess.first"),
                    }}
                  />
                  <ButtonHelp />
                </div>
              }
            />
          ) : null}
        </div>
      ) : null}
    </section>
  );
}
