import * as React from "react";
import * as FullStory from "@fullstory/browser";
import posthog from 'posthog-js'
import HttpLeadRepository from "../api/request/Lead/Lead.service";
import { GetlifeContext } from "./GetLifeContext.context";
import TagManager from "react-gtm-module";
import { getCookie } from "../hooks/getCookie";

interface DataTrackerI {
  leadUlid?: string;
  campaign: string;
  utmSource?: string;
  utmMedium?: string;
}

interface FsEventI {
  FsEvent: string;
  data?: {
    leadId?: string
  };
}

interface IdentifyFSI {
  lead: string;
  data: {
    email: string;
    emailDomain: string;
    brokerId: string;
  };
}
interface ContextProps {
  handleTrackerQuestion: (value: HandleTrackerI) => void;
  identifyFS: (value: IdentifyFSI) => void;
}

export const GetLifeTrackerContext = React.createContext({} as ContextProps);

interface HandleTrackerI {
  type: "FS" | "tracker" | "GA" | "trackerCompare";
  data: any;
  event?: string;
}

export const GetLifeTrackerProvider = ({ children }: any) => {
  const {
    leadId,
    utm_campaign,
    utm_source,
    utm_content,
    utm_medium,
    utm_term,
    utm_test,
    gclid,
    msclkid,
    fbclid,
    gtf_campaign,
    tid,
    getEntryPage,
    getReferrerUrl,
    getABTestVersion,
  } = React.useContext(GetlifeContext);

  const leadRepository = new HttpLeadRepository(leadId);

  const VWOID = getCookie("_vwo_uuid_v2");
  const GAID = getCookie("_ga");
  const FullStoryID = getCookie("fs_uid");
  const AT_GD = getCookie("at_gd");
  const EXT_REFERENCE = getCookie("externalReference");

  const handleChangeDataTracker = ({
    leadUlid = leadId,
    campaign,
    utmSource,
    utmMedium
  }: DataTrackerI) => {
    try {
      leadRepository.track({
        leadUlid: leadUlid,
        googleAnalyticsId: GAID,
        fullstoryId: FullStoryID,
        vwoId: VWOID,
        utmCampaign: utm_campaign,
        utmSource: EXT_REFERENCE ? "facile" : utmSource ? utmSource : utm_source,
        utmMedium: EXT_REFERENCE ? "affiliate" : utmMedium ? utmMedium : utm_medium,
        utmContent: utm_content,
        utmTerm: utm_term,
        utmTest: utm_test,
        gclid: gclid,
        msclkid: msclkid,
        fbclid: fbclid,
        tid: tid,
        campaign: EXT_REFERENCE ? EXT_REFERENCE : campaign,
        atGd: AT_GD,
        entryPage: getEntryPage(),
        referrerUrl: getReferrerUrl(),
        aBTestVersion: getABTestVersion()
      });
    } catch (error) { }
  };

  const handleChangeDataTrackerCompare = ({
    leadUlid = leadId,
  }: DataTrackerI) => {
    try {
      leadRepository.trackCompare({
        leadUlid: leadUlid,
        googleAnalyticsId: GAID,
        fullstoryId: FullStoryID,
        vwoId: VWOID,
        utmCampaign: utm_campaign,
        utmSource: utm_source,
        utmMedium: utm_medium,
        utmContent: utm_content,
        utmTerm: utm_term,
        utmTest: utm_test,
        gclid: gclid,
        msclkid: msclkid,
        fbclid: fbclid,
        tid: tid,
        campaign: gtf_campaign,
        atGd: AT_GD,
        entryPage: getEntryPage(),
        referrerUrl: getReferrerUrl()
      });
    } catch (error) { }
  };

  const sendFullStoryEvent = ({ FsEvent, data = {} }: FsEventI) => {
    posthog.capture(FsEvent, data);
    FullStory.event(FsEvent, data);
    let dataEvent = {
      leadUlid: data.leadId,
      eventType: FsEvent,
      platform: "fullstory",
      createdAt: new Date(),
      payload: data
    }
    if (process.env.REACT_APP_ENVIROMENT === "PRD" || process.env.REACT_APP_ENVIROMENT === "TEST") {
      leadRepository.saveEvent(dataEvent)
    }
   
  };

  const handleChangeDataLayer = (newData: any) => {
    TagManager.dataLayer({ dataLayer: newData });
    let dataEvent = {
      leadUlid: newData.leadId,
      eventType: newData.event,
      platform: "ga4",
      createdAt: new Date(),
      payload: newData
    }
    if (process.env.REACT_APP_ENVIROMENT === "PRD" || process.env.REACT_APP_ENVIROMENT === "TEST") {
      leadRepository.saveEvent(dataEvent)
    }
  };

  const identifyFS = ({ lead, data }: IdentifyFSI) => {
    FullStory.identify(lead, data);
    posthog.identify(
      lead, // Required. Replace 'distinct_id' with your user's unique identifier
      { email: data.email },  // $set, optional
      { data } // $set_once, optional
  );
  };

  const handleTrackerQuestion = ({ type, event, data }: HandleTrackerI) => {
    const trackers = {
      FS: () => sendFullStoryEvent({ FsEvent: event!, data }),
      tracker: () => handleChangeDataTracker(data),
      trackerCompare: () => handleChangeDataTrackerCompare(data),
      GA: () => handleChangeDataLayer(data),
    };

    return trackers[type]();
  };
  return (
    <GetLifeTrackerContext.Provider
      value={{ handleTrackerQuestion, identifyFS }}
    >
      {children}
    </GetLifeTrackerContext.Provider>
  );
};
