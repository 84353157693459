import { Typography } from "get-life-storybook-ts";
import { AlertT } from "../context/GetLifeContext.context";
import { IconNameT } from "get-life-storybook-ts/lib/components/Icons/GlobalIcon";
import { ButtonI } from "get-life-storybook-ts/lib/atoms/Button/Button";
import {
    VariantType,
    WeightType,
} from "get-life-storybook-ts/lib/atoms/Typography/Typography";

export interface NotificationI {
    message: string | React.ReactNode;
    type?: AlertT;
    iconName?: IconNameT;
    positionY?: "top" | "bottom";
    positionX?: "right" | "left";
    time?: number;
    close?: boolean;
    color?: string;
    buttonAlertConfig?: {
        show: boolean;
        config: ButtonI;
    };
}

export interface NotificationMessageI {
    message: string;
    variant: VariantType;
    weight: WeightType;
}

interface NotificationProp {
    messages?: NotificationMessageI[];
}

export const getNotification = ({ messages }: NotificationProp): string | React.ReactNode => {
     return messages ? (
        <>
            {messages.map((msg, index) => (
                <Typography
                    key={`msg-${index}`}
                    variant={msg.variant}
                    text={msg.message}
                    weight={msg.weight}
                />
            ))}
        </>
    ) : (
        ""
    )
};
