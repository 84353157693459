import HttpGlobal from "../Global/HttpGlobal.class";
import { UpdatePaymentMethodI } from "./Interfaces/PaymentMethodI.request";
import {
  AvailablePaymentMethodI,
  DataCardI,
} from "./Interfaces/PaymentMethodI.response";

export default class HttpPaymentRepository extends HttpGlobal {
  private readonly API_URL_PAYMENT:
    | string
    | undefined = `${process.env.REACT_APP_CUSTOMER_APP_URL}api/payment`;

  private readonly API_URL_PAYMENT_METHOD:
    | string
    | undefined = `${process.env.REACT_APP_CUSTOMER_APP_URL}api/payment-method`;

  private readonly API_URL_BESTPRICE:
    | string
    | undefined = `${process.env.REACT_APP_CUSTOMER_APP_URL}api/best-price`;

  getBestPrice = async (token: string) => {
    const response = await this.generalGet(
      `${this.API_URL_BESTPRICE}/${token}`
    );
    return response;
  };

  getDataCard = async (): Promise<DataCardI> => {
    const response: DataCardI = await this.generalGet(
      `${this.API_URL_PAYMENT_METHOD}/get-last-four-digits/${this.leadId}`
    );
    return response;
  };

  availablePaymentMethod = async (): Promise<AvailablePaymentMethodI> => {
    const response: AvailablePaymentMethodI = await this.generalPost({
      url: `${this.API_URL_PAYMENT_METHOD}/available-payment-method`,
      dataRequest: { ulid: this.leadId },
    });
    return response;
  };

  setup = async () => {
    const response = await this.generalPost({
      url: `${this.API_URL_PAYMENT}/setup/${this.leadId}`,
      dataRequest: {},
    });

    return response;
  };

  updatePaymentMethod = async (data: UpdatePaymentMethodI) => {
    const response = await this.generalPut({
      url: `${this.API_URL_PAYMENT_METHOD}/update-payment-method`,
      dataRequest: data,
    });

    return response;
  };
}
