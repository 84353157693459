import { DateTime } from "luxon";

export const calculateDiffDays = (
    date: string,
    translate: (key: string) => string
  ) => {
    const actualDate: number = new Date().getTime();
    const effectDate = new Date(date).getTime();
  
    let diffDays =
      new Date(effectDate - actualDate).getTime() / (24 * 60 * 60 * 1000);
  
    let result = "";
  
    // diffDays = diffDays < 0.5 ? 1 : Math.ceil(diffDays);
    diffDays = Math.ceil(diffDays);
  
    if (diffDays <= 0) {
      result = `${translate("upsell.DatePicker.subtitle3")} ${translate(
        "upsell.DatePicker.textToday"
      )}`;
    } else if (diffDays === 1) {
      result = `${translate("upsell.DatePicker.subtitle3")} ${translate(
        "upsell.DatePicker.textTomorrow"
      )}`;
    } else if (diffDays === 2) {
      result = `${translate("upsell.DatePicker.subtitle3")} ${translate(
        "upsell.DatePicker.textAfterTomorrow"
      )}`;
    } else {
      result = `${translate("upsell.DatePicker.subtitle1")} ${
        diffDays + 1
      } ${translate("upsell.DatePicker.subtitle2")}`;
    }
    return result;
  };
  
export const calculateDiffDaysAndReturnDiff = (date: string) => {
  const actualDate: number = new Date().getTime();
  const effectDate = new Date(date).getTime();

  let diffDays =
    new Date(actualDate - effectDate).getTime() / (24 * 60 * 60 * 1000);

  return diffDays;
}

export const formatDate = (date: string) => {
    let fecha = date.split("-").reverse().join("-");
    return fecha;
};

export const add365DaysToDate = (date: string) => {
  if(!date) return date;
  
  const formatedDate = DateTime.fromFormat(date, 'yyyy-MM-dd');
  const futureDate = formatedDate.plus({ days: 365 }).toFormat('dd/MM/yyyy');

  return futureDate;
}

export const threeMonthsDate = new Date(
  new Date().setMonth(new Date().getMonth() + 3)
);

export const sixHoursDate = new Date(
  new Date().setTime(new Date().getTime() + 6 * 60 * 60 * 1000)
);