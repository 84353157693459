import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router";
import Add from "../../../../components/Components/Add/Add";
import Remove from "../../../../components/Components/Remove/Remove";
import "./formLegalPerson.scss";
import HttpLeadRepository from "../../../../api/request/Lead/Lead.service";
import { ButtonDS, NumberInput, TextInput } from "get-life-storybook-ts";
import { I18nContext } from "../../../../context/i18n.context";
import { submitValidationError } from "../../../../utils/validations";

const validations = [
  {
    name: "businessName",
    type: "string",
    errorText: "Caracteres no permitidos",
    required: true,
    requiredText: "Este campo es obligatorio",
    regExp:
      /^[a-zA-ZÀ-ÿ\u00f1\u00d1\.]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1\.]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1\.]+$/,
  },
  {
    name: "fiscalNumber",
    errorText: "Introduce un CIF válido",
    type: "string",
    required: true,
    requiredText: "Este campo es obligatorio",
    regExp: "cifNumberFunction",
  },
  {
    name: "capital",
    errorText: "Introduzca una cifra válida",
    required: true,
    requiredText: "Este campo es obligatorio",
    regExp: /^([0-9])*$/,
  },
];

export function Body(props) {
  const {
    index,
    legalPersons,
    legalPerson,
    error,
    errorMessage,
    translate,
    availableCapital,
  } = props;
  const [businessName, setBusinessName] = useState(legalPerson.businessName);
  const [fiscalNumber, setFiscalNumber] = useState(legalPerson.fiscalNumber);
  const [capital, setCapital] = useState(legalPerson.capital);

  useEffect(() => {
    setBusinessName(legalPerson.businessName);
    setFiscalNumber(legalPerson.fiscalNumber);
    setCapital(legalPerson.capital);
  }, [legalPerson]);

  useEffect(() => {
    if (typeof legalPersons[index] !== "undefined") {
      legalPersons[index].businessName = businessName;
      legalPersons[index].fiscalNumber = fiscalNumber;
      legalPersons[index].capital = capital;
      props.handleLegalPersons(legalPersons);
    }
  }, [businessName, fiscalNumber, capital]);

  return (
    <>
      <div className="grid-beneficiary">
        <div className="row">
          <TextInput
            initialValue={businessName}
            label={translate("legalperson.name")}
            onValueChange={setBusinessName}
            placeholder={translate("legalperson.name.placeholder")}
            //validationChecks={[(value) => validations(value, "name")]}
          />
          {error && error.businessName && (
            <p>{errorMessage[`businessName${index}`]}</p>
          )}
        </div>
        <div className="row">
          <div>
            <TextInput
              initialValue={fiscalNumber}
              label={translate("legalperson.idNumber")}
              onValueChange={setFiscalNumber}
              placeholder={translate("legalperson.idNumber.placeholder")}
              //validationChecks={[(value) => validations(value, "name")]}
            />
            {error && error[`fiscalNumber${index}`] && (
              <p>{errorMessage[`fiscalNumber${index}`]}</p>
            )}
          </div>
          <div className="capital">
            <NumberInput
              initialValue={capital}
              maxLength={availableCapital}
              label={translate("legalperson.capital")}
              onValueChange={setCapital}
              placeholder={translate(
                "physicalperson.personalData.capitalPlaceholder"
              )}
            />
            {error && error[`capital${index}`] && (
              <p>{errorMessage[`capital${index}`]}</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default function FormLegalPerson(props) {
  const navigate = useNavigate();
  const { token, leadId } = props;
  const [availableCapital, setAvailableCapital] = useState(null);
  const [legalPersons, setLegalPersons] = useState([]);
  const legalPerson = { businessName: "", fiscalNumber: "", capital: 0 };

  const leadRepository = new HttpLeadRepository(leadId, token);

  const {
    state: { translate },
  } = useContext(I18nContext);

  const [error, setError] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const handleLegalPersons = (e) => {
    setLegalPersons(e);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const fetchData = async () => {
      
      await leadRepository.getBeneficiaries().then((res) => {
        if (res.code === 401) {
          navigate("/login");
        } else {
          const filtered = res.beneficiaries.filter(function (obj) {
            if (obj.type === "legalPerson") {
              delete obj.id;
              delete obj.bankName;
              delete obj.name;
              delete obj.lastName;
              delete obj.lastName2;
              delete obj.idNumber;
              delete obj.loanMortgageNumber;
            }
            return obj.type === "legalPerson";
          });
          filtered.length === 0 && filtered.push(legalPerson);
          handleLegalPersons(filtered);
          let jetUsedCapital = 0;
          filtered.map(
            (legalPersonData, index) =>
              (jetUsedCapital += legalPersonData.capital)
          );
          setAvailableCapital(
            parseInt(res.availableCapital) + parseInt(jetUsedCapital)
          );
        }
      });
    };
    fetchData();
  }, []);

  useEffect(() => {
    props.handleValues(legalPersons);
  }, [legalPersons]);

  const addLegalPerson = () => {
    const newLegalPersons = [...legalPersons];
    newLegalPersons.push(legalPerson);
    handleLegalPersons(newLegalPersons);
  };

  const removeLegalPerson = (index) => {
    const newLegalPersons = [...legalPersons];
    newLegalPersons.splice(index, 1);
    handleLegalPersons(newLegalPersons);
  };

  const isButtonDisabled = () => {
    const inputFeilds = document.querySelectorAll("input");
    const validInputs = Array.from(inputFeilds).filter(
      (input) =>
        input.value === "" &&
        (input.name === "businessName" ||
          input.name === "fiscalNumber" ||
          input.name === "capital")
    );
    if (validInputs.length === 0) {
      return false;
    }
    return true;
  };

  const next = () => {
    if (
      !submitValidationError({
        beneficiaries: legalPersons,
        availableCapital,
        validations,
        translate,
        setError,
        setErrorMessage,
      })
    ) {
      props.nextQuestion();
    }
  };
  return (
    legalPersons && (
      <div className="formLegalPerson-container">
        <div className="formLegalPerson-GridContainer">
          {legalPersons.map((legalPersonObject, index) => (
            <>
              <Body
                key={index}
                index={index}
                legalPerson={legalPersonObject}
                legalPersons={legalPersons}
                handleLegalPersons={handleLegalPersons}
                error={error}
                setError={setError}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
                actualUrl={props.actualUrl}
                availableCapital={availableCapital}
                setAvailableCapital={setAvailableCapital}
                translate={translate}
              ></Body>
              {legalPersons.length !== 1 && (
                <div className="formPhysicalPerson-addContainer">
                  <div className="formPhysicalPerson-line"></div>
                  <Remove
                    content={translate("legalperson.remove")}
                    onClick={() => removeLegalPerson(index)}
                  />
                </div>
              )}
            </>
          ))}

          <div className="formLegalPerson-addContainer">
            <div className="formLegalPerson-line"></div>
            <Add
              content={translate("legalperson.add")}
              onClick={addLegalPerson}
            />
          </div>

          <div className="formLegalPerson-buttonContainer">
            <ButtonDS
              label={translate("continuar")}
              onClick={next}
              disabled={isButtonDisabled()}
            />
          </div>
        </div>
      </div>
    )
  );
}
