import { GlobalIcon, Typography } from "get-life-storybook-ts";
import {
  isEmail,
  isITPhone,
  isNotNull,
} from "get-life-storybook-ts/lib/components/Validations";
import { createRef } from "react";

export const checkBoxQuery = (translate: (key: string) => string) => (
  <>
    <div className="flex flex-col">
      <Typography
        variant="BodyMedium"
        text={""}
        color="ligth-gray"
      >
        <span
        dangerouslySetInnerHTML={{ __html: translate(
          "calculatorPage.acceptDataProcessing"
        ) as string }}
      />
      </Typography>
    </div>
  </>
);

export const checkBoxQuery2 = (translate: (key: string) => string) => (
  <>
    <div className="flex flex-col">
      <Typography
        variant="BodyMedium"
        text={""}
        color="ligth-gray"
      >
        <span
        dangerouslySetInnerHTML={{ __html: translate(
          "calculatorPage.acceptDataProcessing3"
        ) as string }}
      />
      </Typography>
    </div>
  </>
);

export const checkBoxQuery3 = (translate: (key: string) => string) => (
  <>
    <div className="flex flex-col">
    <Typography
        variant="BodyMedium"
        text={""}
        color="ligth-gray"
      >
        <span
        dangerouslySetInnerHTML={{ __html: translate(
          "calculatorPage.acceptDataProcessing2"
        ) as string }}
      />
      </Typography>
    </div>
  </>
);
export const buttonForm: any = (translate: (key: string) => string) => ({
  label: translate("partners.form.button.label"),
  buttonType: "primary",
  rightIcon: "ArrowRightIcon",
});

export const arrayForm: any = (translate: (key: string) => string) => [
  {
    inputType: "input",
    placeholder: translate("partners.form.email.placeholder"),
    label: translate("partners.form.email.label"),
    field: "email",
    validation: (value: string) =>
      isEmail({ value, message: translate("partners.form.error.email") }),
    ref: createRef(),
  },
  {
    inputType: "input",
    placeholder: translate("partners.form.phone.placeholder"),
    label: translate("partners.form.phone.label"),
    field: "phone",
    inputMode: "number",
    validation: (value: string) =>
      isITPhone({ value, message: translate("partners.form.error.phone") }),
    ref: createRef(),
  },
  {
    inputType: "checkboxSimple",
    label: checkBoxQuery(translate),
    isLegal: true,
    validation: (value: any) =>
      isNotNull({
        value,
        message: translate("partners.form.error.conditions"),
      }),
    field: "conditions",
    ref: createRef(),
  },
  {
    inputType: "checkboxSimple",
    label: checkBoxQuery3(translate),
    isLegal: true,
    validation: (value: any) => [],
    field: "conditions3",
    ref: createRef(),
  },
  {
    inputType: "checkboxSimple",
    label: checkBoxQuery2(translate),
    isLegal: true,
    validation: (value: any) =>
      isNotNull({
        value,
        message: translate("partners.form.error.conditions"),
      }),
    field: "conditions2",
    ref: createRef(),
  }
];

export const dataTable = (translate: (key: string) => string) => [
  {
    beneficiary: (
      <Typography
        text={translate("partners.table.firstRow")}
        variant="Body"
        weight="400"
        className="comparison__titleColumn"
      />
    ),
    getlife: <GlobalIcon iconName="FilledCheckIcon" />,
    others: <GlobalIcon iconName="CancelIcon" />,
  },
  {
    beneficiary: (
      <Typography
        text={translate("partners.table.secondRow")}
        variant="Body"
        weight="400"
        className="comparison__titleColumn"
      />
    ),
    getlife: <GlobalIcon iconName="FilledCheckIcon" />,
    others: <GlobalIcon iconName="CancelIcon" />,
  },
  {
    beneficiary: (
      <Typography
        text={translate("partners.table.thirdRow")}
        variant="Body"
        weight="400"
        className="comparison__titleColumn"
      />
    ),
    getlife: <GlobalIcon iconName="FilledCheckIcon" />,
    others: <GlobalIcon iconName="CancelIcon" />,
  },
  {
    beneficiary: (
      <Typography
        text={translate("partners.table.fourthRow")}
        variant="Body"
        weight="400"
        className="comparison__titleColumn"
      />
    ),
    getlife: <GlobalIcon iconName="FilledCheckIcon" />,
    others: <GlobalIcon iconName="CancelIcon" />,
  },
  {
    beneficiary: (
      <Typography
        text={translate("partners.table.fifthRow")}
        variant="Body"
        weight="400"
        className="comparison__titleColumn"
      />
    ),
    getlife: <GlobalIcon iconName="FilledCheckIcon" />,
    others: <GlobalIcon iconName="CancelIcon" />,
  },
];

export const headerTable = (translate: (key: string) => string) => [
  {
    header: (
      <Typography
        text={translate("partners.table.firstHeader")}
        variant="H3"
        weight="700"
        className="comparison__tableHeadingTitle"
      />
    ),
    ref: "beneficiary",
  },
  {
    header: (
      <span className="comparison__desktopLogo">
        <GlobalIcon iconName="LogoLife5Blue" />
      </span>
    ),
    ref: "getlife",
    styles: "comparison__oddColumn",
  },
  {
    header: (
      <Typography
        text={translate("partners.table.secondHeader")}
        variant="H3"
        weight="700"
        color="ligth-gray"
      />
    ),
    ref: "others",
  },
];

export const faqsList: { title: string; description: string }[] = [
  {
    title: "partners.faqs.title.first",
    description: "partners.faqs.description.first",
  },
  {
    title: "partners.faqs.title.second",
    description: "partners.faqs.description.second",
  },
  {
    title: "partners.faqs.title.three",
    description: "partners.faqs.description.three",
  },
  {
    title: "partners.faqs.title.four",
    description: "partners.faqs.description.four",
  },
  {
    title: "partners.faqs.title.five",
    description: "partners.faqs.description.five",
  },
  {
    title: "partners.faqs.title.six",
    description: "partners.faqs.description.six",
  },
];
