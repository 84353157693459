import * as React from "react";
import HttpIntegrationsRepository from "../../api/request/Integrations/Integrations.service";
import HttpLeadRepository from "../../api/request/Lead/Lead.service";
import { GetlifeContext } from "../../context/GetLifeContext.context";
import LoadingSpinner from "../Calculator/LoadingSpinner/LoadingSpinner";
import { useNavigate } from "react-router";

const ResumeVelogica = () => {

  const navigate = useNavigate();

  const {tokenVelogica, question, leadId, token} = React.useContext(GetlifeContext);

  const integrationsRepository = new HttpIntegrationsRepository();
  const leadRepository = new HttpLeadRepository(leadId, token);
  
  
  React.useEffect(() => {
    integrationsRepository.getResult(tokenVelogica).then((res) => {
      leadRepository
      .saveQuestion({
        answer: JSON.parse(res.result),
        questionId: JSON.parse(question).questionId,
      })
      .then(() => {
        window.parent.location.href = `${process.env.REACT_APP_WEB_URL}`; 
      })
      .catch(() => {
        navigate("/agent-confirmation")
      });
    }).catch(() => {
      navigate("/agent-confirmation")
    });
  }, []);

  return (
    <>
        <LoadingSpinner />
    </>
  );
};

export default ResumeVelogica;
