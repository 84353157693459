import {
  ButtonDS,
  GlobalIcon,
  IconNameT,
  Input,
  ModalDS,
} from "get-life-storybook-ts";
import React, { useContext, useState } from "react";
import { I18nContext } from "../../context/i18n.context";

type PhoneValidationStates = "validationCode" | "phoneNumber";

interface PhoneValidationModalI {
  open: boolean;
  setOpen: (value: boolean) => void;
  phoneValue: string;
  phoneOnBlur?: () => void;
  PhoneOnValueChange: (e: any) => void;
  phoneError: { error: boolean; message: string } | undefined;
  GeneratePhoneValidationCode: () => Promise<void>;
  ValidatePhoneValidationCode: (code: string) => Promise<void>;
}

const PhoneValidationModal = ({
  open,
  setOpen,
  phoneValue,
  phoneOnBlur,
  PhoneOnValueChange,
  phoneError,
  GeneratePhoneValidationCode,
  ValidatePhoneValidationCode,
}: PhoneValidationModalI) => {
  const {
    state: { translate },
  } = useContext(I18nContext);

  const [state, setState] = useState<PhoneValidationStates>("validationCode");
  const [code, setCode] = useState<string>("");
  const [codeError, setCodeError] = useState<{
    error: boolean;
    message: string;
  }>({ error: false, message: "" });
  const [loading, setLoading] = useState<boolean>(false);

  const SwitchToPhoneNumberState = () => setState("phoneNumber");

  const SwitchToValidationCodeState = () => setState("validationCode");

  const ConfirmNewPhoneNumber = async () => {
    setLoading(true);
    await GeneratePhoneValidationCode();
    setLoading(false);
    SwitchToValidationCodeState();
  };

  const ValidateCode = async () => {
    setLoading(true);
    const ValideCodeError = await ValidatePhoneValidationCode(code);
    setCodeError(ValideCodeError as any);
    setLoading(false);
  };

  const GenerateANewPhoneValidationCode = async () => {
    if (loading) return;
    setLoading(true);
    await GeneratePhoneValidationCode();
    setLoading(false);
  };

  const IsPhoneNumberButtonDisabled = () =>
    loading || (phoneError && phoneError.error === true);

  const getTitle = (): string => {
    if (state === "phoneNumber") return translate("phone.validation.modal.ChangePhone");
    return translate("phone.validation.modal.SMSValidation");
  };

  const getIcon = (): IconNameT => {
    if (state === "phoneNumber") return "DeviceMobile";
    return "DeviceMobileMessageIcon";
  };

  return (
    <ModalDS
      open={open}
      onClose={() => setOpen(false)}
      title={getTitle()}
      icon={getIcon()}
      content={
        <>
          {state === "validationCode" && (
            <>
              <div className="flex flex-col gap-[16px]">
                <span className="BodyM font-normal text-[#424242]">
                  {translate("phone.validation.modal.SMSSend")}
                </span>
                <div className="flex flex-col gap-[8px]">
                  <Input
                    initialValue={code}
                    inputMode="number"
                    pattern="\d*"
                    onValueChange={(e: any) => setCode(e)}
                    placeholder={translate("phone.validation.modal.CodePlaceholder")}
                    iconName="DeviceMobileMessageIcon"
                    initialError={codeError}
                  />
                  <span className="BodyS text-[#8E8E8E] font-normal">
                    <GlobalIcon
                      iconName="InfoIcon"
                      size="XXS"
                      color="currentColor"
                      className="inline-block mr-[4px] mb-[4px]"
                    />
                    {translate("phone.validation.modal.resendCode")}{" "}
                    <u
                      role="button"
                      tabIndex={0}
                      onClick={GenerateANewPhoneValidationCode}
                      onKeyDown={(e) => {
                        if (e.key === " " || e.key === "Enter") {
                          e.preventDefault();
                          GenerateANewPhoneValidationCode();
                        }
                      }}
                    >
                      {translate("phone.validation.modal.resendCodeUrl")}
                    </u>
                  </span>
                </div>
              </div>
              <div className="mt-[24px] flex flex-row flex-wrap gap-[8px] justify-center">
                <ButtonDS
                  label={translate("phone.validation.modal.EditNumber")}
                  buttonType="secondary"
                  ghost={true}
                  leftIcon="EditIcon"
                  className="flex-1 max-w-[350px] whitespace-nowrap"
                  onClick={SwitchToPhoneNumberState}
                  disabled={loading}
                />
                <ButtonDS
                  label={translate("phone.validation.modal.Continue")}
                  rightIcon="ArrowRightIcon"
                  className="flex-1 max-w-[350px] whitespace-nowrap"
                  onClick={ValidateCode}
                  disabled={loading}
                />
              </div>
            </>
          )}
          {state === "phoneNumber" && (
            <>
              <div className="flex flex-col gap-[16px]">
                <span className="BodyM font-normal text-[#424242]">
                  {translate("phone.validation.modal.TypeNumber")}
                </span>
                <div className="flex flex-col gap-[8px]">
                  <Input
                    initialValue={phoneValue}
                    inputMode="number"
                    pattern="\d*"
                    onBlur={phoneOnBlur ?? undefined}
                    onValueChange={PhoneOnValueChange}
                    placeholder={translate("phone.validation.modal.PhonePlaceholder")}
                    iconName="DeviceMobile"
                    initialError={phoneError}
                  />
                  <span className="BodyS text-[#8E8E8E] font-normal">
                    <GlobalIcon
                      iconName="InfoIcon"
                      size="XXS"
                      color="currentColor"
                      className="inline-block mr-[4px] mb-[4px]"
                    />
                    {translate("phone.validation.modal.CheckPhone")}
                  </span>
                </div>
              </div>
              <div className="mt-[24px] flex flex-row flex-wrap gap-[8px] justify-center">
                <ButtonDS
                  label={translate("phone.validation.modal.ConfirmPhone")}
                  leftIcon="CheckedIcon"
                  className="md:w-6/12 ml-auto whitespace-nowrap"
                  disabled={IsPhoneNumberButtonDisabled()}
                  onClick={ConfirmNewPhoneNumber}
                />
              </div>
            </>
          )}
        </>
      }
    />
  );
};

export default PhoneValidationModal;
