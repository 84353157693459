import React from "react";
import { CouponInfoI } from "../PaymentPage/PaymentPage.controller";
import "./Coupon.styles.scss";
import { Typography } from "get-life-storybook-ts";

export interface CouponInfoCompI {
  couponInfo: CouponInfoI;
  translate: (key: string) => string;
  couponName: string;
}

const CouponInfo: React.FC<CouponInfoCompI> = ({
  couponInfo,
  translate,
  couponName,
}): JSX.Element => {
  return (
    <div className="couponInfo">
      <Typography
        variant="Body"
        weight="700"
        className="couponInfo__title"
        text={couponName}
      />
      <div className="couponInfo__body">
        {couponInfo.code || couponInfo.code === "" ? (
          <>
            <Typography variant="Body" weight="700" text={couponInfo.conditions!} />
          </>
        ) : (
          <>
            <Typography variant="Body" weight="700" text={translate("coupons.error.message")} />
          </>
        )}
      </div>
      {couponInfo.exceptions && (
        <div className="couponInfo__body">
          <Typography
            variant="Body"
            weight="700"
            text={translate("coupons.exceptions")}
          />
          <Typography variant="Body" weight="700" text={couponInfo.exceptions} />
        </div>
      )}
    </div>
  );
};

export default CouponInfo;
