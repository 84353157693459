import React,{ useState } from "react";
import { useNavigate } from "react-router";
import logo from "../../assets/img/getlife-logo/getlife-azul.svg";
import "./css/reset.scss";
import HttpLeadRepository from "../../api/request/Lead/Lead.service";
import { ButtonDS, TextInput } from "get-life-storybook-ts";
import { I18nContext } from "../../context/i18n.context";

export default function Reset() {
  const [userEmail, setUserEmail] = useState("");
  const navigate = useNavigate();

  const {
    state: { translate },
  } = React.useContext(I18nContext);

  const leadRepository = new HttpLeadRepository();

  const handleSubmit = () => {
    leadRepository.getReset(userEmail);
    navigate("/resetDone");
  };
  const handleEmail = (e) => setUserEmail(e);

  return (
    <div className="loginPageSignin">
      <main className="loginPage--main">
        <img
          src={logo}
          alt="Logo de Getlife"
          title="Logo de Getlife"
          className="callMeBack--main__img"
        />
        <h6 className="loginPage--main__title">
          {translate("reset.title")}
        </h6>
        <form className="loginPage--main__form">
          <TextInput
            placeholder="E-mail"
            initialValue={userEmail}
            onValueChange={handleEmail}
          />
          <div className="form--btn">
            <ButtonDS
              disabled={
                userEmail === "" 
              }
              onClick={() => handleSubmit()}
            />
          </div>
        </form>
      </main>
    </div>
  );
}
