import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { CustomModal, Typography } from "get-life-storybook-ts";
import styles from "./BenefitsModal.module.scss";

export interface BenefitsModalI {
  title: string;
  benefits: {
    icon: React.JSX.Element;
    title: string;
    description: string;
  }[];
}

const BenefitsModal = forwardRef(({ title, benefits }: BenefitsModalI, ref) => {
  const refModal = useRef<any>();

  const handleShowModal = () => refModal.current.handleShowPortal();

  useImperativeHandle(ref, () => {
    return { handleShowModal };
  });

  return (
    <CustomModal
      ref={refModal}
      ModalTitle={
        <Typography
          text={title}
          variant="H3"
          weight="700"
          className={styles.title}
        />
      }
      ModalBody={
        <div className={styles.benefits}>
          {benefits.map((b) => {
            const { icon, title, description } = b;

            return (
              <div className={styles.benefit}>
                {icon}
                <div className={styles.content}>
                  <Typography
                    text={title}
                    variant="H3"
                    weight="700"
                    className={styles.title}
                  />
                  <Typography text={description} variant="Body" weight="400" />
                </div>
              </div>
            );
          })}
        </div>
      }
      fitContent
    />
  );
});

export default BenefitsModal;
