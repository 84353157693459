import HttpGlobal from "../Global/HttpGlobal.class";


export default class HttpLocationRepository extends HttpGlobal {

    private readonly API_URL_LOCATION: string | undefined = `${process.env.REACT_APP_CUSTOMER_APP_URL}api/location`

    private readonly API_URL_IDENTITY: string | undefined = `${process.env.REACT_APP_CUSTOMER_APP_URL}api/lead/identity`

    getAllZipCodesFR = async () => {
        const response = await this.generalGet(`${this.API_URL_LOCATION}/get-all-fr-postal-code`)
        return response
    }

    getValidateZipCode = async (zipCode: string) => {
        const response = await this.generalGet(`${this.API_URL_LOCATION}/validate-fr-postal-code/${zipCode}`)
        return response
    }

    getValidatePhoneNumber = async (phoneNumber: string) => {
        const response = await this.generalGet(`${this.API_URL_LOCATION}/validate-fr-phone-number/${phoneNumber}`)
        return response
    }
    
    getValidateCodiceFiscale = async (codiceFiscale: string) => {
        const response = await this.generalGet(`${this.API_URL_IDENTITY}/validate/${codiceFiscale}`)
        return response
    }
}