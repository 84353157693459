import { PHONE } from "./Globals";

interface ConstantTranslationProps {
  translate: (key: string) => string;
}

export const contactTranslations = ({
  translate,
}: ConstantTranslationProps) => {
  const secondParagraphText =
    translate("weCallYou.contact.secondParagraphText") !==
      "weCallYou.contact.secondParagraphText" &&
    !!translate("weCallYou.contact.secondParagraphText").trim()
      ? translate("weCallYou.contact.secondParagraphText")
      : "";
  return {
    titleText: translate("weCallYou.contact.titleText"),
    firstParagraphText: translate("weCallYou.contact.firstParagraphText"),
    secondParagraphText,
    thirdParagraphText: translate("weCallYou.contact.thirdParagraphText"),
    inputPlaceholderText: translate("weCallYou.contact.inputPlaceholderText"),
    buttonTextFirst: translate("weCallYou.contact.buttonTextFirst").replace(
      "$",
      PHONE || ""
    ),
    buttonTextSecond: translate("weCallYou.contact.buttonTextSecond"),
    isNull: translate("weCallYou.contact.isNull"),
  };
};

export const numberReceivedTranslations = ({
  translate,
}: ConstantTranslationProps) => {
  return {
    titleText: translate("weCallYou.numberReceived.titleText"),
    firstParagraphText: translate(
      "weCallYou.numberReceived.firstParagraphText"
    ),
    secondParagraphText: translate(
      "weCallYou.numberReceived.secondParagraphText"
    ).replace("$", PHONE || ""),
    buttonText: translate("weCallYou.numberReceived.buttonText"),
  };
};
