import { months } from "./staticData";
import { DateTime } from "luxon";

export const prepareListDaysOptions = function () {
  const days = [...Array(31).keys()];
  const optionsDays = [];
  days.forEach((element) =>
    optionsDays.push({
      value: String(element + 1),
      label: String(element + 1),
    })
  );

  return optionsDays;
};

export const prepareListMonthsOptions = function () {
  const optionsMonths = [];
  months.forEach((element, key) =>
    optionsMonths.push({ value: String(key + 1), label: String(element) })
  );

  return optionsMonths;
};

export const calcListYearOptions = () => {
  const today = new Date();
  const thisYear = today.getFullYear();
  const minYear = thisYear - 18;
  const maxYear = thisYear - 75;

  const validateYear = Array.from({ length: minYear - maxYear }, (_, i) => ({
    value: String(maxYear + i),
    label: String(maxYear + i),
  }));

  return validateYear;
};

export const validateIsAdult = (inserDate, translate) => {
  const actualDate = DateTime.now();
  const prueba = DateTime.fromISO(inserDate);

  return prueba > actualDate.minus({ years: 18 })
    ? {
        error: !0,
        message: translate("prestackQuestions.form.dateInput.notAdult").replace(
          "$",
          18
        ),
      }
    : { error: !1, message: "" };
};

export const validateIsNotElder = (inserDate, translate) => {
  const actualDate = DateTime.now();
  return DateTime.fromISO(inserDate) < actualDate.minus({ years: 75 })
    ? {
        error: !0,
        message: translate("prestackQuestions.form.dateInput.notElder").replace(
          "$",
          75
        ),
      }
    : { error: !1, message: "" };
};


export const validDateYear = (locale, format) => {
  const actualDate = DateTime.now();
  const nextYear = actualDate.plus({ year: 1 }).year;
  const newDate = DateTime.fromObject({
    year: nextYear,
    month: actualDate.month,
    day: 1,
  });

  return format
    ? newDate.setLocale(locale).toFormat(format)
    : newDate.setLocale(locale).toLocaleString(DateTime.DATETIME_FULL);
};

export const formatGetlifeDate = (date, domain) =>
  DateTime.fromISO(date).setLocale(domain).toFormat("dd-MM-yyyy");
