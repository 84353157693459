import { Typography } from "get-life-storybook-ts";

import "../../css/LegalPages.scss";

const CookiesPolicy = () => {
	return (
		<main className="legalPages legalPages--layout">
			<Typography
				variant="H2"
				weight="700"
				text=""
				className="legalPages--title"
			>
				Política de privacidad de Life5 (completa)
			</Typography>
			<section className="legalPages--section">
				<Typography
					variant="H3"
					weight="700"
					text=""
					className="legalPages--sectionHeading"
				>
					Responsable del tratamiento de sus datos:
				</Typography>
				<Typography variant="BodyMedium" weight="400" text="">
					<b>YOUR LIFE CORREDURÍA DE SEGUROS, S.L</b> CIF: B-42814236
					Calle Francisco de Rojas, 3 – 6ºD 28010 (Madrid) ESPAÑA
					<br />
					Teléfono: <a href="telf:919 495 510">919 495 510</a>
					<br />
					Email:{" "}
					<a href="mailto:asesores@axa.life5.es">
						asesores@axa.life5.es
					</a>
				</Typography>
			</section>
			<section className="legalPages--section">
				<Typography
					variant="H3"
					weight="700"
					text=""
					className="legalPages--sectionHeading"
				>
					Un poco de información sobre nuestras Cookies
				</Typography>
				<Typography variant="BodyMedium" weight="400" text="">
					En cumplimiento con lo previsto en el artículo 22.2 de la
					Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de
					la Información y de Comercio Electrónico y siguiendo las
					recomendaciones de la AEPD, la presente Política de Cookies
					tiene como finalidad informar al usuario sobre el uso y las
					funciones de las cookies. El usuario también tiene la
					posibilidad de retirar su consentimiento respecto a las
					Cookies que ya hayan sido instaladas. Asimismo, el usuario
					puede eliminarlas desinstalándolas de su dispositivo.
				</Typography>
			</section>
			<section className="legalPages--section">
				<Typography
					variant="H3"
					weight="700"
					text=""
					className="legalPages--sectionHeading"
				>
					¿Qué son las cookies?
				</Typography>
				<Typography variant="BodyMedium" weight="400" text="">
					Las cookies son ficheros que se instalan en el dispositivo
					del usuario cuando visita un sitio web. Las cookies se
					utilizan para garantizar la calidad, usabilidad y correcto
					funcionamiento de los sitios web, además de para almacenar y
					recuperar información sobre los hábitos de navegación en los
					mismos y personalizar sus contenidos, pudiendo utilizarse en
					ocasiones para reconocer al usuario de un sitio web.
					<br />
					Por lo tanto, las cookies son esenciales para el
					funcionamiento de Internet, y en ningún caso suponen un
					riesgo para tu equipo o tu dispositivo. Es más, pueden
					ayudarnos a reconocer la necesidad de mejoras en partes del
					sitio web y a detectar errores de funcionamiento.
				</Typography>
			</section>
			<section className="legalPages--section">
				<Typography
					variant="H3"
					weight="700"
					text=""
					className="legalPages--sectionHeading"
				>
					¿Qué tipos de cookies existen en nuestro sitio web?
				</Typography>
				<Typography variant="BodyMedium" weight="400" text="">
					Las cookies pueden clasificarse en diversos tipos, en
					función de diferentes criterios. A continuación, el usuario
					tiene información sobre los diferentes tipos de cookies que
					utilizamos en nuestro sitio web.
					<br />
					<br />
					1. Según la entidad que las gestiona
					<br />
					<br />
					<ul>
						<li>
							<b>Cookies propias</b> son aquellas que se envían a
							su equipo o dispositivo desde un sitio web y desde
							el que se le presta el servicio.
						</li>
						<li>
							<b>Cookies de terceros</b> son aquellas que se
							envían a su equipo web desde un equipo o dominio que
							no es gestionado por Life5, sino por otra entidad,
							que trata la información obtenida a través de las
							mismas.
						</li>
					</ul>
					<br />
					2. Según el período de tiempo que permanecen activadas en su
					dispositivo
					<br />
					<br />
					<ul>
						<li>
							<b>Cookies de sesión</b> son aquellas diseñadas para
							recabar y almacenar información únicamente durante
							la duración de la visita a un sitio web, y
							desaparecen al terminar la sesión. Se emplean
							normalmente para almacenar información que solo
							interesa conservar para garantizar las
							funcionalidades básicas de un sitio web durante su
							visita al mismo.
						</li>
						<li>
							<b>Cookies persistentes</b> estas cookies almacenan
							la información por un período de tiempo más largo,
							que puede ser accedida y tratada por el responsable
							de la cookie durante dicho período. Este período
							varía según cada cookie, y puede ir de unos minutos
							a varios años.
						</li>
					</ul>
					<br />
					3. Según su finalidad
					<br />
					<br />
					<ul>
						<li>
							<b>Cookies de análisis</b> son aquellas que permiten
							el seguimiento y análisis de su comportamiento en el
							Sitio web. Esta información permite medir y analizar
							el uso del Sitio web, así como elaborar perfiles de
							navegación de los usuarios, con el objetivo de
							introducir las mejoras necesarias en Sitio web en
							función del análisis de los datos de navegación
							recogidos.
						</li>
						<li>
							<b>Cookies funcionales</b> (necesarias) son aquellas
							cookies propias que sirven para facilitar la
							correcta navegación en un sitio web, así como para
							asegurar que el contenido de los mismos se carga
							eficazmente. Además, ayudan a identificar de forma
							única a un usuario para no repetir tareas dentro de
							un periodo de tiempo especificado.
						</li>
						<li>
							<b>Cookies publicitarias</b> son aquéllas que
							permiten la gestión, de la forma más eficaz posible,
							de los espacios publicitarios que, en su caso, se
							hayan incluido en el Sitio web de Life5.
						</li>
					</ul>
					<br />
					Concretamente, en nuestro sitio web utilizamos las
					siguientes:
				</Typography>
			</section>
			<section className="legalPages--section">
				<Typography
					variant="H3"
					weight="700"
					text=""
					className="legalPages--sectionHeading"
				>
					Cookies De Análisis
				</Typography>
				<Typography variant="BodyMedium" weight="400" text="">
					Estas cookies son necesarias para mejorar el funcionamiento
					y la interacción del usuario con la web. La información
					recogida es anónima y se utiliza para fines estadísticos.
					<br />
					<br />
					<table className="legalPages--tableTwentyFive">
						<tr>
							<th>Nombre</th>
							<th>Descripción</th>
							<th>Titular</th>
							<th>Plazo</th>
						</tr>
						<tr>
							<td>Analytics</td>
							<td>
								Sirven para medir la interacción de las visitas
								con el sitio web de Life5
							</td>
							<td>Google Ireland Limited</td>
							<td>365 días</td>
						</tr>
						<tr>
							<td>Hotjar</td>
							<td>
								Sirven para medir la interacción de las visitas
								con el sitio web de Life5
							</td>
							<td>Hotjar Ltd.</td>
							<td>365 días</td>
						</tr>
						<tr>
							<td>Facebook</td>
							<td>
								Sirve para medir la interacción de las visitas
								con el sitio web de Life5
							</td>
							<td>Facebook Payments International Limited</td>
							<td>365 días</td>
						</tr>
					</table>
				</Typography>
			</section>
			<section className="legalPages--section">
				<Typography
					variant="H3"
					weight="700"
					text=""
					className="legalPages--sectionHeading"
				>
					Cookies Funcionales (Necesarias)
				</Typography>
				<Typography variant="BodyMedium" weight="400" text="">
					Estas cookies permiten una funcionalidad adicional, como
					guardar preferencias como permitir interacciones sociales,
					lo que le permite publicar comentarios en nuestro sitio y
					analizar el uso para la optimización del sitio.
					<br />
					<br />
					<table className="legalPages--tableTwentyFive">
						<tr>
							<th>Nombre</th>
							<th>Descripción</th>
							<th>Titular</th>
							<th>Plazo</th>
						</tr>
						<tr>
							<td>life5users</td>
							<td>
								Permite a life5 guardar la información de los
								usuarios y facilitar su navegación
							</td>
							<td>Your Life Correduría de Seguros SL</td>
							<td>365 días</td>
						</tr>
					</table>
				</Typography>
			</section>
			<section className="legalPages--section">
				<Typography
					variant="H3"
					weight="700"
					text=""
					className="legalPages--sectionHeading"
				>
					Cookies de Publicidad
				</Typography>
				<Typography variant="BodyMedium" weight="400" text="">
					Estas cookies permiten la gestión, de la forma más eficaz
					posible, de los espacios publicitarios en esta web. Estas
					cookies almacenan información del comportamiento de los
					usuarios obtenida a través de la observación continuada de
					sus hábitos de navegación, lo que permite desarrollar un
					perfil específico para mostrar publicidad en función del
					mismo.
					<br />
					<br />
					<table className="legalPages--tableTwentyFive">
						<tr>
							<th>Nombre</th>
							<th>Descripción</th>
							<th>Titular</th>
							<th>Plazo</th>
						</tr>
						<tr>
							<td>Google ads</td>
							<td>
								Permite el seguimiento de las campañas de
								publicidad
							</td>
							<td>Google Ireland Limited</td>
							<td>365 días</td>
						</tr>
						<tr>
							<td>Bing Ads</td>
							<td>
								Permite el seguimiento de las campañas de
								publicidad
							</td>
							<td>Microsoft Ireland Operations Limited</td>
							<td>365 días</td>
						</tr>
						<tr>
							<td>Taboola</td>
							<td>
								Permite el seguimiento de las campañas de
								publicidad
							</td>
							<td>Taboola, Inc</td>
							<td>365 días</td>
						</tr>
					</table>
				</Typography>
			</section>
			<section className="legalPages--section">
				<Typography
					variant="H3"
					weight="700"
					text=""
					className="legalPages--sectionHeading"
				>
					Respecto de nuestras Redes Sociales.
				</Typography>
				<Typography variant="BodyMedium" weight="400" text="">
					Life5 se encuentra en las principales redes sociales,
					reconociéndose en todos los casos responsable del
					tratamiento de los datos de sus seguidores, fans,
					suscriptores, comentaristas y otros perfiles de usuarios
					(“Seguidores”). El tratamiento que Life5 llevará a cabo con
					dichos datos será, como máximo, el que la red social permita
					a las páginas de empresa.
					<br />
					<br />
					Así pues, LIFE5 podrá informar a sus Seguidores por
					cualquier vía que las redes sociales autoricen sobre sus
					actividades, ponencias, ofertas, así como prestar servicio
					personalizado de atención al cliente. En ningún caso Life5
					obtendrá datos de las redes sociales, a menos que se
					obtuviera puntual y expresamente el consentimiento del
					usuario para ello.
				</Typography>
			</section>
			<section className="legalPages--section">
				<Typography
					variant="H3"
					weight="700"
					text=""
					className="legalPages--sectionHeading"
				>
					En lo relativo al consentimiento del usuario
				</Typography>
				<Typography variant="BodyMedium" weight="400" text="">
					Cuando acceda a nuestro sitio web, le ofreceremos
					información sobre el uso de esta tecnología en el mismo y le
					pediremos que nos otorgue su consentimiento para ello. Tenga
					en cuenta que ciertas cookies podrían ser necesarias para el
					funcionamiento del sitio web y, si deniega este
					consentimiento, el acceso al mismo podría quedar impedido.
					En cualquier momento podrá retirar su consentimiento al uso
					de esta tecnología mediante las funciones de su navegador,
					desactivando las cookies o bloqueando la posibilidad de que
					se descarguen en su equipo o dispositivo. A continuación, le
					facilitamos la información relativa a las cookies de nuestro
					sitio web.
					<br />
					El usuario puede revocar su consentimiento para el uso de
					cookies en su navegador a través de las indicaciones
					anteriores o a través de la instalación de un sistema de
					rechazo, (“opt-out“) en su navegador web. Algunos terceros
					facilitan este rechazo a través de los siguientes enlaces:
					<br />
					<br />
					Opt-out de <b>Google Analytics</b>
					<br />
					<a
						href="https://tools.google.com/dlpage/gaoptout?hl=None"
						target="_blank"
						rel="noreferrer"
					>
						https://tools.google.com/dlpage/gaoptout?hl=None
					</a>
					<br />
					<br />
					Opt-out de <b>Google (publicidad comportamental)</b>
					<br />
					<a
						href="https://adssettings.google.com/authenticated"
						target="_blank"
						rel="noreferrer"
					>
						https://adssettings.google.com/authenticated
					</a>
					<br />
					<br />
					<b>Base jurídica del tratamiento:</b> el consentimiento, que
					el usuario podrá revocar en cualquier momento según se
					indica en el siguiente apartado.
					<br />
					<br />
					<b>Destinatarios de los datos:</b> los terceros
					identificados en este apartado (“Cookies que utiliza Life5”)
					<br />
					<br />
					<b>Transferencias a terceros países:</b> En relación con los
					terceros identificados en este apartado (“Cookies que
					utiliza Life5”), el usuario puede consultar sus respectivas
					políticas de privacidad para informarse de las
					transferencias que, en su caso, estos realicen.
					<br />
					<br />
					<b>Elaboración de perfiles:</b> podrá observarse la
					navegación del usuario para la creación de un perfil en los
					términos y con los fines que se indican en este aportado
					(“Cookies que utiliza Life5”) de esta política de cookies.
					Para más detalle, puede usted consultar las políticas de
					privacidad de los terceros identificados en dicho apartado.
					<br />
					<br />
					Plazo de conservación: mantendremos la información mientras
					sea imprescindible para la prestación del servicio
					solicitado, hasta que el usuario nos manifieste su oposición
					o hasta que revoque tu consentimiento. En estos casos, la
					información se mantendrá debidamente bloqueada sin que la
					podamos utilizar mientras sea necesaria para el ejercicio o
					defensa de reclamaciones legales o contractuales. Una vez
					transcurrido este plazo de tiempo, los serán eliminados
					definitivamente.
					<br />
					<br />
					En los casos en que obtenemos datos automáticamente a través
					de cookies, debe tener en cuenta que algunas pueden estar
					instaladas durante años. No obstante, el usuario puede
					limitar su uso en el tiempo eliminándolas de los navegadores
					o dispositivos.
				</Typography>
			</section>
		</main>
	);
};

export default CookiesPolicy;
