import './remove.scss';

export default function Remove(props) {
    return (
        <div
            className="remove-container"
            onClick={() => props.onClick()}
        >
            <p className={'remove-p'}>
                {props.content}
            </p>
        </div>
    );
}
