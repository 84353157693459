import numberFormat from '../../../../utils/Utils';
import Modal from '../../../../components/Components/modal/Modal.component';
import './css/upgradeCapitalModal.scss';

export default function UpgradeCapitalModal(props) {

    const { upGradedOpen, handleUpgradedClose, capitalUpgrade } = props;

    return (
        <div>
            <Modal
                handleClose={handleUpgradedClose}
                show={upGradedOpen}
            >
                <div className='questions-downgraded-dialog-content'>
                <p>
                        Debido a los datos que nos has facilitado, debemos
                        aumentarte el capital a <br />
                        <span>
                            {numberFormat(
                                capitalUpgrade,
                                'capital'
                            )}
                        </span>
                    </p>
                    <p>Ese es el capital mínimo para poder asegurarte</p>
                </div>
            </Modal>
        </div>
    );
}














