import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";

import PostPayment from "./PostPayment";
import { handleHubspot } from "../../../utils/HandleHubspot";
import { GetlifeContext } from "../../../context/GetLifeContext.context";
import { I18nContext } from "../../../context/i18n.context";
import { questionsPostPayment } from "./components/questionsPostPayment";
import { DOMAIN } from "../../../constants/Global";

export default function PostPaymentMain() {
  const { step } = useParams();

  const {
    state: { translate },
  } = useContext(I18nContext);

  const { leadId, token, setLoading, loading, setProgressBar, progressBar } =
    useContext(GetlifeContext);

  const [question, setQuestion] = useState(false);

  const [actualStep, setActualStep] = useState(false);

  const showFormBuilderButton = () => {
    if (
      question.name === "adress" ||
      question.name === "idCard" ||
      question.name === "nationality"
    ) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setLoading(true);
    if (step === "0") {
      setProgressBar(80);
    } else if (step === "2") {
      setProgressBar(85);
    }
    setActualStep(step);

    setQuestion(questionsPostPayment(translate, DOMAIN)[step]);
    handleHubspot();

    setLoading(false);
  }, [step]);

  return (
    <PostPayment
      token={token}
      leadId={leadId}
      progressAmount={progressBar}
      handleProgressBar={setProgressBar}
      step={actualStep}
      question={question}
      showButton={showFormBuilderButton}
      isLoading={loading}
      setLoading={setLoading}
    />
  );
}
